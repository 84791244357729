import React, { useState } from "react";
import { Space, List, Collapse, Row, Col, Button, Card, message } from 'antd';
import { useNavigate } from "react-router-dom";
import { CourseDateData, CourseDateItem, CourseDateList, ErrorDisplay, ModalDisplay, MoreButton, ParticipantForm, SimpleCourseDateList, StornoForm } from "../..";
import { useEffect } from "react";

export const ParticipationItemParticipant = ({ ghost, editable, item, refresh, custApi, mainApi }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [btLoading, setBtLoading] = useState(false);
    const [course, setCourse] = useState({});

    useEffect(() => {
        setError("")
        getData()
    }, [open]);

    const getData = async () => {
        try {
            const response = await mainApi.get(`/course/one/${item.courseId}`);
            let data = response.data.course;
            data.courseDates = data.courseDates.sort((a, b) => new Date(a.time.publicStart) - new Date(b.time.publicStart))
            setCourse(data);

        } catch (error) {
            setError("Probleme beim Laden der Daten: " + error?.response?.data?.message)
        } finally {
            setLoading(false);
        }
    };

    const handleResend = async () => {
        setBtLoading(true)
        console.log(item.uuid)
        try {
            const values = { courseId: course.uuid, participationId: item.uuid }
            await custApi.post(`/participation/sendConfirmation`, values);
            message.success("Erfolgreich gesendet")
        } catch (error) {
            message.error("Senden Fehlgeschlagen: " + error?.response?.data?.message)
        }
        setBtLoading(false)
    }

    const handleStorno = async () => {
        setBtLoading(true)
        try {
            const values = { courseId: course.uuid }
            await custApi.patch(`/participation/update/${item.uuid}`);
            message.success("Erfolgreich storniert")
            refresh(true)
        } catch (error) {
            message.error("Stornierung Fehlgeschlagen: " + error?.response?.data?.message)
        }
        setBtLoading(false)
    }



    const handleDelete = async () => {
        await custApi.patch(`/contact/delete/${item.uuid}`)
        refresh(true)
    };

    return (
        <ErrorDisplay loading={loading} error={error}>
            <List.Item style={{ padding: 0 }}>
                {/* <Collapse
                    size="small"
                    type
                    ghost
                    items={[{
                        key: item.uuid,
                        label: (
                            <Row gutter={[8, 16]} justify="space-between">
                                <Col flex>
                                    {course?.courseType?.courseTypeAccessible?.informations[0].title + " "}
                                </Col>
                                <Col flex>
                                    {new Date(course?.courseDates?.[0]?.time?.publicStart).toLocaleString('de-DE', { month: 'long', year: "numeric" })}
                                </Col>
                                {/* <Col flex>
                                    <Space>
                                        <MoreButton text="mehr" onClick={() => navigate(`/participant/${item?.uuid}`)} />
                                    </Space>
                                </Col> 
                            </Row>
                        ),
                        children: (<>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                    <Button>Senden</Button>
                                </Col>
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                    <SimpleCourseDateList ghost dates={course?.courseDates} />
                                </Col>
                            </Row>

                        </>)
                    }]}>
                </Collapse > */}
                <Card
                    styles={{ header: { background: "lightgray" } }}
                    actions={item?.participationStatus?.statusValue === "ACTIVE" ? [
                        <Button loading={btLoading} onClick={() => { handleResend() }} type="default">Bestätigung Senden</Button>,
                        <Button loading={btLoading} onClick={() => { setOpen(true) }} type="primary">Stornieren</Button>
                    ] : []}
                    title={<Row gutter={[8, 16]} justify="space-between">
                        <Col flex>
                            {course?.courseType?.courseTypeAccessible?.informations[0].title + " "}
                        </Col>
                        <Col flex>
                            {new Date(course?.courseDates?.[0]?.time?.publicStart).toLocaleString('de-DE', { month: 'long', year: "numeric" })}
                        </Col>
                    </Row>}
                >
                    {getDisplay(item?.participationStatus, course)}

                </Card>
                <ModalDisplay
                    onCancel={() => setOpen(false)}
                    open={open}
                    content={<StornoForm
                        custApi={custApi}
                        createdAt={item.createdAt}
                        courseDates={course.courseDates}
                        uuid={item.uuid}
                        isSuccess={() => {
                            setOpen(false)
                            handleStorno()
                        }}
                    />} />
            </List.Item >
        </ErrorDisplay >
    )
};

function getDisplay(status, course) {
    switch (status?.statusValue) {
        case "ACTIVE":
            return (<SimpleCourseDateList ghost dates={course?.courseDates} />)
        case "FULL_CANCELED":
        case "FEE_CANCELED":
        case "FREE_CANCELED":
            return "Diese Teilnahme wurde Storniert."
    }
}