import React from 'react';
import { Checkbox, List, Button, Form, message, Row, Col, Divider, Card } from 'antd';

import { ErrorDisplay } from '../..';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';

export const BookingOverviewForm = ({ customer, course, location, participant, isSuccess }) => {
    const [form] = Form.useForm();
    const handleSubmit = () => {
        if (form.getFieldValue("agb")) { isSuccess() }
        else { message.error("Bitte unseren Allgemeinen Geschäftbedinnungen zustimmen") }
    }

    const courseData = [
        {
            label: course?.courseType?.courseTypeAccessible?.informations[0].title,
            icon: <ListAltOutlinedIcon />,
        }, {
            label: (`${course?.courseDates?.length || 0} Termin${course?.courseDates?.length === 1 ? "" : "en"} à ${course?.courseType?.duration?.overallTime} Minuten`),
            icon: <AccessTimeOutlinedIcon />,
        }, {
            label: (location?.locationAccessible?.informations[0].title),
            icon: <LocationOnOutlinedIcon />,
        },
    ];

    const customerData = [
        {
            label: (<>
                {customer?.person?.sex === "male" ? "Herr " : customer?.person?.sex === "female" ? "Frau " : "Person "}
                {customer?.person?.name?.firstname + " " + customer?.person?.name?.lastname}
            </>),
            icon: <PersonOutlineOutlinedIcon />,
        }, {
            label: (customer?.email),
            icon: <EmailOutlinedIcon />,
        }, {
            label: (customer?.phone?.phoneNumber),
            icon: <LocalPhoneOutlinedIcon />,
        },
    ];

    const participantData = [
        {
            label: (<>
                {participant?.person?.name?.firstname + " " + participant?.person?.name?.lastname}
            </>),
            icon: <PersonOutlineOutlinedIcon />,
        }, {
            label: (new Date(participant?.person?.birthdate).toLocaleDateString()),
            icon: <CakeOutlinedIcon />,
        },
    ];

    const bookingData = [
        {
            label: (<>
                {course?.price + " Euro"}
            </>),
            icon: <EuroSymbolOutlinedIcon />,
        }, {
            label: ("Bezahlen per Rechung"),
            icon: <AccountBalanceOutlinedIcon />,
        }, {
            label: (<div style={{ color: "blue" }} onClick={() => window.open('https://swimgood-schwimmschule.de/agb-kurse', '_blank')}>AGB Zustimmung</div>),
            icon: <Form.Item name="agb" valuePropName="checked" noStyle>
                <Checkbox ></Checkbox>
            </Form.Item>,
        },
    ];

    return (
        <ErrorDisplay error={!participant || !course || !customer || !location}>
            <Form
                name="form"
                form={form}
                initialValues={{ agb: false, }}
                onFinish={handleSubmit}
            >
                <Divider style={{ margin: 5 }}></Divider>
                <Row gutter={[16, 16]} justify="center">
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                        <Card size='small' title="Angaben" headStyle={{ color: "#fff", backgroundColor: "#1a4568" }}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                    <List
                                        size='small'
                                        header={<b>Kursbuchende Person</b>}
                                        itemLayout="horizontal"
                                        dataSource={customerData}
                                        renderItem={(item, index) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    avatar={item.icon}
                                                    title={item.label}
                                                />
                                            </List.Item>
                                        )}>
                                    </List>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                    <List
                                        size='small'
                                        header={<b>Teilnehmer</b>}
                                        itemLayout="horizontal"
                                        dataSource={participantData}
                                        renderItem={(item, index) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    avatar={item.icon}
                                                    title={item.label}
                                                />
                                            </List.Item>
                                        )}>
                                    </List>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                        <Card size='small' title="Kursdaten" headStyle={{ color: "#fff", backgroundColor: "#1a4568" }}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                    <List
                                        size='small'
                                        header={<b>Kurs</b>}
                                        itemLayout="horizontal"
                                        dataSource={courseData}
                                        renderItem={(item, index) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    avatar={item.icon}
                                                    title={item.label}
                                                />
                                            </List.Item>
                                        )}>
                                    </List>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                    <List
                                        size='small'
                                        header={<b>Anmeldung</b>}
                                        itemLayout="horizontal"
                                        dataSource={bookingData}
                                        renderItem={(item, index) => (
                                            <List.Item>
                                                <List.Item.Meta
                                                    avatar={item.icon}
                                                    title={item.label}
                                                />
                                            </List.Item>
                                        )}>
                                    </List>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={0} sm={0} md={12} lg={12} xl={12} ></Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                        <Form.Item>
                            <Button block type="primary" htmlType="submit" >Kostenpflichtig Anmelden</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </ErrorDisplay >
    );
};

