import React, { useEffect, useState } from 'react';
import { Tag, List, Button, Flex, Space, message, Row, Col, Divider, Tooltip, Select, Card } from 'antd';

import { ErrorDisplay, ParticipantForm, ModalDisplay, MoreButton } from '../../../package';
import { LogInForm } from '../../components/form/start/LogInForm';
import { CustomerLogInForm } from '../user/CustomerLogInForm';
import { useNavigate } from 'react-router-dom';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import { calculateAge } from '../../timestampManymulation';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

const { Option } = Select;
export const BookingParticipantForm = ({ title, isSuccess, customerId, custApi, participantId, ageRange = "" }) => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState("")
    const [participant, setParticipant] = useState()
    const [open, setOpen] = useState(false)

    useEffect(() => {
        setLoading(true)
        const user = JSON.parse(localStorage.getItem("user"))
        getData(user);
    }, [open]);

    const checkAge = (birthDate) => {
        const { years, months } = calculateAge(birthDate);
        const { minAge, maxAge } = ageRange;
        const ageInMonths = years * 12 + months;
        const minAgeInMonths = parseInt(minAge?.year) * 12 + parseInt(minAge?.month);
        const maxAgeInMonths = parseInt(maxAge?.year) * 12 + parseInt(maxAge?.month);
        return ageInMonths >= minAgeInMonths && ageInMonths <= maxAgeInMonths;
    }

    const getData = async () => {
        try {
            const customer = await custApi.get(`/participant/all?customerIds=${customerId}`);
            setParticipant(customer?.data?.participant)
            const participant = customer?.data?.participant?.find(item => item.uuid === participantId) || ""
            if (participant) { isSuccess(participant) }
        } catch (error) {
            setError('Fehler beim Laden der Benutzerdetails.' + error?.response?.data?.message);
        } finally {
            setLoading(false)
        }
    };

    const data = participant?.map((p, index) => ({
        onClick: (() => isSuccess(p)),
        label: p.person.name.firstname + ' ' + p.person.name.lastname,
        icon: <PersonOutlineOutlinedIcon />,
        able: checkAge(p.person.birthdate)
    }));

    return (
        <ErrorDisplay loading={loading} error={error}>
            <Row justify="center">
                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                    <Card><Card.Meta title={"Teilnehmer Wählen"} ></Card.Meta>
                        <Divider style={{ margin: 5 }}></Divider>
                        <List
                            itemLayout="horizontal"
                            dataSource={data}
                            renderItem={(item, index) => (
                                <List.Item
                                    extra={<>
                                        {item.able ? <Tag color='green'>Teilnahme möglich</Tag> : <Tag color='orange'>Teilnahme nicht möglich</Tag>}
                                        {item.able ? <Button icon={<ArrowForwardIosOutlinedIcon fontSize='small' />} type='' size='small'> </Button> : ""}
                                    </>}
                                    onClick={() => item.able ? item.onClick() : ""}>
                                    <List.Item.Meta
                                        avatar={item.icon}
                                        title={item.label}
                                    />
                                </List.Item>
                            )}>
                            <Divider style={{ margin: 5 }}> Oder</Divider>
                            <List.Item onClick={() => setOpen(true)}>
                                <List.Item.Meta
                                    avatar={<PersonAddAlt1OutlinedIcon />}
                                    title={"Teilnehmer Anlegen"}
                                />
                            </List.Item>
                        </List>
                    </Card>
                    <ModalDisplay
                        onCancel={() => setOpen(false)}
                        open={open}
                        content={<ParticipantForm
                            custApi={custApi}
                            isSuccess={() => {
                                setOpen(false)
                            }}
                        />} />
                </Col>
            </Row>
        </ErrorDisplay>
    );
};

