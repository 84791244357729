import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Space, message, Row, Col, Divider, Tooltip, Checkbox } from 'antd';
import { QuillEditor, AgeSelect, SelectWithArray, SelectWithData } from '../..';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

export const UserForm = ({ title, isSuccess, uuid, empApi }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [user, setUser] = useState([])

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user")) || {};
        if (storedUser) { setUser(storedUser) }
        if (uuid) { getData() }
    }, []);

    const getData = async () => {
        try {
            setLoading(true);
            const response = await empApi.get(`/user/one/${uuid}`);
            const data = {
                email: response?.data?.user?.email,
                userName: response?.data?.user?.userName,
                status: response?.data?.user?.userStatus?.statusValue,
            }
            form.setFieldsValue(data);
        } catch (error) {
            message.error("Fehler beim Laden der Daten. " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    const onFinish = async (values) => {

        try {
            setLoading(true);
            if (uuid) {
                await empApi.patch(`/user/update/${uuid}`, values);
            } else {
                await empApi.post(`/user`, values);
            }
            isSuccess();
        } catch (error) {
            message.error("Fehlgeschlagen: " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Form
                name="UserForm"
                requiredMark={false}
                form={form}
                onFinish={onFinish}
                layout="vertical" a
                validateTrigger="onChange"
                style={{ width: "100%" }}
            >
                <Space direction="vertical">
                    <h3>{uuid ? 'User ändern' : 'User anlegen'}</h3>
                </Space>
                <Spin spinning={false} size="large" loading={loading}>
                    <Row gutter={[16, 0]}>

                        {/**/}
                        <Col span={24}>
                            <Divider orientation='left' orientationMargin="0"><PersonOutlineOutlinedIcon /> Daten</Divider>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Benutzername angeben">
                                <Form.Item
                                    hasFeedback
                                    name="userName"
                                    rules={[{
                                        required: true,
                                        min: 1,
                                        message: 'Bitte einen Benutzernamen angeben!',
                                    },
                                    {
                                        max: 50,
                                        message: 'Der Benutzernamen ist zu Lang!',
                                    },
                                    ]}>
                                    <Input disabled={uuid} prefix={<PersonOutlineOutlinedIcon fontSize='small' />} placeholder="Benutzername" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="E-Mail-Adresse">
                                <Form.Item
                                    hasFeedback
                                    name="email"
                                    rules={[{
                                        required: true,
                                        type: 'email',
                                        message: 'Bitte eine gültige E-Mail-Adresse eingeben',
                                    },
                                    {
                                        max: 255,
                                        message: 'Die E-Mail-Adresse ist zu lang!',
                                    },
                                    ]}>
                                    <Input disabled={uuid} prefix={<EmailOutlinedIcon fontSize='small' />} placeholder="E-Mail-Adresse" />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            {!uuid ? "" : <SelectWithData
                                label="Status"
                                tooltip={"Wie die Kursart im System angezeigt werden?"}
                                name="status"
                                data={[
                                    { value: "INIT", label: "Nicht eingerichtet" },
                                    { value: "ACTIVE", label: "Aktiv" },
                                    { value: "PENDING", label: "Pending" },
                                    { value: "BLOCKED", label: "Blockiert" },
                                ]}
                            />}
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6} >
                            {!uuid ? "" : <Form.Item
                                name="password"
                                valuePropName="checked">
                                <Checkbox>Neues Passwort ?</Checkbox>
                            </Form.Item>}
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" loading={loading} block>
                                    {title ? title : uuid ? "Speichern" : 'Erstellen'}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </div >
    );
};