import React, { useEffect, useState } from "react";
import { Flex, Tag, Space, List, Collapse, Row, Col, message } from 'antd';
import { useNavigate } from "react-router-dom";
import { EditButton, DeleteButton, ModalDisplay, MoreButton, UnitData, ContactForm, ContactData, AvailableList, CommunicationList } from "../..";
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';

export const CourseDateItem = ({ ghost, editable, item, refresh, mainApi, nr }) => {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleDelete = async () => {
        try {
            await mainApi.patch(`/courseDate/delete/${item.uuid}`)
            refresh(true)
        } catch (error) {
            message.error("Fehlgeschlagen: " + error?.message)
        }

    };

    const start = new Date(item?.time?.publicStart)
    const end = new Date(item?.time?.publicEnd)

    return (
        <List.Item>
            <Row gutter={[8, 16]} justify="space-between">
                <Col flex>
                    <Tag color="gray">{nr}</Tag>
                    {start.toLocaleDateString('de-DE', { weekday: 'long' }) + " - " + start.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: "numeric" })}
                </Col>
                <Col flex>
                    {start.toLocaleString('de-DE', { hour: '2-digit', minute: '2-digit' }) + " Uhr - "}
                    {end.toLocaleString('de-DE', { hour: '2-digit', minute: '2-digit' }) + " Uhr"}
                </Col>
                <Col flex>
                    <Space>
                        {!editable ? "" : <Tag icon={<AutorenewOutlinedIcon />} color="green">{(item?.courseDateStatus?.msg)}</Tag>}
                        {/* <MoreButton text="mehr" onClick={() => navigate(`/courseDate/${item?.uuid}`)} /> */}
                        {!editable ? "" : <DeleteButton handleDelete={() => handleDelete()} />}
                    </Space>
                </Col>
            </Row>

            <ModalDisplay
                onCancel={() => setOpen(false)}
                open={open}
                content={<ContactForm
                    mainApi={mainApi}
                    uuid={item.uuid}
                    isSuccess={() => {
                        setOpen(false)
                        refresh(true)
                    }}
                />} />
        </List.Item>
    )
};

