import React from "react";
import { useNavigate } from "react-router-dom";
import { List, Flex, Button, Modal } from 'antd';

import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import CookieOutlinedIcon from '@mui/icons-material/CookieOutlined';
import { ModalDisplay } from "../package";
import { useState } from "react";
import Impressum from "./Impressum";
import Privacy from "./Privacy";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";

export default function DisplayProvider({ page, credentials, mode }) {
    const [open, setOpen] = useState(false);

    switch (mode) {
        case "drawer":
            return (<React.Fragment>
                <Button size='small' type='' icon={credentials.icon} onClick={() => setOpen(true)} />
                <ModalDisplay
                    onCancel={() => {
                        setOpen(false)
                        credentials.onCancel && credentials.onCancel()
                    }} open={open}
                    content={page}
                    header={credentials.header}
                    footer={credentials.footer}
                    extra={credentials.icon}
                    closeIcon={credentials.closeIcon}
                />
            </React.Fragment>);
        case "modal":
            return (<React.Fragment>
                <Button size='small' type='' icon={credentials.icon} onClick={() => setOpen(true)} />
                <Modal
                    centered
                    closable={credentials.closable}
                    destroyOnClose
                    maskClosable={false}
                    footer={credentials.footer}
                    onCancel={() => {
                        setOpen(false)
                        credentials.onCancel && credentials.onCancel()
                    }}
                    open={open}
                    width={'95%'}
                    style={{ maxWidth: '800px', maxHeight: "80%", overflow: 'auto' }}
                    styles={credentials.mask ? {} : { mask: { backdropFilter: 'blur(10px)' } }}
                >
                    {page}
                </Modal>
            </React.Fragment >);
        case "collapse":
        case "card":
        case "ghost":
        case "carousel":
        case "pop":
        case "tabs":
        case "tour":
        case "message":
        case "result":
        case "notification":

        default:
            return (<React.Fragment>{page.content}</React.Fragment>);

    }

}
