import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Space, message, Row, Col, Divider, Tooltip, Select, DatePicker, Checkbox } from 'antd';
import { ErrorDisplay } from "../.."
import TransgenderOutlinedIcon from '@mui/icons-material/TransgenderOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import LocalHospitalOutlinedIcon from '@mui/icons-material/LocalHospitalOutlined';
import dayjs from 'dayjs';


const { Option } = Select;
export const ParticipantForm = ({ title, isSuccess, uuid, custApi, customerId }) => {
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(true)
    const [form] = Form.useForm();
    const [showHealth, setShowHealth] = useState(false);

    const handleCheckbox = (e) => {
        setShowHealth(e.target.checked);
    };

    useEffect(() => {
        if (uuid) { getDate(); }
        setLoading(false);
    }, []);

    const getDate = async () => {
        try {
            setLoading(true);
            const response = await custApi.get(`/participant/one/${uuid}`);
            setShowHealth(response?.data?.participant?.health ? true : false)
            const participantData = {
                sex: response?.data?.participant?.person?.sex,
                firstname: response?.data?.participant?.person?.name?.firstname,
                lastname: response?.data?.participant?.person?.name?.lastname,
                birthdate: dayjs(response?.data?.participant?.person?.birthdate),
                healthCheck: response?.data?.participant?.health ? true : false,
                health: response?.data?.participant?.health,
            }
            form.setFieldsValue(participantData);
        } catch (error) {
            setError(`Fehler beim Laden der Teilnehmerdaten. ${(error?.response?.data?.message)}`);
        }
    };

    const onFinish = async (values) => {
        values.health = showHealth ? values.health : null;
        setLoading(true);
        let response
        try {
            if (uuid) {
                response = await custApi.patch(`/participant/update/${uuid}`, values);
            } else {
                const storedUser = JSON.parse(localStorage.getItem("user")) || {};
                values.customerId = customerId || storedUser?.customer?.uuid;
                response = await custApi.post(`/participant`, values);
            }
            isSuccess(response?.data?.participantId);
        } catch (error) {
            message.error(`Fehlgeschlagen: ${(error?.response?.data?.message)}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <ErrorDisplay error={error} loading={loading}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Form
                    name="ParticipantFrom"
                    requiredMark={false}
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    validateTrigger="onChange"
                    style={{ width: "100%" }}
                >
                    <Space direction="vertical">
                        <h3>{uuid ? 'Teilnehmer ändern' : 'Teilnehmer anlegen'}</h3>
                    </Space>
                    <Spin spinning={false} size="large" loading={loading}>
                        <Row gutter={[16, 0]}>

                            {/*Teilnehmer*/}
                            <Col span={24}>
                                <Divider orientation='left' orientationMargin="0"><AccountCircleOutlinedIcon /> Teilnehmer</Divider>
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Wie soll die Person angesprochen werden?">
                                    <Form.Item
                                        name="sex"
                                        rules={[{ required: true, message: 'Bitte Geschlecht auswählen!' }]}
                                    >
                                        <Select prefix={<TransgenderOutlinedIcon fontSize='small' />} placeholder="Geschlecht">
                                            <Option value="male">Männlich</Option>
                                            <Option value="female">Weiblich</Option>
                                            <Option value="other">Divers</Option>
                                        </Select>
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                            <Col xs={0} sm={0} md={12} lg={12} xl={12} ></Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                                <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Vorname der teilnehmenden Person">
                                    <Form.Item
                                        hasFeedback
                                        name="firstname"
                                        rules={[{
                                            required: true,
                                            min: 2,
                                            message: 'Bitte den Vornamen eingeben!',
                                        },
                                        {
                                            pattern: /^[a-zA-ZÀ-ÿ ]+$/i,
                                            message: 'Der Vorname darf nur Buchstaben enthalten!',
                                        },
                                        {
                                            max: 50,
                                            message: 'Der Vorname ist zu Lang!',
                                        },
                                        ]}>
                                        <Input prefix={<PersonOutlineOutlinedIcon fontSize='small' />} placeholder="Vorname" />
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                                <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Nachname der teilnehmenden Person">
                                    <Form.Item
                                        hasFeedback
                                        name="lastname"
                                        rules={[{
                                            required: true,
                                            min: 2,
                                            message: 'Bitte den Nachnamen eingeben!',
                                        },
                                        {
                                            pattern: /^[a-zA-ZÀ-ÿ ]+$/i,
                                            message: 'Der Nachname darf nur Buchstaben enthalten!',
                                        },
                                        {
                                            max: 50,
                                            message: 'Der Nachname ist zu Lang!',
                                        },
                                        ]}>
                                        <Input prefix={<PersonOutlineOutlinedIcon fontSize='small' />} placeholder="Nachname" />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            {/*Weitere Infos*/}
                            <Col span={24}>
                                <Divider orientation='left' orientationMargin="0"><AddCircleOutlineOutlinedIcon /> Weitere Angaben</Divider>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Geburtsdatum für altersgerechte Kurse">
                                    <Form.Item
                                        hasFeedback

                                        name="birthdate"
                                        rules={[{
                                            required: true,
                                            type: 'date',
                                            message: 'Bitte eine gültiges Geburtsdatum eingeben',
                                        },]}>
                                        <DatePicker disabledDate={current => current && current > dayjs().endOf('day')} format={'DD.MM.YYYY'} style={{ width: '100%' }} placeholder="Geburtsdatum" />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Müssen wir etwas im Umgang beachten?">
                                    <Form.Item name="healthCheck" valuePropName="checked">
                                        <Checkbox onChange={handleCheckbox}> Die teilnehmende Person hat Behinderungen, Allergien oder Beeinträchtigungen, die beachtet werden müssen. </Checkbox>
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            {showHealth && (
                                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                    <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Einschränkungen des Teilnehmers">
                                        <Form.Item
                                            hasFeedback

                                            name="health"
                                            rules={[{
                                                required: true,
                                                max: 200,
                                                message: 'Bitte die Informationen eingeben!',
                                            },
                                            ]}>
                                            <Input prefix={<LocalHospitalOutlinedIcon fontSize='small' />} placeholder="Gesundheitliche Informationen" />
                                        </Form.Item>
                                    </Tooltip>
                                </Col>
                            )}

                            <Col xs={8} sm={12} md={16} lg={17} xl={18} >
                            </Col>
                            <Col xs={16} sm={12} md={8} lg={7} xl={6} >
                                <Form.Item >
                                    <Button type="primary" htmlType="submit" loading={loading} block>
                                        {uuid ? 'Speichern' : 'Anlegen'}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Spin>
                </Form>
            </div >
        </ErrorDisplay>
    );
};