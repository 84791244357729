import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LinksPage = ({ link }) => {
    const navigate = useNavigate();

    useEffect(() => {
        switch (link) {
            case "register":
                navigate("/?register=1");
                break;
            case "login":
                navigate("/?login=1");
                break;
            case "settings":
                navigate("/?settings=1");
                break;
            case "privacy":
                navigate("/?settings=1&privacy=1");
                break;
            case "impressum":
                navigate("/?settings=1&impressum=1");
                break;
            case "ex":
                navigate("/?ex=1");
                break;
            case "debug":
                navigate("/?debug=1");
                break;
            default:
                navigate("/");
        }
    }, [link, navigate]);

    return <div>Redirecting...</div>;
};

export default LinksPage;
