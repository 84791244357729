import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Input, Button, Row, Col, Card, Space, Spin, message } from 'antd';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';

export const RegisterForm = ({ linkLogin, isSuccess, authApi }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const handleRegister = async (values) => {
    try {
      setLoading(true);
      await authApi.post('/register', values);
      isSuccess();
    } catch (error) {
      message.error(`Registrierung Fehlgeschlagen: ${error?.message}`);
    } finally {
      setLoading(false);
    }
  };



  return (
    <Form
      name="normal_register"
      className="register-form"
      requiredMark={false}
      onFinish={handleRegister}
      layout="vertical"
      validateTrigger="onChange"
      style={{ width: "100%" }}
    >
      <Card title={
        <Space direction="vertical">
          <h3>Registrierung Kundenportal</h3>
          <h6>Swimgood-Schwimmschule - CourseSync</h6>
        </Space>
      }>
        <Spin spinning={loading} size="large">
          <Form.Item
            hasFeedback
            label="E-Mail-Adresse:"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Bitte eine gültige E-Mail-Adresse eingeben',
              },
            ]}
          >
            <Input prefix={<AlternateEmailOutlinedIcon fontSize='small' />} placeholder="E-Mail-Adresse" />
          </Form.Item>
          <Form.Item
            hasFeedback
            name="password"
            label="Passwort:"
            rules={[
              {
                required: true,
                message: 'Bitte das Passwort eingeben!',
              },
            ]}
          >
            <Input.Password prefix={<LockOutlinedIcon fontSize='small' />} placeholder="Passwort" />
          </Form.Item>
          <Form.Item
            hasFeedback
            name="confirmPassword"
            label="Passwort bestätigen:"
            dependencies={['password']}
            rules={[
              {
                required: true,
                message: 'Bitte das Passwort bestätigen!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Die Passwörter stimmen nicht überein!'));
                },
              }),
            ]}
          >
            <Input.Password prefix={<LockOutlinedIcon fontSize='small' />} placeholder="Passwort bestätigen" />
          </Form.Item>
          <Form.Item>
            <Row align="middle" justify="space-between">
              {linkLogin ?
                <Col className='text-end'>
                  <a href={linkLogin}>Zurück zur Anmeldung</a>
                </Col>
                : ''}
              <Col>
                <Button type="primary" htmlType="submit" className="text-start btn-secondary">
                  Registrieren
                </Button>
              </Col>
            </Row>
            <Row align="middle" justify="end">
              <Col className='text-end'>
                Mit dem Registrieren stimme ich meiner <a href='' onClick={() => { navigate("/privacy") }}>Datenverarbeitung</a> zu.
              </Col>
            </Row>
          </Form.Item>
        </Spin>
      </Card>
    </Form>
  );
};

export default RegisterForm;
