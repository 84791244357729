import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Space, message, Row, Col, Divider, Tooltip, Select, DatePicker, TimePicker, Tag } from 'antd';
import TransgenderOutlinedIcon from '@mui/icons-material/TransgenderOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import AddAlarmOutlinedIcon from '@mui/icons-material/AddAlarmOutlined';
import { CoachSelect } from '../../components/form/parts/CoachSelect';
import { CourseTypeSelect } from '../../components/form/parts/CourseTypeSelect';
import { PriceInput } from '../../components/form/parts/PriceInput';
import { SelectWithData } from '../../components/form/parts/SelectWithData';
import { UnitSelect } from '../../components/form/parts/UnitSelect';
import { LocationSelect } from '../../components/form/parts/LocationSelect';
import { json } from 'react-router-dom';
import dayjs from 'dayjs';


const { Option } = Select;
export const CourseForm = ({ title, isSuccess, uuid, locationId, mainApi }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [date, setDate] = useState([])

    useEffect(() => {
        if (uuid) {
            getData();
        }
        setLoading(false)
    }, [uuid]);

    const getData = async () => {
        try {
            setLoading(true);
            const response = await mainApi.get(`/course/one/${uuid}`);
            const data = {
                coachId: response?.data?.course?.coachId,
                courseType: response?.data?.course?.courseTypeId,
                price: response?.data?.course?.price,
                prerelease: [
                    dayjs(response?.data?.course?.prerelease?.start),
                    dayjs(response?.data?.course?.prerelease?.end),
                ],
                release: [
                    dayjs(response?.data?.course?.release?.start),
                    dayjs(response?.data?.course?.release?.end),
                ],
                status: response?.data?.course?.courseStatus?.statusValue,
            }
            form.setFieldsValue(data);
        } catch (error) {
            message.error("Fehler beim Laden der Daten. " + error?.response?.data?.message && error?.message);
        } finally {
            setLoading(false);
        }
    };

    const onFinish = async (values) => {
        values.courseTypeId = values.courseType;
        values.courseDates = values.dates;
        let response;
        try {
            setLoading(true);
            if (uuid) {
                response = await mainApi.patch(`/course/update/${uuid}`, values);
            } else {
                response = await mainApi.post(`/course`, values);
            }
            isSuccess(response.data.course);
        } catch (error) {
            message.error("Fehlgeschlagen: " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Form
                name="CourseForm"
                requiredMark={false}
                form={form}
                onFinish={onFinish}
                layout="vertical"
                validateTrigger="onChange"
                style={{ width: "100%" }}
            >
                <Space direction="vertical">
                    <h3>{uuid ? 'Kurs ändern' : 'Kurs anlegen'}</h3>
                </Space>
                <Spin spinning={false} size="large" loading={loading}>
                    <Row gutter={[16, 0]}>
                        {/**/}
                        <Col span={24}>
                            <Divider orientation='left' orientationMargin="0"><PersonOutlineOutlinedIcon /> Daten</Divider>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            <CourseTypeSelect mainApi={mainApi} />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            <CoachSelect mainApi={mainApi} />
                        </Col>
                        {uuid ? "" : <>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                <LocationSelect disabled={date.length === 0 ? false : true} mainApi={mainApi} />
                            </Col>
                            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            </Col>
                        </>}

                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Frühbucherzeitraum">
                                <Form.Item
                                    name="prerelease"
                                    rules={[{ required: true, message: `Bitte einen Frühbucherzeitraum wählen` }]}
                                >
                                    <DatePicker.RangePicker
                                        style={{ width: "100%" }}
                                        showTime={{ format: 'HH:mm' }}
                                        format="YYYY-MM-DD HH:mm"
                                        placeholder={["Start", "Ende"]}
                                    />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Buchungszeitraum">
                                <Form.Item
                                    name="release"
                                    rules={[{ required: true, message: `Bitte einen Buchungszeitraum wählen` }]}
                                >
                                    <DatePicker.RangePicker
                                        style={{ width: "100%" }}
                                        showTime={{ format: 'HH:mm' }}
                                        format="YYYY-MM-DD HH:mm"
                                        placeholder={["Start", "Ende"]}
                                    />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <PriceInput />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            <SelectWithData
                                label="Status"
                                tooltip={"Wie die Kursart im System angezeigt werden?"}
                                name="status"
                                data={[
                                    { value: "INTERNAL", label: "Inter (Verwaltung)" },
                                    { value: "SOON", label: "Inter (Trainer)" },
                                    { value: "BOOKING", label: "Buchung" },
                                    { value: "ACTIVE", label: "Aktiv" },
                                    { value: "PASSED", label: "Vergangen" },
                                ]}
                            />
                        </Col>
                        {uuid ? "" : <Form.List
                            name="dates"
                            rules={[
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (value && value.length > 0) {
                                            return Promise.resolve();
                                        }
                                        message.error('Bitte mindestens einen Kurstermin auswählen.')
                                        return Promise.reject();
                                    },
                                }),
                            ]}
                        >
                            {(fields, { add, remove }) => (
                                < >
                                    <Col span={24}>
                                        <Divider orientation='left' orientationMargin="0">
                                            <Space>
                                                <PersonOutlineOutlinedIcon /> Termine
                                                <Button type="primary" size='small' onClick={() => form.getFieldValue("locationId") ? add() : message.error("Bitte zuerst einen Standort auswählen!")} >Neuen Kurstermin </Button>
                                            </Space>
                                        </Divider>
                                    </Col>
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} key={key}>
                                            <Row gutter={[16, 0]}>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                                    <Form.Item
                                                        hasFeedback
                                                        name={[name, 'date']}
                                                        rules={[{ required: true, message: `Bitte einen Termin wählen` }]}
                                                    >
                                                        <DatePicker
                                                            showTime={{ format: "HH:mm" }} style={{ width: "100%" }}
                                                            changeOnBlur
                                                            format="YYYY-MM-DD HH:mm"
                                                            placeholder={`Kurstermin ${name + 1}`} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={9} sm={10} md={10} lg={10} xl={10}>
                                                    <UnitSelect
                                                        name={[name, 'unitId']}
                                                        mainApi={mainApi}
                                                        locationId={form.getFieldValue("locationId")} />
                                                </Col>
                                                <Col xs={2} sm={2} md={2} lg={2} xl={2} >
                                                    <Form.Item>
                                                        <Button type='dashed' icon={<RemoveCircleOutlineOutlinedIcon fontSize='small' />} onClick={() => remove(name)} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>))}
                                </>)}
                        </Form.List>}
                        <Col xs={24} sm={12} md={16} lg={17} xl={18} ></Col>
                        <Col xs={16} sm={12} md={8} lg={7} xl={6} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" loading={loading} block>
                                    {title ? title : uuid ? "Speichern" : 'Erstellen'}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </div >
    );
};