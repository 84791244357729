import { Select, Form, Tooltip, message } from 'antd';
import { useEffect, useState } from 'react';

export const CourseTypeSelect = ({ startValue, ghost, onChange, disabled, mainApi, filterStatus = "", tooltip = "Kursart Wählen", label = "Kursart", name = "courseType" }) => {
    const [data, setData] = useState([])
    const [error, setError] = useState(false)
    const [selectedValue, setSelectedValue] = useState(startValue);

    const getData = async () => {
        try {
            const response = await mainApi.get(`/courseType/all?status=${filterStatus}`);
            setData(response.data.courseType)
        } catch (error) {
            setError(true)
            message.error("Probleme beim Laden des Moduls: " + error?.response?.data?.message && error?.message)
        }
    };

    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    useEffect(() => {
        setSelectedValue(startValue)
        getData();
    }, [startValue]);

    const result = (
        <>
            <Select
                disabled={error || disabled}
                showSearch
                allowClear
                onChange={(e) => {
                    setSelectedValue(e);
                    if (onChange) {
                        onChange(e);
                    }
                }}
                value={selectedValue}
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
                style={{ width: '100%' }}
                placeholder={"Kursart"}
                options={
                    data?.map((value) => ({
                        value: value?.uuid,
                        label: value?.courseTypeAccessible?.informations[0]?.title
                    }))
                }>
            </Select>
        </>
    )

    return (<>
        {!ghost ?
            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title={tooltip}>
                <Form.Item
                    hasFeedback
                    name={name}
                    rules={[
                        {
                            required: true,
                            message: `Bitte die ${label} wählen`,
                        },
                    ]}
                >
                    {result}
                </Form.Item>
            </Tooltip >
            :
            result}
    </>);
};