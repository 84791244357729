// Sidebar.js
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Space, Card, Divider, Menu, Flex, Modal, Drawer, Button, FloatButton, Dropdown } from "antd";

import swimy from '../img/swimy.png'
import logo from '../img/logo.png'
import csLogo from '../img/CourseSync-Customer-White.png'

//TitleIcons
import PoolOutlinedIcon from '@mui/icons-material/PoolOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import DashboardCustomizeOutlinedIcon from '@mui/icons-material/DashboardCustomizeOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';

//MyCourseIcons
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';

//CourseIcons
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';


export function Sidebar(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [login, setLogin] = useState(false);

  const [current, setCurrent] = useState('mail');
  const onClick = (e) => {
    setCurrent(e.key);
  };

  useEffect(() => {
    if (localStorage.getItem("customToken") === "true") {
      setLogin(true)
    }
  }, []);


  const defaultData = [
    {
      label: (<div onClick={() => navigate('/')}> Startseite </div>),
      label_sm: (<div onClick={() => navigate('/course')}> Home </div>),
      key: 'home',
      icon: <HomeOutlinedIcon />,
    },
    { type: "divider" },
    {
      label: (<div onClick={() => navigate('/course')}> Anmeldung </div>),
      label_sm: (<div onClick={() => navigate('/course')}> Anmeldung </div>),
      key: 'booking',
      icon: <HowToRegOutlinedIcon />,
    },
    // {
    //   label: (<div onClick={() => navigate('/location')}> Standorte </div>),
    //   label_sm: (<div onClick={() => navigate('/location')}> Bad </div>),
    //   key: 'location',
    //   icon: <LocationOnOutlinedIcon />,
    // },
    // {
    //   label: (<div onClick={() => navigate('/courseType')}> Kursübersicht </div>),
    //   label_sm: (<div onClick={() => navigate('/courseType')}> Kurse </div>),
    //   key: 'courseType',
    //   icon: <ListOutlinedIcon />,
    // },
  ];
  const loginData = [
    { type: "divider" },
    {
      label: (<div onClick={() => navigate('/my-course')}> Meine Kurse </div>),
      label_sm: (<div onClick={() => navigate('/my-course')}> Kurse </div>),
      key: 'my-course',
      icon: <PoolOutlinedIcon />,
    },
    { type: "divider" },
    {
      label: (<div onClick={() => navigate('/participants')}> Meine Teilnehmer </div>),
      label_sm: (<div onClick={() => navigate('/participants')}> Teilnehmer </div>),
      key: 'participants',
      icon: <GroupOutlinedIcon />,
    },
    { type: "divider" },
    // {
    //   label: (<div onClick={() => navigate('/chat')}> Nachrichten </div>),
    //   label_sm: (<div onClick={() => navigate('/chat')}> Nachrichten </div>),
    //   key: 'chat',
    //   icon: <ChatBubbleOutlineOutlinedIcon />,
    // },

    {
      label: (<div onClick={() => navigate('/profile')}> Profil </div>),
      label_sm: (<div onClick={() => navigate('/profile')}> Profil </div>),
      key: 'profile',
      icon: <AccountBoxOutlinedIcon />,
    },
  ];

  const data = login ? defaultData.concat(loginData) : defaultData;

  switch (props.layout) {
    case "md":
      return ("");

    case "mobile":
      return (
        <React.Fragment>
          <FloatButton
            onClick={() => { setOpen(true) }}
            type='primary' shape='square'
            icon={<MenuOutlinedIcon fontSize='small' />}
          />
          <Drawer onClose={() => setOpen(false)} open={open}
            style={{ backgroundColor: '#1a4568' }}
            width={250} placement="left"
            title={<img style={{ width: "80%" }} src={logo} alt="logo" />}
            footer={<img style={{ width: "90%" }} src={csLogo} alt="logo" />}
          >
            <Menu onClick={onClick} selectedKeys={[current]} theme="dark" mode="inline" type="divider" items={data} style={{ background: "transparent", width: "100%" }} />
          </Drawer>
        </React.Fragment>
      );

    default:
      return (
        <React.Fragment>
          <Flex vertical justify="space-between" align='start' gap='small' style={{ overflow: "auto", backgroundColor: '#1a4568', height: '100%', width: '100%' }}>
            <div>
              <Space style={{ marginLeft: 20 }}>
                <img style={{ width: "90%" }} src={logo} alt="logo" />
              </Space>
              <Divider style={{ margin: 0 }}></Divider>
              <Menu onClick={onClick} selectedKeys={[current]} theme="dark" mode="inline" type="divider" items={data} style={{ background: "transparent", width: "100%" }} />
              <Col>
              </Col>
            </div>
            <div>
              {/* <Card
                size="small"
                style={{ margin: 15 }}
                actions={[
                  <div></div>,
                  <Button type="primary" size="small">Mehr erfahren</Button>
                ]}>
                <Card.Meta
                  title="Tutorial"
                  description={`Sehen Sie sich die Möglichkeiten der My-Swimy-App an.`}>
                </Card.Meta>
              </Card> */}
              <Divider></Divider>
              <Space style={{ marginLeft: 10, marginBottom: 10 }}>
                <img style={{ width: "90%" }} src={csLogo} alt="logo" />
              </Space>
            </div>
          </Flex>
        </React.Fragment>

      );

  }
};


export default Sidebar;