import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Space, message, Row, Col, Divider, Tooltip, Select } from 'antd';
import { ErrorDisplay } from "../.."
import TransgenderOutlinedIcon from '@mui/icons-material/TransgenderOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NumbersOutlinedIcon from '@mui/icons-material/NumbersOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const { Option } = Select;
export const CustomerForm = ({ title, isSuccess, uuid, custApi }) => {
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(true)
    const [form] = Form.useForm();

    const getLocalData = () => {
        const storedUser = JSON.parse(localStorage.getItem("user")) || {};
        if (storedUser.customer) { getData(storedUser.customer.uuid) } else {
            form.setFieldValue("email", storedUser.email)
            form.setFieldValue("firstname", storedUser.userName)
        }
    };

    useEffect(() => {
        setError("")
        if (uuid) { getData(uuid); } else { getLocalData() }
        setLoading(false)
    }, []);


    const getData = async (uuid) => {
        try {
            setLoading(true);
            const response = await custApi.get(`/customer/one/${uuid}`);
            const userData = {
                sex: response?.data?.customer?.person?.sex,
                firstname: response?.data?.customer?.person?.name?.firstname,
                lastname: response?.data?.customer?.person?.name?.lastname,
                email: response?.data?.customer?.email,
                phoneNumber: response?.data?.customer?.phone?.phoneNumber,
                street: spiltStreet(response?.data?.customer?.adress?.street, 0),
                streetNumber: spiltStreet(response?.data?.customer?.adress?.street, 1),
                postalCode: response?.data?.customer?.adress?.postalCode,
                city: response?.data?.customer?.adress?.city,
                country: response?.data?.customer?.adress?.country,
                origin: response?.data?.customer?.origin,
            }
            form.setFieldsValue(userData);
        } catch (error) {
            setError('Fehler beim Laden der Benutzerdetails.' + error?.message);
        }
    };

    const onFinish = async (values) => {
        setLoading(true);
        values.street = values.street + " " + values.streetNumber;
        let response
        try {
            if (uuid) {
                response = await custApi.patch(`/customer/update/${uuid}`, values);
            } else {
                let storedUser = JSON.parse(localStorage.getItem("user")) || {};
                values.userId = storedUser?.uuid
                response = await custApi.post(`/customer`, values);
                storedUser.customer = response.data.customer;
                localStorage.setItem("user", JSON.stringify(storedUser))
            }
            isSuccess(response?.data?.customerId);
        } catch (error) {
            message.error(`Fehlgeschlagen: ${(error?.response?.data?.message)}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <ErrorDisplay error={error} loading={loading}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <Form
                    name="profileForm"
                    className="register-form"
                    requiredMark={false}
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    validateTrigger="onChange"
                    style={{ width: "100%" }}
                >
                    <Space direction="vertical">
                        <h3>{uuid ? 'Profil ändern' : 'Profil anlegen'}</h3>
                    </Space>
                    <Spin spinning={loading} size="large">
                        <Row gutter={[16, 0]}>

                            {/*Person*/}
                            <Col span={24}>
                                <Divider orientation='left' orientationMargin="0"><AccountCircleOutlinedIcon /> Person</Divider>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={6} >
                                <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Wie möchten Sie angesprochen Werden">
                                    <Form.Item
                                        name="sex"
                                        rules={[{ required: true, message: 'Bitte Anrede auswählen!' }]}
                                    >
                                        <Select prefix={<TransgenderOutlinedIcon fontSize='small' />} placeholder="Anrede">
                                            <Option value="male">Herr</Option>
                                            <Option value="female">Frau</Option>
                                            <Option value="other">Person</Option>
                                        </Select>
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                            <Col xs={12} sm={12} md={18} lg={18} xl={18} ></Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                                <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Ihr Vorname">
                                    <Form.Item
                                        hasFeedback
                                        name="firstname"
                                        rules={[{
                                            required: true,
                                            min: 2,
                                            message: 'Bitte Ihren Vornamen eingeben!',
                                        },
                                        {
                                            pattern: /^[a-zA-ZÀ-ÿ ]+$/i,
                                            message: 'Der Vorname darf nur Buchstaben enthalten!',
                                        },
                                        {
                                            max: 50,
                                            message: 'Der Vorname ist zu Lang!',
                                        },
                                        ]}>
                                        <Input prefix={<PersonOutlineOutlinedIcon fontSize='small' />} placeholder="Vorname" />
                                    </Form.Item>
                                </Tooltip>
                            </Col>
                            <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                                <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Ihr Nachname">
                                    <Form.Item
                                        hasFeedback
                                        name="lastname"
                                        rules={[{
                                            required: true,
                                            min: 2,
                                            message: 'Bitte Ihren Nachnamen eingeben!',
                                        },
                                        {
                                            pattern: /^[a-zA-ZÀ-ÿ ]+$/i,
                                            message: 'Der Nachname darf nur Buchstaben enthalten!',
                                        },
                                        {
                                            max: 50,
                                            message: 'Der Nachname ist zu Lang!',
                                        },
                                        ]}>
                                        <Input prefix={<PersonOutlineOutlinedIcon fontSize='small' />} placeholder="Nachname" />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            {/*Kontaktdaten*/}
                            <Col span={24}>
                                <Divider orientation='left' orientationMargin="0"><AlternateEmailOutlinedIcon /> Kontaktdaten</Divider>
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="E-Mail-Adresse für den Kontakt">
                                    <Form.Item
                                        hasFeedback
                                        name="email"
                                        rules={[{
                                            required: true,
                                            type: 'email',
                                            message: 'Bitte eine gültige E-Mail-Adresse eingeben',
                                        },
                                        {
                                            max: 255,
                                            message: 'Die E-Mail-Adresse ist zu lang!',
                                        },
                                        ]}>
                                        <Input prefix={<AlternateEmailOutlinedIcon fontSize='small' />} placeholder="E-Mail-Adresse" />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Telefonnummer (ggf. für Notfälle)">
                                    <Form.Item
                                        hasFeedback
                                        name="phoneNumber"
                                        rules={[{
                                            required: true,
                                            message: 'Bitte Ihre Telefonnummer eingeben!',
                                        },
                                        {
                                            pattern: /^(0|\+)[0-9]+$/,
                                            message: 'Die Telefonnummer ist ungültig! Bitte im Format: +123456789 angeben',
                                        },
                                        {
                                            max: 25,
                                            message: 'Die Telefonnummer ist zu lang!',
                                        },
                                        ]}>
                                        <Input prefix={<LocalPhoneOutlinedIcon fontSize='small' />} placeholder="Telefon" />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col xs={16} sm={18} md={18} lg={18} xl={18} >
                                <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Rechnungsadresse">
                                    <Form.Item
                                        hasFeedback
                                        name="street"
                                        rules={[{
                                            required: true,
                                            min: 2,
                                            message: 'Bitte eine Straße angeben!',
                                        },
                                        {
                                            max: 200,
                                            message: 'Die E-Mail-Adresse ist zu lang!',
                                        },
                                        {
                                            pattern: /^[a-zA-ZäöüßÄÖÜ \-.,]+$/i,
                                            message: 'Bitte ein gültiges Format eingeben!'
                                        }
                                        ]}>
                                        <Input prefix={<HomeOutlinedIcon fontSize='small' />} placeholder="Straße" />
                                    </Form.Item>
                                </Tooltip>
                            </Col>

                            <Col xs={8} sm={6} md={6} lg={6} xl={6} >
                                <Form.Item
                                    hasFeedback
                                    name="streetNumber"
                                    rules={[{
                                        required: true,
                                        message: 'Bitte eine Hausnummer angeben!',
                                    },
                                    {
                                        pattern: /^[1-9][a-zA-Z0-9äöüßÄÖÜ \-.,]*$/i,
                                        message: 'Bitte ein gültiges Format eingeben!'
                                    },
                                    ]}>
                                    <Input prefix={<NumbersOutlinedIcon fontSize='small' />} placeholder="Nr." />
                                </Form.Item>
                            </Col>
                            <Col xs={8} sm={6} md={6} lg={6} xl={6} >
                                <Form.Item
                                    hasFeedback
                                    name="postalCode"
                                    rules={[{
                                        required: true,
                                        message: 'Bitte die PLZ eingeben',
                                    },
                                    {
                                        pattern: /^\d{5}$/,
                                        message: 'Bitte ein gültiges Format eingeben! [12345]'
                                    }
                                    ]}>
                                    <Input prefix={<LocationOnOutlinedIcon fontSize='small' />} placeholder="PLZ" />
                                </Form.Item>
                            </Col>
                            <Col xs={16} sm={18} md={18} lg={18} xl={18} >
                                <Form.Item
                                    hasFeedback
                                    name="city"
                                    rules={[{
                                        required: true,
                                        min: 2,
                                        message: 'Bitte die Stadt eingeben!',
                                    },
                                    {
                                        pattern: /^[a-zA-ZäöüßÄÖÜ \-]+$/i,
                                        max: 100,
                                        message: 'Die Stadt ist ungültig!',
                                    },
                                    ]}>
                                    <Input prefix={<LocationCityOutlinedIcon fontSize='small' />} placeholder="Stadt" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                <Form.Item
                                    hasFeedback
                                    name="country"
                                    rules={[{
                                        required: true,
                                        min: 2,
                                        message: 'Bitte ein Land eingeben!',
                                    },
                                    {
                                        pattern: /^[a-zA-ZäöüßÄÖÜ \-]+$/i,
                                        max: 100,
                                        message: 'Das Land ist ungültig!',
                                    },
                                    ]}>
                                    <Input prefix={<LocationCityOutlinedIcon fontSize='small' />} placeholder="Land" />
                                </Form.Item>
                            </Col>

                            <Col xs={8} sm={12} md={16} lg={17} xl={18} >
                                <Form.Item name="origin"></Form.Item>
                            </Col>
                            <Col xs={16} sm={12} md={8} lg={7} xl={6} >
                                <Form.Item >
                                    <Button type="primary" htmlType="submit" loading={loading} block>
                                        {title ? title : uuid ? "Speichern" : 'Erstellen'}
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Spin>
                </Form>
            </div >
        </ErrorDisplay>
    );
};

function spiltStreet(string, part) {
    const lastIndex = string.lastIndexOf(" ");
    if (part === 0) {
        return string.slice(0, lastIndex);
    }
    return string.slice(lastIndex + 1);
}