import React from 'react';
import { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import { Spin, Watermark } from "antd";
import axios from 'axios';
import { SystemApi, customAuthApi, customCustomerApi } from './urlConfig.js';
import './style.scss'
import './App.css';
//Routes
import Dashboard from './pages/dashborad/DashboardPage.jsx'
import RenewAuthPage from './pages/start/RenewAuthPage.jsx';
import CustomerForm from './components/forms/start/CustomerForm.jsx';
import AuthPage from './pages/start/AuthPage.jsx';
import ProfilePage from './pages/profile/profilePage.jsx';
import ParticipantPage from './pages/participant/ParticipantPage.jsx';
import CoursePage from './pages/public/booking/CoursePage.jsx';
import LinksPage from './pages/start/LinksPage.jsx';
import MyCoursePage from './pages/my-courses/MyCoursePage.jsx';
import { ErrorDisplay, ErrorPage, SuccessPage } from './package/index.js';

export function App() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const checkToken = async () => {
    try {
      if (localStorage.getItem("user")) {
        const response = await customAuthApi.get(`/verify`);

        if (response.status === 200) {
          localStorage.setItem("customToken", true);
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
        localStorage.removeItem("customToken");
        localStorage.removeItem("user");
        setLoading(false);
      }
    } finally {
      setLoading(false);
    }
  }

  const getTest = async () => {
    try {
      await customAuthApi.get("/test");
      await customCustomerApi.get("/test");
      //await SystemApi.get("/test");
    } catch (error) {
      setError("Probleme beim Laden der Daten: " + error)
    }
  };

  useEffect(() => {
    getTest();
    checkToken();
    const interval = setInterval(() => {
      checkToken();
    }, 1000 * 60 * 2); //2 min

    return () => clearInterval(interval);
  }, [new URLSearchParams(window.location.search)]);


  return (
    <React.Fragment>
      <Watermark content={""} gap={[500, 500]}>
        <ErrorDisplay error={error} loading={loading}>
          <Routes>
            <Route path="/register/new" element={<RenewAuthPage />} />
            <Route path="/success" element={< SuccessPage />} />
            <Route path="/error" element={<ErrorPage />} />
            <Route path="/auth/:token" element={<AuthPage />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/my-course/*" element={<MyCoursePage />} />
            <Route path="/customer" element={<CustomerForm />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route path="/participants" element={<ParticipantPage />} />
            <Route path="/course" element={<CoursePage />} />

            <Route path="/debug" element={< LinksPage link={"debug"} />} />
            <Route path="/ex" element={< LinksPage link={"ex"} />} />
            <Route path="/register" element={< LinksPage link={"register"} />} />
            <Route path="/settings" element={< LinksPage link={"settings"} />} />
            <Route path="/login" element={< LinksPage link={"login"} />} />
            <Route path="/privacy" element={< LinksPage link={"privacy"} />} />
            <Route path="/impressum" element={< LinksPage link={"impressum"} />} />


            <Route path="*" element={<Navigate to="/404" />} />
            <Route path="/404" element={<ErrorPage data={{title: "Seite nicht gefunden", message: " ", error: "404 Seite nicht gefunden", primaryButton: {link: "/", label: "Startseite"} }} />} />
          </Routes>
        </ErrorDisplay>
      </Watermark>
    </React.Fragment>
  );
};

export default App;