import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Space, message, Row, Col, Divider, Tooltip, Checkbox, Select, DatePicker } from 'antd';
import moment from 'moment';
import { isValidBIC } from 'ibantools';

import ApartmentOutlinedIcon from '@mui/icons-material/ApartmentOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';

const { Option } = Select;
export const EmployeeBankForm = ({ title, isSuccess, uuid, empApi }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();

    useEffect(() => {
        if (uuid) { getData() }
    }, []);

    const getData = async () => {
        try {
            setLoading(true);
            const response = await empApi.get(`/employee/one/${uuid}`);
            const data = {
                iban: response?.data?.employee?.bankDetails?.iban,
                bic: response?.data?.employee?.bankDetails?.bic,
                bank: response?.data?.employee?.bankDetails?.bank,
            }
            form.setFieldsValue(data);
        } catch (error) {
            message.error("Fehler beim Laden der Daten. " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    const ibanValidator = (_, value) => {
        const ibanRegex = /^[DE]{2}\d{20}$/;
        if (!ibanRegex.test(value)) {
            return Promise.reject('Ungültige IBAN');
        }
        return Promise.resolve();
    };

    const bicValidator = (_, value) => {
        if (!isValidBIC(value)) {
            return Promise.reject('Ungültiger BIC');
        }
        return Promise.resolve();
    }

    const onFinish = async (values) => {
        const data = {
            bankDetails: {
                iban: values.iban,
                bic: values.bic,
                bank: values.bank,
            },
        }
        try {
            setLoading(true);
            await empApi.patch(`/employee/update/${uuid}`, data);
            isSuccess();
        } catch (error) {
            message.error("Fehlgeschlagen: " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Form
                name="employeeBankForm"
                requiredMark={false}
                form={form}
                onFinish={onFinish}
                layout="vertical"
                validateTrigger="onChange"
                style={{ width: "100%" }}
            >
                <Space direction="vertical">
                    <h3>{"Finazen"}</h3>
                </Space>
                <Spin spinning={false} size="large" loading={loading}>
                    <Row gutter={[16, 0]}>

                        {/**/}
                        <Col span={24}>
                            <Divider orientation='left' orientationMargin="0"><AccountBalanceOutlinedIcon /> Finanzen</Divider>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="IBAN für die Gehaltsüberweisungen">
                                <Form.Item
                                    name="iban"
                                    rules={[
                                        { required: true, message: 'Bitte geben Sie eine IBAN ein' },
                                        { validator: ibanValidator },
                                    ]}
                                >
                                    <Input prefix={<AccountBalanceOutlinedIcon fontSize='small' />} placeholder='IBAN' />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={10} md={10} lg={10} xl={10} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="BIC">
                                <Form.Item
                                    name="bic"
                                    rules={[
                                        { required: true, message: 'Bitte geben Sie einen BIC ein' },
                                        { validator: bicValidator },
                                    ]}
                                >
                                    <Input prefix={<AccountBalanceOutlinedIcon fontSize='small' />} placeholder='BIC' />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col xs={24} sm={14} md={14} lg={14} xl={14} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Name der Bank">
                                <Form.Item
                                    hasFeedback
                                    name="bank"
                                    rules={[{
                                        required: true,
                                        min: 2,
                                        message: 'Bitte die Bank angeben!',
                                    },
                                    {
                                        max: 50,
                                        message: 'Die Bank ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<ApartmentOutlinedIcon fontSize='small' />} placeholder="Bank" />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col xs={12} sm={18} md={18} lg={18} xl={18} ></Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" loading={loading} block>
                                    {title ? title : uuid ? "Speichern" : 'Erstellen'}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </div >
    );
};