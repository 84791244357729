import { Card, Drawer, Modal, Row, Space } from "antd";

export const ModalDisplay = ({ closeIcon, footer, extra, header, onCancel, open, content, closable = true, mask = true }) => {
    const isMobile = window.innerWidth < 991
    return (
        <Drawer
            closable={closable}
            destroyOnClose
            maskClosable={false}
            closeIcon={closeIcon}
            placement={isMobile ? "top" : "right"}
            footer={footer}
            title={header}
            extra={extra}
            onClose={onCancel}
            styles={{
                footer: { background: "#f0f0f0" },
                body: { background: "#f0f0f0", padding: 5 },
                header: { backgroundColor: "#9dcedf" }
            }}
            open={open}
            width={isMobile ? '100%' : "40%"}
            height={'80%'}
        >
            <Row align="middle" justify="center">
                <Card style={{ width: "100%" }} >
                    {content}
                </Card>
            </Row>
        </Drawer >
    )
};

// <Modal
//     centered
//     closable={closable}
//     destroyOnClose
//     maskClosable={false}
//     footer={false}
//     onCancel={onCancel}
//     open={open}
//     width={'100%'}
//     //style={{ maxWidth: '800px', maxHeight: "80%", overflow: 'auto' }}
//     styles={ mask? { mask: { backdropFilter: 'blur(10px)' } }: {}}
// >
//     {content}
// </Modal >
// <Drawer
//     centered
//     closable={closable}
//     destroyOnClose
//     maskClosable={false}
//     placement="bottom"
//     footer={false}
//     onClose={onCancel}
//     open={open}
//     width={'100%'}
//     height={'90%'}
// >
//     {content}
// </Drawer >