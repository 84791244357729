import React from "react";
import { Descriptions, Row, Col, Divider, Card, Tag, Skeleton, Space } from 'antd';
//Icons
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

export const EmployeeInsuranceData = ({ data, card }) => {
    const { type, insuranceNumber, institution } = data.insuranceData;

    const items = [
        {
            key: '1',
            label: (<> <HomeOutlinedIcon fontSize="small" /> Art: </>),
            children: (<Space direction="vertical">
                {type || <Skeleton paragraph={{ rows: 0 }} />}
            </Space>),
            span: 2,
        },
        {
            key: '2',
            label: (<> <HomeOutlinedIcon fontSize="small" /> Institution: </>),
            children: (<Space direction="vertical">
                {institution || <Skeleton paragraph={{ rows: 0 }} />}
            </Space>),
            span: 2,
        },
        {
            key: '3',
            label: (<> <HomeOutlinedIcon fontSize="small" /> Rentenversicherungsnummer: </>),
            children: (<Space direction="vertical">
                {insuranceNumber || <Skeleton paragraph={{ rows: 0 }} />}
            </Space>),
            span: 24,
        },
    ]

    if (card) {
        return (
            <Card
                size="small"
                title={<Divider orientation='left' orientationMargin="0"><LockPersonOutlinedIcon /> Krankenversicherungsdaten </Divider>}
                headStyle={{ backgroundColor: '#9dcedf' }}
            >
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                        <Descriptions size="small" bordered layout="vertical" items={items} />
                    </Col>
                </Row>
            </Card>

        );
    }
    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                    <Divider orientation='left' orientationMargin="0"><LockPersonOutlinedIcon /> Krankenversicherungsdaten </Divider>
                    <Descriptions size="small" bordered layout="vertical" items={items} />
                </Col>
            </Row>
        </>

    );
};