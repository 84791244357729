import React from "react";
import { Row, Col, Button, Result } from "antd";

export const SuccessPage = ({ data = "" }) => {
    const { title, message, primaryButton, secondaryButton, icon } = data

    return (
        <React.Fragment>
            <Row justify="center" align="middle">
                <Col xs={24} sm={20} md={18} lg={16} xl={14}>
                    <Result
                        icon={icon ? icon : ''}
                        status="success"
                        title={title ? title : "Erfolgreich"}
                        subTitle={message ? message : "Der Vorgang ist erfolgreich abgeschlossen"}
                        extra={[
                            primaryButton ?
                                [<Button type="primary" href={primaryButton.link}>
                                    {primaryButton.label}
                                </Button>]
                                : '',
                            secondaryButton ?
                                [<Button href={secondaryButton.link}>
                                    {secondaryButton.label}
                                </Button>] : '',
                        ]}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default SuccessPage;
