import React, { useEffect, useState } from "react";
import { Card, Tag, Space, List, Collapse, Row, Col, Button, Badge, Divider, Popover, Flex, Grid } from 'antd';
import { swimy, ModalDisplay, FullParticipationForm, ErrorDisplay, SimpleCourseDateList, fkaIcon, nsbIcon } from "../..";
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import Meta from "antd/es/card/Meta";
import PoolOutlined from "@mui/icons-material/PoolOutlined";

export const BookingItem = ({ item, refresh, mainApi, custApi, authApi, size, isSuccess }) => {
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState(false);
    const [location, setLocation] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    const getData = async () => {
        try {
            setLoading(true);
            const response = await mainApi.get(`/location/all?limit=1&locationIds=${item?.courseDates?.[0]?.unit.locationId}`);
            setLocation(response?.data?.location?.[0]);
        } catch (error) {
            setError(`Fehler beim Laden der Daten. ${(error?.response?.data?.message)}`);
            setError(`Fehler beim Laden der Daten. ${(error?.message)}`);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getData();
        setSuccess(false)
    }, [success, item]);

    const getStatus = (value) => {
        if (value < 0.5) {
            return { color: "green", text: "Ausreichend Plätze verfügbar" }
        }
        if (value < 0.6) {
            return { color: "orange", text: "Noch wenig Plätze verfügbar" }
        }
        if (value < 1) {
            return { color: "red", text: "Noch sehr wenig Plätze verfügbar" }
        }
        return { color: "", text: "Keine Plätze verfügbar", disabled: true }
    }
    const amount = (item?.participants) / item?.courseType?.places;

    return (
        <List.Item style={{ padding: 0, paddingBlockStart: 10 }}>
            <ErrorDisplay error={error} loading={loading}>
                <Card
                    size="small"
                    // extra={<Space>
                    //     <img style={{ width: "25px" }} src={nsbIcon} alt="logo" />
                    //     <img style={{ width: "25px" }} src={fkaIcon} alt="logo" />
                    // </Space>}
                    type="inner" style={{ margin: 0, width: "100%" }} styles={{ header: { background: 'lightgray' } }}
                    title={
                        <Card.Meta
                            style={{ margin: 5 }}
                            avatar={<Space>
                                <img style={{ width: "40px" }} src={swimy} alt="logo" />
                            </Space>
                            }
                            title={
                                <Row align="middle" gutter={[4, 0]} justify="space-between">
                                    <Col flex>
                                        <Space style={{ whiteSpace: 'pre-wrap', color: "#1a4568", fontSize: 16 }}>{item?.courseType?.courseTypeAccessible?.informations[0]?.title}</Space>
                                    </Col>
                                    {getStatus(amount).text === "" ? "" : <Col align="end">
                                        <Tag color={getStatus(amount).color}>{getStatus(amount).text}</Tag>
                                    </Col>}
                                </Row>
                            } />}
                >
                    <Row style={{ padding: 0, margin: 0 }} gutter={[8, 0]} align="middle">
                        <Col align="center" span={8}>
                            {findSmallestTimestamp(item?.courseDates)}
                        </Col>
                        {size !== "full" ?
                            <Col align="center" span={16}>
                                <Row style={{ padding: 0 }} gutter={[0, 2]} >
                                    <Col align={size === "full" ? "center" : "start"} span={24}>
                                        {displayLocation(location, size)}
                                    </Col>
                                    <Col align={size === "full" ? "center" : "start"} span={24}>
                                        {displayDates(item?.courseDates, item?.price)}
                                    </Col>
                                    <Col align="center" span={24}>
                                        <Button
                                            block type="primary"
                                            disabled={getStatus(amount).disabled}
                                            onClick={() => setOpen(true)}>Weiter</Button>
                                    </Col>
                                </Row>
                            </Col>
                            : <>
                                <Col align="center" span={16}>
                                    {displayLocation(location, size)}
                                </Col>
                                <Divider style={{ margin: 8 }}></Divider>
                                <Col align="center" span={24}>
                                    <SimpleCourseDateList ghost dates={item?.courseDates} />
                                </Col>
                                <Divider style={{ margin: 8 }}></Divider>
                                <Col align="start" span={12}>
                                    {displayDates(item?.courseDates, item?.price, size)}
                                </Col>
                                <Col align="center" span={12}>
                                    <Button
                                        block type="primary"
                                        //disabled={getStatus(amount).disabled}
                                        onClick={() => isSuccess()}>Anmelden</Button>
                                </Col>
                            </>}
                    </Row>

                    <ModalDisplay
                        onCancel={() => {
                            setOpen(false)
                            refresh(true)
                        }
                        }
                        open={open}
                        header={"Kursanmeldung"}
                        extra={<PoolOutlined />}
                        content={<FullParticipationForm
                            mainApi={mainApi}
                            custApi={custApi}
                            authApi={authApi}
                            courseId={item.uuid}
                            isSuccess={() => {
                                setOpen(false)
                                refresh(true)
                            }}
                        />} />
                </Card>
            </ErrorDisplay >
        </List.Item >
    )
};



const findSmallestTimestamp = (courseDates) => {
    const allStartTimes = courseDates?.map(courseDate => courseDate?.time?.publicStart).flat();
    if (allStartTimes && allStartTimes.length > 0) {
        const smallestTimestamp = new Date(Math.min(...allStartTimes.map(timestamp => new Date(timestamp))));
        const formattedDate = (
            <Row align={"center"} justify={"middle"} >
                <Col span={24}>
                    <b style={{ fontStyle: "italic", color: "#1a4568", fontSize: 18 }}>{smallestTimestamp.toLocaleString('de-DE', { weekday: "long" })}</b>
                </Col>
                <Col span={24}>
                    <div style={{ fontSize: 15 }}>
                        <div>{smallestTimestamp.toLocaleString('de-DE', { hour: "2-digit", minute: "2-digit" })} Uhr</div>
                        <div> {smallestTimestamp.toLocaleString('de-DE', { day: "2-digit", month: 'short' })}</div>
                    </div>
                </Col>
            </Row>
        )
        return formattedDate;
    } else {
        return "Keine Startzeiten vorhanden";
    }
};

const displayLocation = (location, size) => {
    if (size === "full") {
        return (
            < Row align={"center"} justify={"middle"} >
                <Col span={24}>
                    < Row align={"center"} justify={"middle"} >
                        <Col span={24}>
                            <LocationOnOutlinedIcon style={{ color: "#1a4568" }} />
                            <>{`${location?.locationAccessible?.informations[0].title}`}</>
                        </Col>
                        <Col span={24}>
                            <LocationOnOutlinedIcon style={{ color: "transparent" }} />
                            <>{location?.adress?.street}</>
                        </Col>
                        <Col span={24}>
                            <LocationOnOutlinedIcon style={{ color: "transparent" }} />
                            <>{location?.adress?.postalCode + " " + location?.adress?.city}</>
                        </Col>
                    </Row>
                </Col>
            </Row >
        )
    }
    return (
        <Popover
            placement="bottomRight"
            content={<>
                <div>{location?.adress?.street}</div>
                <div>{location?.adress?.postalCode + " " + location?.adress?.city}</div>
                <div>{location?.adress?.country}</div>
            </>
            } title={location?.locationAccessible?.informations[0].title}>
            <Row align={"center"} justify={"middle"}  >
                <Col span={24}>
                    <Space>
                        <LocationOnOutlinedIcon style={{ color: "#1a4568" }} />
                        <>{`${location?.locationAccessible?.informations[0].title}`}</>
                        <ExpandCircleDownOutlinedIcon style={{ color: "lightgray" }} fontSize="small" />
                    </Space>
                </Col>
            </Row>
        </Popover>
    )
};
const displayDates = (courseDates, price, size) => {
    return (
        <Popover
            placement="bottomRight"
            content={<SimpleCourseDateList ghost dates={courseDates} />} title={<>Termine:</>}>
            <Row align={"center"} justify={"middle"}  >
                <Col span={24}>
                    <Space>
                        <DateRangeOutlinedIcon style={{ color: "#1a4568" }} />
                        {`${price} €  /`}{`${courseDates?.length || 0} Termin${courseDates?.length === 1 ? "" : "e"}`}
                        {size === "full" ? "" : <li style={{ color: "gray" }}>mehr</li>}
                        {/* <ExpandCircleDownOutlinedIcon style={{ color: "lightgray" }} fontSize="small" /> */}
                    </Space>
                </Col>
            </Row>
        </Popover>
    )
};


// const body = (
//     <Row gutter={[0, 8]}>
//         <Col xs={24} sm={24} md={12} lg={12} xl={6}>
//             <Popover
//                 placement="bottomRight"
//                 content={<>
//                     <div>{location?.adress?.street}</div>
//                     <div>{location?.adress?.postalCode + " " + location?.adress?.city}</div>
//                     <div>{location?.adress?.country}</div>
//                 </>
//                 } title={location?.locationAccessible?.informations[0].title}>
//                 <Space>
//                     <LocationOnOutlinedIcon />
//                     {`${location?.locationAccessible?.informations[0].title}`}
//                     <div style={{ color: "gray" }}><ExpandCircleDownOutlinedIcon fontSize="small" /></div>
//                 </Space>
//             </Popover>
//         </Col>
//         <Col xs={24} sm={24} md={12} lg={12} xl={6}>
//             <Space>
//                 <AccessTimeOutlinedIcon />
//                 {`${item?.price} € / ${item?.courseDates?.length || 0} Termin${item?.courseDates?.length === 1 ? "" : "en"} à ${item?.courseType?.duration?.overallTime} Minuten`}
//             </Space>
//         </Col>
//         <Col xs={24} sm={24} md={12} lg={12} xl={6}>
//             <Popover
//                 placement="bottomRight"
//                 content={<SimpleCourseDateList ghost dates={item?.courseDates} />
//                 } title={<>Termine:</>}>
//                 <Space>
//                     <DateRangeOutlinedIcon />
//                     Beginn: {findSmallestTimestamp(item?.courseDates)}
//                     <div style={{ color: "gray" }}><ExpandCircleDownOutlinedIcon fontSize="small" /></div>
//                 </Space>
//             </Popover>
//         </Col>
//         <Col xs={24} sm={24} md={12} lg={12} xl={6}>
//             <Button
//                 style={{ backgroundColor: (getStatus(amount).color) }}
//                 block
//                 type="primary"
//                 disabled={getStatus(amount).disabled}
//                 onClick={() => setOpen(true)}>
//                 Anmelden bis: {new Date(item.release.end).toLocaleDateString()}
//             </Button>
//         </Col>
//     </Row>
// )