import React, { useState, useEffect } from 'react';
import { Row, Col, FloatButton, Drawer } from "antd";
import Sidebar from '../layout/Sidebar';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

export function Navbar({ children }) {
  const [layout, setLayout] = useState('');
  const [showSidebar, setShowSidebar] = useState(true);
  const [open, setOpen] = useState(false);

  const handleToggleSidebar = () => {
    showSidebar ? setShowSidebar(false) : setShowSidebar(true);
  };


  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 991) {
        setLayout('');
      } else if (window.innerWidth < 576) {
        setLayout('mobile');
      } else {
        setLayout('md');
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  switch (layout) {
    case "md":
      return (
        <Row className={`app bg-light`} style={{ maxHeight: '100vh' }}>
          {showSidebar ?
            <Col sm={9} md={7}>
              <Sidebar layout={''} />
            </Col>
            : ''
          }
          <FloatButton icon={<MenuOpenOutlinedIcon />} type="primary" onClick={handleToggleSidebar} style={{ position: 'absolute', top: 80, left: 0 }} />
          <Col sm={showSidebar ? 15 : 24} md={showSidebar ? 17 : 24} className="main-content max-vh-100 overflow-x-hidden">
            {children}
          </Col>
        </Row>
      );

    case "mobile":
      return (
        <Row className={`app bg-light`} style={{ maxHeight: '100vh' }}>
          <Col xs={24} style={{ height: '100vh' }}>
            <Sidebar layout={'mobile'} />
            {children}
          </Col>
        </Row>
      );

    default:
      return (
        <Row className={`app bg-light`} style={{ maxHeight: '100vh' }}>
          <Col lg={6} xl={5} xxl={4}>
            <Sidebar layout={''} />
          </Col>
          <Col lg={18} xl={19} xxl={20} style={{ maxHeight: '100vh' }}>
            {children}
          </Col>
        </Row>
      );
  }
};

export default Navbar;
