import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SystemApi, customAuthApi, customCustomerApi, testApi } from '../urlConfig';
import { Modal, Button, message, Flex, Space, Popconfirm } from "antd";
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useState } from 'react';
import { CustomerLogInForm, InitPageForm, ModalDisplay, RegisterForm } from '../package';
import Settings from './Settings';
import Cookies from './Cookies';
import swimy from '../img/swimy.png'
import RegisterPage from '../package/custom/user/RegisterPage';

export function Header({ mode, data, select, isOpen, layout }) {
  const navigate = useNavigate();
  const [login, setLogin] = useState(false);
  const [init, setInit] = useState(false);
  const [open, setOpen] = useState(false);
  const [register, setRegister] = useState(false);
  const [settings, setSettings] = useState(false);
  const [cookie, setCookie] = useState(false);

  const handleLogout = async () => {
    try {
      await customAuthApi.post('/logout');
      setLogin(false)
      localStorage.removeItem("user");
      localStorage.setItem("customToken", false);
      navigate("/?logout=1")
      window.location.reload();
    } catch (error) {
      message.error(`Abmeldung Fehlgeschlagen: ${error.response.data.message}`);
    }
  };

  const checkInit = () => {
    const localUser = JSON.parse(localStorage.getItem("user"))
    if (localUser && !localUser.customer) {
      setInit(true);
    }
  };

  const checkCookie = () => {
    if (localStorage.getItem("customToken") === "true") {
      setLogin(true)
    } else if (localStorage.getItem("cookie") !== "true") {
      setCookie(true)
    }
  };

  const stateSetter = () => {
    setOpen(new URLSearchParams(window.location.search).get("login") == 1 ? true : false)
    setRegister(new URLSearchParams(window.location.search).get("register") == 1 ? true : false)
    setSettings(new URLSearchParams(window.location.search).get("settings") == 1 ? true : false)
    setCookie(new URLSearchParams(window.location.search).get("cookie") == 1 ? true : false)
    if (new URLSearchParams(window.location.search).get("ex") == 1) { handleLogout() }
  };

  useEffect(() => {
    stateSetter();
    checkCookie();
    checkInit()
  }, []);

  return (
    <React.Fragment>
      <Flex justify='space-between' style={{ backgroundColor: "#9dcedf", height: 75 }}>
        <Space size="large" style={{ padding: 10 }}>
          <img style={{ width: "40px" }} src={swimy} alt="logo" />
          <h6>{data?.title}</h6>
        </Space>
        <Space style={{ padding: 15 }}>
          <Settings mode="drawer" isSuccess={(value) => setCookie(value === "cookie" ? true : false)} />
          {login ?
            <Button icon={<LogoutOutlinedIcon />} type='' size='small' onClick={handleLogout} ></Button>
            : <Button icon={<LoginOutlinedIcon />} size='small' onClick={() => setOpen(true)} type=''></Button>
          }
        </Space>
        <ModalDisplay
          onCancel={() => {
            navigate("/")
            setOpen(false)
          }}
          open={open}
          content={
            <CustomerLogInForm
              linkRegister={"/?register=1"}
              authApi={customAuthApi}
              isSuccess={(data) => {
                setOpen(false)
                window.location.reload();
              }}
            />
          } />
        <ModalDisplay
          onCancel={() => {
            setRegister(false)
            navigate("/")
          }}
          open={register}
          content={
            <RegisterPage
              card
              linkLogin={'/?login=1'}
              authApi={customAuthApi}
              isSsuccess={() => {
                navigate("/")
                window.location.reload();
              }}
            />
          } />
        <ModalDisplay
          onCancel={() => navigate("/ex")}
          open={init}
          content={<InitPageForm
            custApi={customCustomerApi}
            isSuccess={() => {
              setInit(false);
              window.location.reload();
            }} />} />
        <Modal
          closeIcon={false}
          maskClosable={false}
          footer={false}
          open={cookie}
          centered
        >
          <Cookies
            isSuccess={() => { setCookie(false) }}
          />
        </Modal>
      </Flex>
    </React.Fragment >
  );
};

export default Header;