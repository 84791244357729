import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Descriptions, Skeleton, Row, Col, Divider, Card, Space, Button } from 'antd';
import { EditButton, EmptyDisplay, ErrorDisplay, ModalDisplay, ParticipantForm, ParticipationList } from "../..";
//Icons
import WcOutlinedIcon from '@mui/icons-material/WcOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import LocalHospitalOutlined from "@mui/icons-material/LocalHospitalOutlined";
import PersonOutlineOutlined from "@mui/icons-material/PersonOutlineOutlined";
import { calculateAge, toAgeDisplay } from "../../../utils/timestampManymulation";

export const ParticipantShowCustom = ({ editable, ghost, card, uuid, custApi, refresh, mainApi }) => {
    const navigate = useNavigate();
    const [data, setData] = useState({})
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false);

    useEffect(() => {
        getData();
    }, [open]);

    const getData = async () => {
        if (!uuid) { setData([]) }
        else {
            try {
                setLoading(true);
                const response = await custApi.get(`/participant/one/${uuid}`);
                setData(response.data.participant)
            } catch (error) {
                setError("Fehler beim Laden der Daten. " + error?.response?.data?.message);
            } finally {
                setLoading(false);
            }
        }
    };

    const items = [
        {
            key: '1',
            label: (<> <CakeOutlinedIcon fontSize="small" /> Alter </>),
            children: (<>{toAgeDisplay(calculateAge(data?.person?.birthdate)) || <Skeleton paragraph={{ rows: 0 }} />} </>),
            span: 24,
        },
        {
            key: '2',
            label: (<> <WcOutlinedIcon fontSize="small" /> Geschlecht </>),
            children: (<>
                {data?.person?.sex === "male" ? "Männlich" : data?.person?.sex === "female" ? "Weiblich" : data?.person?.sex === "other" ? "Divers" : <Skeleton paragraph={{ rows: 0 }} />}
            </>),
            span: 24,
        },
        {
            key: '3',
            label: (<> <LocalHospitalOutlined fontSize="small" /> Gesundheitliche Informationen </>),
            children: (<>{data?.health || 'Keine Informationen Angegeben'} </>),
            span: 24,
        },

    ];

    return (
        <ErrorDisplay error={error} loading={loading}>
            <Card title={data?.person?.name?.firstname + " " + data?.person?.name?.lastname}
                extra={
                    <Space>
                        <Button type="primary" onClick={() => navigate(`/course?par=${data.uuid}`)}> Kurs Finden </Button>
                    </Space>
                }>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} >
                        {ghost ? "" : <Divider orientation='left' orientationMargin="0">
                            <Space>
                                <><PersonOutlineOutlined />Daten</>
                                {(!editable || !data) ? "" : <EditButton text="Bearbeiten" onClick={() => setOpen(true)} />}
                            </Space>
                        </Divider>}
                        {(!data || data?.length === 0) ?
                            <EmptyDisplay iconHeight={0} card text={"Keine Daten Vorhanden"} onClick={() => setOpen(editable ? true : false)} /> :
                            <Descriptions size="small" bordered layout="vertical" items={items} />
                        }
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={12} >
                        <ParticipationList filterText participantIds={data?.uuid} custApi={custApi} mainApi={mainApi} />
                    </Col>
                </Row>
                <ModalDisplay
                    onCancel={() => setOpen(false)}
                    open={open}
                    content={<ParticipantForm
                        custApi={custApi}
                        uuid={uuid}
                        isSuccess={() => {
                            setOpen(false)
                            refresh(true)
                        }}
                    />} />
            </Card>
        </ErrorDisplay >
    );
};