import axios from "axios"
const APP_DEV = "false"
const CUSTOM_CUSTOM_API_KEY = "aFui2FOQKPJTU1yA6doMaiTJw5JGrUQ4nh4UxyUiq729QjIRgFofBzCAwg9qP5kI"
const CUSTOM_SYSTEM_API_KEY = "Uoor4zDPrT5oaNmMToDBy6I6QrVf9aGwzPmleiZajTh3fNkkgp80ydYBOxB0zAlp"

export const SystemApi = axios.create({
    baseURL: APP_DEV == "true" ? 'http://localhost:5501/custom/api/v1' : 'https://api.system.coursesync.de/custom/api/v1',
    headers: {
        'apiKey': CUSTOM_SYSTEM_API_KEY,
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

export const SystemFileApi = axios.create({
    baseURL: APP_DEV == "true" ? 'http://localhost:5501/custom/api/v1' : 'https://api.system.coursesync.de/custom/api/v1',
    headers: {
        'apiKey': CUSTOM_SYSTEM_API_KEY,
        'Content-Type': 'multipart/form-data'
    },
    withCredentials: true,
});


export const customAuthApi = axios.create({
    baseURL: APP_DEV == "true" ? 'http://localhost:5500/auth/api/v1' : 'https://api.customer.coursesync.de/auth/api/v1',
    headers: {
        'apiKey': CUSTOM_CUSTOM_API_KEY,
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

export const customCustomerApi = axios.create({
    baseURL: APP_DEV == "true" ? 'http://localhost:5500/custom/api/v1' : 'https://api.customer.coursesync.de/custom/api/v1',
    headers: {
        'apiKey': CUSTOM_CUSTOM_API_KEY,
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});

export const frondend = { customBase: APP_DEV == "true" ? 'http://localhost:4000' : 'https://app.coursesync.de' }

export default { customAuthApi, customCustomerApi, frondend, SystemApi, SystemFileApi }



