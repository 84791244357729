import React from "react";
import { useNavigate } from "react-router-dom";
import { List, Tabs, Card, Button } from 'antd';

import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';

export default function Cookies({ isSuccess }) {
    const navigate = useNavigate()

    const handleAccept = () => {
        localStorage.setItem("cookie", true);
        isSuccess();
    };
    const handleImportantAccept = () => {
        localStorage.setItem("cookie", true);
        isSuccess();
    };

    const data = [
        {
            key: '1',
            label: ("Übersicht"),
            children: (<>
                <h6>
                    Diese Webseite verwendet Cookies
                </h6>
                <p>
                    Wir verwenden Cookies, um Inhalte und Anzeigen zu personalisieren, Funktionen für soziale Medien anbieten zu können und die Zugriffe auf unsere Website zu analysieren. Außerdem geben wir Informationen zu Ihrer Verwendung unserer Website an unsere Partner für soziale Medien, Werbung und Analysen weiter. Unsere Partner führen diese Informationen möglicherweise mit weiteren Daten zusammen, die Sie ihnen bereitgestellt haben oder die sie im Rahmen Ihrer Nutzung der Dienste gesammelt haben.
                </p>
            </>)
        },
        {
            key: '2',
            label: ("Was sind Cookies"),
            children: (<>
                <p>
                    Cookies sind kleine Textdateien, die von Webseiten verwendet werden, um die Benutzererfahrung effizienter zu gestalten.
                    Laut Gesetz können wir Cookies auf Ihrem Gerät speichern, wenn diese für den Betrieb dieser Seite unbedingt notwendig sind. Für alle anderen Cookie-Typen benötigen wir Ihre Erlaubnis. Dies bedeutet, dass als notwendig kategorisierte Cookies auf der Grundlage von Art. 6 Abs. 1 lit. f DSGVO verarbeitet werden. Alle anderen nicht notwendigen Cookies, d. h. diejenigen aus den Kategorien Präferenzen und Marketing, werden auf der Grundlage von Art. 6 Abs. 1 lit. a  DSGVO verarbeitet.
                    Diese Seite verwendet unterschiedliche Cookie-Typen. Einige Cookies werden von Drittparteien platziert, die auf unseren Seiten erscheinen.
                    Sie können Ihre Einwilligung jederzeit von der Cookie-Erklärung auf unserer Website ändern oder widerrufen.
                    Erfahren Sie in unserer Datenschutzrichtlinie mehr darüber, wer wir sind, wie Sie uns kontaktieren können und wie wir personenbezogene Daten verarbeiten.
                </p>
            </>)
        },
    ];

    return (<>
        <Card
            bordered={false}
            title={"Cookie Einwilligung"}
            actions={[
                <Button onClick={() => handleAccept()} type="primary">Zustimmen</Button>,
                <Button onClick={() => handleImportantAccept()} type="text" >Notwendigen Zustimmen</Button >,
            ]
            }>
            <Tabs style={{maxHeight: "50%", overflowY: "auto"}} type="line" size="small" defaultActiveKey="1" items={data} />
        </Card >
    </>);
};