import React, { useState, useEffect } from "react";
import { Space, Card, Row, Col, Divider } from 'antd';
import { CreateButton, ErrorDisplay, SearchFilter, ListDisplay, ModalDisplay, UnitItem, UnitForm, getFilter, ContactItem, ContactForm, CourseForm, CourseItem, CourseDateItem, CourseDateForm } from "../..";
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';

export const SimpleCourseDateList = ({ ghost, bordered, dates }) => {

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                {ghost ? "" : <Divider orientation='left' orientationMargin="0">
                    <Space>
                        <><FormatListBulletedOutlinedIcon />Kurstermine</>
                    </Space>
                </Divider>}
                <ListDisplay
                    bordered={!bordered}
                    emptyText="Keine Termine gefunden."
                    data={dates?.sort((a, b) => new Date(a.time.publicStart) - new Date(b.time.publicStart))}
                    listItem={(item, index) => <CourseDateItem item={item} nr={index + 1 + ". Termin"} />}
                    maxHeight={500}
                />
            </Col >
        </Row >
    );
};