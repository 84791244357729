// Sidebar.js
import React from "react";
import { Card, Col, Button } from "antd";
import { useNavigate } from "react-router-dom";
import logo from '../../img/logo.png';
//MyCourseIcons
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { EmptyDisplay } from "../../package";
import PoolOutlinedIcon from '@mui/icons-material/PoolOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';

export default function DashboardContent() {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Col xs={24} sm={24} md={12} lg={8} xl={8} >
        <Card
          hoverable
          cover={<img alt="logo" src={logo} />}
        >
          <Card.Meta
            title="Was ist die My-Swimy-App?"
            description="Mit der My-Swimy-App habe haben Sie alle Informationen und Möglichkeiten für Ihre Kursanmeldungen und Teilnehmer gesammelt an einem Ort."
          />
        </Card>
      </Col>
      <Col xs={0} sm={0} md={12} lg={16} xl={16} >
      </Col>
      <Col xs={12} sm={12} md={8} lg={6} xl={6}>
        <EmptyDisplay onClick={() => { navigate("/my-course") }} card icon={<PoolOutlinedIcon />} text={"Meine Kurse "} />
      </Col>
      <Col xs={12} sm={12} md={8} lg={6} xl={6}>
        <EmptyDisplay onClick={() => { navigate("/participants") }} card icon={<GroupOutlinedIcon />} text={"Meine Teilnehmer"} />
      </Col>
      <Col xs={12} sm={12} md={8} lg={6} xl={6}>
        <EmptyDisplay onClick={() => { navigate("/profile") }} card icon={<AccountBoxOutlinedIcon />} text={"Profil"} />
      </Col>
      <Col xs={12} sm={12} md={8} lg={6} xl={6}>
        <EmptyDisplay onClick={() => { navigate("/course") }} card icon={<HowToRegOutlinedIcon />} text={"Kurs finden"} />
      </Col>
    </React.Fragment >
  );
};