import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Space, message, Row, Col, Divider, Tooltip, Select } from 'antd';
import TransgenderOutlinedIcon from '@mui/icons-material/TransgenderOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';

const { Option } = Select;
export const ContactForm = ({ title, isSuccess, uuid, locationId, mainApi }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [user, setUser] = useState([])

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user")) || {};
        if (storedUser) { setUser(storedUser) }
        if (uuid) {
            getData();
        }
    }, []);

    const getData = async () => {
        try {
            setLoading(true);
            const response = await mainApi.get(`/contact/one/${uuid}`);
            const unit = {
                email: response?.data?.contact?.email,
                role: response?.data?.contact?.role,
                phoneNumber: response?.data?.contact?.phone?.phoneNumber,
                sex: response?.data?.contact?.person?.sex,
                firstname: response?.data?.contact?.person?.name?.firstname,
                lastname: response?.data?.contact?.person?.name?.lastname,
            }
            form.setFieldsValue(unit);
        } catch (error) {
            message.error("Fehler beim Laden der Daten. " + error?.response?.data?.message && error?.message);
        } finally {
            setLoading(false);
        }
    };

    const onFinish = async (values) => {
        let response;
        values.locationId = locationId;
        try {
            setLoading(true);
            if (uuid) {
                response = await mainApi.patch(`/contact/update/${uuid}`, values);
            } else {
                response = await mainApi.post(`/contact`, values);
            }
            isSuccess(response.data.contact);
        } catch (error) {
            message.error("Fehlgeschlagen: " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Form
                name="ContactForm"
                requiredMark={false}
                form={form}
                onFinish={onFinish}
                layout="vertical" a
                validateTrigger="onChange"
                style={{ width: "100%" }}
            >
                <Space direction="vertical">
                    <h3>{uuid ? 'Kontakt ändern' : 'Kontakt anlegen'}</h3>
                </Space>
                <Spin spinning={false} size="large" loading={loading}>
                    <Row gutter={[16, 0]}>
                        {/**/}
                        <Col span={24}>
                            <Divider orientation='left' orientationMargin="0"><PersonOutlineOutlinedIcon /> Person</Divider>
                        </Col>

                        <Col xs={12} sm={12} md={6} lg={6} xl={6} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Geschlecht">
                                <Form.Item
                                    name="sex"
                                    rules={[{ required: true, message: 'Bitte Anrede auswählen!' }]}
                                >
                                    <Select prefix={<TransgenderOutlinedIcon fontSize='small' />} placeholder="Anrede">
                                        <Option value="male">Herr</Option>
                                        <Option value="female">Frau</Option>
                                        <Option value="other">Person</Option>
                                    </Select>
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={12} sm={12} md={18} lg={18} xl={18} ></Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Vorname">
                                <Form.Item
                                    hasFeedback
                                    name="firstname"
                                    rules={[{
                                        required: true,
                                        min: 2,
                                        message: 'Bitte Ihren Vornamen eingeben!',
                                    },
                                    {
                                        pattern: /^[a-zA-ZäöüßÄÖÜ ]+$/i,
                                        message: 'Der Vorname darf nur Buchstaben enthalten!',
                                    },
                                    {
                                        max: 50,
                                        message: 'Der Vorname ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<PersonOutlineOutlinedIcon fontSize='small' />} placeholder="Vorname" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Nachname">
                                <Form.Item
                                    hasFeedback
                                    name="lastname"
                                    rules={[{
                                        required: true,
                                        min: 2,
                                        message: 'Bitte Ihren Nachnamen eingeben!',
                                    },
                                    {
                                        pattern: /^[a-zA-ZäöüßÄÖÜ ]+$/i,
                                        message: 'Der Nachname darf nur Buchstaben enthalten!',
                                    },
                                    {
                                        max: 50,
                                        message: 'Der Nachname ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<PersonOutlineOutlinedIcon fontSize='small' />} placeholder="Nachname" />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col span={24}>
                            <Divider orientation='left' orientationMargin="0"><AlternateEmailOutlinedIcon /> Kontaktdaten</Divider>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="E-Mail-Adresse für den Kontakt">
                                <Form.Item
                                    hasFeedback
                                    name="email"
                                    rules={[{
                                        required: true,
                                        type: 'email',
                                        message: 'Bitte eine gültige E-Mail-Adresse eingeben',
                                    },
                                    {
                                        max: 255,
                                        message: 'Die E-Mail-Adresse ist zu lang!',
                                    },
                                    ]}>
                                    <Input prefix={<AlternateEmailOutlinedIcon fontSize='small' />} placeholder="E-Mail-Adresse" />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Telefonnummer">
                                <Form.Item
                                    hasFeedback
                                    name="phoneNumber"
                                    rules={[{
                                        required: true,
                                        message: 'Bitte Ihre Telefonnummer eingeben!',
                                    },
                                    {
                                        pattern: /^(0|\+)[0-9]+$/,
                                        message: 'Die Telefonnummer ist ungültig! Bitte im Format: +123456789 angeben',
                                    },
                                    ]}>
                                    <Input prefix={<LocalPhoneOutlinedIcon fontSize='small' />} placeholder="Telefon" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Die Position und Aufgaben der Person">
                                <Form.Item
                                    hasFeedback
                                    name="role"
                                    rules={[{
                                        required: true,
                                        min: 2,
                                        message: 'Bitte eine Beschreibung eingeben!',
                                    },
                                    {
                                        max: 50,
                                        message: 'Die Beschreibung ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<AssignmentIndOutlinedIcon fontSize='small' />} placeholder="Beschreibung" />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col xs={24} sm={12} md={16} lg={17} xl={18} ></Col>
                        <Col xs={16} sm={12} md={8} lg={7} xl={6} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" loading={loading} block>
                                    {title ? title : uuid ? "Speichern" : 'Erstellen'}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </div >
    );
};