import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Checkbox, Form, Input, Alert, Button, Row, Col, Card, Space, Spin, message, Divider } from 'antd';
import { customAuthApi } from '../../../urlConfig.js';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

export const CustomerLogInForm = ({ linkRegister, linkPwForgotten, authApi, ghost, isSuccess, card }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleLogin = async (values) => {
        try {
            setLoading(true);

            const response = await authApi.post(`/login`, values);
            localStorage.setItem("user", JSON.stringify(response.data.user));
            localStorage.setItem("customToken", true);

            if (isSuccess) {
                isSuccess(response.data.user.customer)
            } else {
                navigate('/');
                window.location.reload();
            }

        } catch (error) {
            message.error(`Anmeldung Fehlgeschlagen: ${error?.response?.data?.message}`);
            message.error(`Anmeldung Fehlgeschlagen: ${error}`);
        } finally {
            setLoading(false);
        }
    };

    const formDisplay = () => {
        return (
            <Spin spinning={loading} size="large">
                <Form.Item
                    hasFeedback
                    label="Benutzername:"
                    name="email"
                    rules={[
                        {
                            required: true,
                            message: 'Bitte Benutzername eingeben',
                        },
                        {
                            type: 'email',
                            message: "Bitte eine gültige E-Mail-Adesse eingeben"
                        },
                    ]} >
                    <Input prefix={<PersonOutlineOutlinedIcon fontSize='small' />} placeholder="Benutzername" />
                </Form.Item>
                <Form.Item
                    hasFeedback
                    name="password"
                    label="Passwort:"
                    rules={[
                        {
                            required: true,
                            message: 'Bitte das Passwort eingeben!',
                        },
                    ]}
                >
                    <Input.Password prefix={<LockOutlinedIcon fontSize='small' />} placeholder="Passwort"
                    />
                </Form.Item>
                <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox >Angemeldet bleiben</Checkbox>
                    </Form.Item>
                    {linkPwForgotten ?
                        <a className="login-form-forgot" href="">
                            Passwort vergessen?
                        </a>
                        : ''}
                </Form.Item>

                <Form.Item>
                    <Row align="middle" justify="space-between">
                        <Col>
                            <Button type="primary" htmlType="submit" className="text-start btn-secondary" >
                                Anmelden
                            </Button>
                        </Col>
                        {linkRegister ?
                            <Col className='text-end'>
                                <a href={linkRegister}> Kein Account? <br /> Jetzt registrieren</a>
                            </Col>
                            : ''}
                    </Row>
                </Form.Item>
            </Spin>
        )
    }


    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Form
                name="normal_login"
                className="login-form"
                requiredMark={false}
                initialValues={{ remember: true, }}
                onFinish={handleLogin}
                layout="vertical"
                validateTrigger="onChange"
                style={{ width: "100%" }}

            >
                {!card ?
                    <>
                        {ghost ? "" :
                            <Space direction="vertical">
                                <h3>Login My-Swim-App</h3>
                                <h6>Swimgood-Schwimmschule - CourseSyncCustomer</h6>
                                <Divider style={{ margin: 0 }}></Divider>
                            </Space>
                        }
                        {formDisplay()}
                    </> : <Card
                        title={ghost ? "" :
                            <Space direction="vertical">
                                <h3>Anmeldung Kundenportal</h3>
                                <h6>Swimgood-Schwimmschule - CoursSync</h6>
                            </Space>
                        }>

                        {formDisplay()}
                    </Card>}
            </Form>
        </div >
    );
};