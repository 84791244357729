import React from "react";

export default function Impressum({ }) {

    return (<>
        <h3>Impressum</h3>
        <h6>Angaben gem. § 5 TMG:</h6>
        <p>
            Adrian Trumpa<br />
            Kaiserstraße 3<br />
            76131 Karlsruhe
        </p>
        <h6>Kontakt:</h6>
        <p>
            Telefon: +49 (0) 178 3249700 <br />
            E-Mail: kontakt(at)swimgood-schwimmschule.de
        </p>

        <h6>Umsatzsteuer-Identifikationsnummer:</h6>
        <p>
            35171/00996
        </p>

        <h6>EU-Streitschlichtung:</h6>
        <p>
            Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung bereit:
            https://ec.europa.eu/consumers/odr/.
            Kontaktdaten finden Sie oben im Impressum.
        </p>
    </ >);
};