export function calculateAge(birthdate) {
    const birthDate = new Date(birthdate);
    const currentDate = new Date();

    let ageYears = currentDate.getFullYear() - birthDate.getFullYear();
    let ageMonths = currentDate.getMonth() - birthDate.getMonth();
    let ageDays = currentDate.getDate() - birthDate.getDate();

    if (ageDays < 0) {
        ageMonths--;
        const lastMonthDays = getDaysInMonth(currentDate.getMonth() - 1, currentDate.getFullYear());
        ageDays = lastMonthDays + ageDays;
    }

    if (ageMonths < 0) {
        ageYears--;
        ageMonths = 12 + ageMonths;
    }

    return {
        years: ageYears,
        months: ageMonths,
        days: ageDays
    };
}

function getDaysInMonth(month, year) {
    return new Date(year, month + 1, 0).getDate();
}

export function toAgeDisplay(age) {
    let y = age.years + " Jahre";
    let m = age.months + " Monate";
    if (age.years === 1) { y = age.years + " Jahr" }
    if (age.months === 1) { m = age.months + " Monat" }
    return y + " und " + m;
}