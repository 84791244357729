import React from "react";
import { Descriptions, Skeleton, Row, Col, Divider, Card, Space } from 'antd';
import { CustomerForm, EditButton, ErrorDisplay, ModalDisplay } from "../../../package";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AlternateEmailOutlined from "@mui/icons-material/AlternateEmailOutlined";
import LocalPhoneOutlined from "@mui/icons-material/LocalPhoneOutlined";
import HomeOutlined from "@mui/icons-material/HomeOutlined";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useState } from "react";
import { useEffect } from "react";

export const CustomerFullData = ({ uuid, ghost, card, editable, custApi }) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");

    useEffect(() => {
        setLoading(true)
        getData();
    }, [open]);

    const getData = async () => {
        if (!uuid) { setData([]) }
        else {
            try {
                setLoading(true);
                const response = await custApi.get(`/customer/one/${uuid}`);
                setData(response.data.customer)
            } catch (error) {
                setError("Fehler beim Laden der Daten. " + error?.response?.data?.message + ": " + error?.response?.data?.errorCode);
            } finally {
                setLoading(false);
            }
        }
    };

    const items = [
        {
            key: '1',
            label: (<> <PersonOutlineOutlinedIcon fontSize="small" /> Name </>),
            children: (
                <>
                    {data?.person?.sex === "male" ? "Herr" : data?.person?.sex === "female" ? "Frau" : data?.person?.sex === "other" ? "Person" : <Skeleton paragraph={{ rows: 0 }} />}
                    {' '}
                    {data?.person?.name?.firstname || ""}
                    {' '}
                    {data?.person?.name?.lastname || ""}
                </>
            ),
            span: 24,
        },
        {
            key: '2',
            label: (<> <EmailOutlinedIcon fontSize="small" /> E-Mail </>),
            children: (<>{data?.email || <Skeleton paragraph={{ rows: 0 }} />} </>),
            span: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 },
        },
        {
            key: '3',
            label: (<> <LocalPhoneOutlined fontSize="small" /> Telefon </>),
            children: (<>{data?.phone?.phoneNumber || <Skeleton paragraph={{ rows: 0 }} />} </>),
            span: { xs: 24, sm: 24, md: 24, lg: 24, xl: 24, xxl: 24 },
        },
        {
            key: '4',
            label: (<> <HomeOutlined fontSize="small" /> Adresse </>),
            span: 2,
            children: (<>
                {data?.adress?.street || <Skeleton paragraph={{ rows: 0 }} />}
                <br />
                {data?.adress?.postalCode || <Skeleton paragraph={{ rows: 0 }} />}
                {' '}
                {data?.adress?.city || <Skeleton paragraph={{ rows: 0 }} />}
                <br />
                {data?.adress?.country || <Skeleton paragraph={{ rows: 0 }} />}
            </>),
            span: { xs: 4, sm: 4, md: 4, lg: 4, xl: 4, xxl: 4 },
        },
    ]
    const result = (
        <ErrorDisplay error={error} loading={loading}>
            < Row gutter={[16, 16]} >
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                    {ghost ? "" : <Divider orientation='left' orientationMargin="0"> <Space>
                        <><AlternateEmailOutlined /> Daten:</>
                        {!editable ? "" : <EditButton text="Bearbeiten" onClick={() => setOpen(true)} />}
                    </Space>
                    </Divider>}
                    <Descriptions size="small" bordered layout="vertical" items={items} />
                </Col>
            </ Row>
        </ErrorDisplay>
    )

    return (<>
        {card ?
            <Card size="small" >
                {result}
            </Card>
            :
            { result }
        }
        <ModalDisplay
            onCancel={() => setOpen(false)}
            open={open}
            content={<CustomerForm
                custApi={custApi}
                uuid={data?.uuid}
                isSuccess={() => {
                    setOpen(false)
                }}
            />} />
    </>
    );
};