// Sidebar.js
import React from "react";
import { Card, Col, Button } from "antd";
import { useNavigate } from "react-router-dom";
import logo from '../../img/logo.png';
//MyCourseIcons
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';

export default function PublicDashboardContent() {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      {new URLSearchParams(window.location.search).get("logout") == 1 ?
        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
          <Card>
            <h3>Sie haben sich erfolgreich abgemeldet, bis bald!</h3>
          </Card>
        </Col> : ""}
      <Col xs={24} sm={24} md={12} lg={8} xl={8} >
        <Card
          style={{ height: "100%" }}
          hoverable
          cover={<img alt="logo" src={logo} />}
        >
          <Card.Meta
            title="Was ist die My-Swimy-App?"
            description="Mit der My-Swimy-App haben Sie alle Informationen und Möglichkeiten für Ihre Kursanmeldungen und Teilnehmer gesammelt an einem Ort."
          />
        </Card>
      </Col>
      <Col xs={24} sm={24} md={12} lg={16} xl={16} >
        <Card
          style={{ height: "100%" }}
          title="Sie haben noch keinen Account?"
          actions={[
            <Button icon={<GroupOutlinedIcon />} type="text" onClick={() => navigate("/register")}>Registrieren</Button>,
            <Button icon={<GroupOutlinedIcon />} type="text" onClick={() => navigate("/course")}>Kurs finden</Button>,
          ]}>
          <p>Erstellen Sie sich jetzt gleich ihren eigenen Account und profitieren Sie von vielen Vorteilen.</p>
          <b>Vorteile:</b>
          <p>
            Schnelle und unkomplizierte Kursanmeldung <br />
            Kusanmeldung ohne wiederholte Dateingabe <br />
            Kursempfehlung passend zum Leistungsstand der teilnehmenden Person <br />
            Einsehen von Anwesenheiten beim Kurs <br />
            Teilnehmerausweise <br />
            und vieles mehr
          </p>
        </Card>
      </Col>
      {/* <Col xs={12} sm={12} md={8} lg={6} xl={6}>
       <EmptyDisplay onClick={() => { navigate("/course") }} card icon={<GroupOutlinedIcon />} text={"Kurs Finden"} />
     </Col>
     <Col xs={12} sm={12} md={8} lg={6} xl={6}>
       <EmptyDisplay onClick={() => { navigate("/location") }} card icon={<GroupOutlinedIcon />} text={"Standorte ansehen"} />
     </Col>
     <Col xs={12} sm={12} md={8} lg={6} xl={6}>
       <EmptyDisplay onClick={() => { navigate("/courseType") }} card icon={<GroupOutlinedIcon />} text={"Kursarten ansehen"} />
     </Col>
     <Col xs={12} sm={12} md={8} lg={6} xl={6}>
       <EmptyDisplay onClick={() => { navigate("/courseType") }} card icon={<GroupOutlinedIcon />} text={"Kursarten ansehen"} />
     </Col> */}
    </React.Fragment >
  );
};