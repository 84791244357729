import React from "react";
import { Col } from "antd";
import Layout from "../../../layout/Layout";
import { BookingList } from "../../../package";
import { SystemApi, customAuthApi, customCustomerApi } from "../../../urlConfig";

export default function CoursePage({ layout }) {
  const headerData = { title: 'Kursanmeldung' }

  return (
    <React.Fragment>
      <Layout layout={layout} headerData={headerData}>
        <BookingList card mainApi={SystemApi} custApi={customCustomerApi} authApi={customAuthApi} />
      </Layout>
    </React.Fragment>
  );
};