import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Space, message, Row, Col, Divider, Tooltip, Select, DatePicker, TimePicker, Tag } from 'antd';
import TransgenderOutlinedIcon from '@mui/icons-material/TransgenderOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import AddAlarmOutlinedIcon from '@mui/icons-material/AddAlarmOutlined';
import { CoachSelect } from '../../components/form/parts/CoachSelect';
import { CourseTypeSelect } from '../../components/form/parts/CourseTypeSelect';
import { PriceInput } from '../../components/form/parts/PriceInput';
import { SelectWithData } from '../../components/form/parts/SelectWithData';
import moment from 'moment';
import { UnitSelect } from '../../components/form/parts/UnitSelect';
import { LocationSelect } from '../../components/form/parts/LocationSelect';
import { json } from 'react-router-dom';
const { RangePicker } = DatePicker;


const { Option } = Select;
export const CourseDateForm = ({ title, isSuccess, uuid, locationId, mainApi }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [date, setDate] = useState([])

    useEffect(() => {
        if (uuid) {
            getData();
        }
    }, []);

    const getData = async () => {
        try {
            setLoading(true);
            const response = await mainApi.get(`/courseDate/one/${uuid}`);
            const data = {
                coachId: response?.data?.course?.coachId,
                courseTypeId: response?.data?.course?.courseTypeId,
                price: response?.data?.course?.price,
                prerelase: [
                    new Date(response?.data?.course?.prerelase?.start),
                    new Date(response?.data?.course?.prerelase?.end),
                ],
                relase: [
                    new Date(response?.data?.course?.relase?.start),
                    new Date(response?.data?.course?.relase?.end),
                ],
                status: response?.data?.course?.courseStatus?.statusValue,
            }
            form.setFieldsValue(data);
        } catch (error) {
            message.error("Fehler beim Laden der Daten. " + error?.response?.data?.message && error?.message);
        } finally {
            setLoading(false);
        }
    };



    const onFinish = async (values) => {
        values.courseTypeId = values.courseType;
        values.courseDates = date;
        let response;
        try {
            setLoading(true);
            if (uuid) {
                response = await mainApi.patch(`/course/update/${uuid}`, values);
            } else {
                response = await mainApi.post(`/course`, values);
            }
            isSuccess(response.data.course);
        } catch (error) {
            message.error("Fehlgeschlagen: " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Form
                name="CourseForm"
                requiredMark={false}
                form={form}
                onFinish={onFinish}
                layout="vertical"
                validateTrigger="onChange"
                style={{ width: "100%" }}
            >
                <Space direction="vertical">
                    <h3>{uuid ? 'Kurs ändern' : 'Kurs anlegen'}</h3>
                </Space>
                <Spin spinning={false} size="large" loading={loading}>
                    <Row gutter={[16, 0]}>
                        {/**/}
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            <LocationSelect disabled={!form.getFieldValue("date") ? false : true} mainApi={mainApi} />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                        </Col>


                        <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                            <Form.Item
                                hasFeedback
                                name={'date'}
                                rules={[{ required: true, message: `Bitte einen Termin wählen` }]}
                            >
                                <DatePicker
                                    showTime={{ format: "HH:mm" }} style={{ width: "100%" }}
                                    changeOnBlur
                                    format="YYYY-MM-DD HH:mm"
                                    placeholder={`Kurstermin`} />
                            </Form.Item>
                        </Col>
                        <Col xs={9} sm={10} md={10} lg={10} xl={10}>
                            <UnitSelect
                                name='unitId'
                                mainApi={mainApi}
                                locationId={form.getFieldValue("locationId")} />
                        </Col>
                        <Col xs={24} sm={12} md={16} lg={17} xl={18} ></Col>
                        <Col xs={16} sm={12} md={8} lg={7} xl={6} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" loading={loading} block>
                                    {title ? title : uuid ? "Speichern" : 'Erstellen'}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </div >
    );
};