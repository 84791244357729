import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Space, message, Row, Col, Divider, Tooltip, Checkbox, Select, DatePicker } from 'antd';
import moment from 'moment';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TransgenderOutlinedIcon from '@mui/icons-material/TransgenderOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';

const { Option } = Select;
export const EmployeePersonForm = ({ title, isSuccess, uuid, empApi }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();

    useEffect(() => {
        if (uuid) { getData() }
    }, []);

    const getData = async () => {
        try {
            setLoading(true);
            const response = await empApi.get(`/employee/one/${uuid}`);
            const data = {
                sex: response?.data?.employee?.person?.sex,
                birthdate: response?.data?.employee?.person?.birthdate ? moment(response?.data?.employee?.person?.birthdate) : null,
                birthCity: response?.data?.employee?.person?.birthCity,
                birthCountry: response?.data?.employee?.person?.birthCountry,

                firstname: response?.data?.employee?.person?.name?.firstname,
                lastname: response?.data?.employee?.person?.name?.lastname,
                prefix: response?.data?.employee?.person?.name?.prefix,
                addition: response?.data?.employee?.person?.name?.addition,
                title: response?.data?.employee?.person?.name?.title,

                birthLastname: response?.data?.employee?.person?.birthName?.lastname,
                birthPrefix: response?.data?.employee?.person?.birthName?.prefix,
                birthAddition: response?.data?.employee?.person?.birthName?.addition,
            }
            form.setFieldsValue(data);
        } catch (error) {
            message.error("Fehler beim Laden der Daten. " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    const onFinish = async (values) => {
        const data = {
            person: {
                sex: values.sex,
                birthdate: values.birthdate,
                birthCity: values.birthCity,
                birthCountry: values.birthCountry,
                name: {
                    firstname: values.firstname,
                    lastname: values.lastname,
                    prefix: values.prefix ? values.prefix : null,
                    title: values.title ? values.title : null,
                    addition: values.addition ? values.addition : null,
                },
                birthName: {
                    lastname: values.birthLastname,
                    addition: values.birthAddition ? values.birthAddition : null,
                    prefix: values.birthPrefix ? values.birthPrefix : null,
                },
            },
        }
        try {
            setLoading(true);
            await empApi.patch(`/employee/update/${uuid}`, data);
            isSuccess();
        } catch (error) {
            message.error("Fehlgeschlagen: " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Form
                name="employeeForm"
                requiredMark={false}
                form={form}
                onFinish={onFinish}
                layout="vertical"
                validateTrigger="onChange"
                style={{ width: "100%" }}
            >
                <Space direction="vertical">
                    <h3>{"Profil"}</h3>
                </Space>
                <Spin spinning={false} size="large" loading={loading}>
                    <Row gutter={[16, 0]}>

                        {/**/}
                        <Col span={24}>
                            <Divider orientation='left' orientationMargin="0"><PersonOutlineOutlinedIcon /> Person</Divider>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Geschlecht">
                                <Form.Item
                                    name="sex"
                                    rules={[{ required: true, message: 'Bitte Anrede auswählen!' }]}
                                >
                                    <Select prefix={<TransgenderOutlinedIcon fontSize='small' />} placeholder="Anrede">
                                        <Option value="male">Herr</Option>
                                        <Option value="female">Frau</Option>
                                        <Option value="other">Person</Option>
                                    </Select>
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Geburtsdatum für altersgerechte Kurse">
                                <Form.Item
                                    hasFeedback

                                    name="birthdate"
                                    rules={[{
                                        required: true,
                                        type: 'date',
                                        message: 'Bitte eine gültiges Geburtsdatum eingeben',
                                    },]}>
                                    <DatePicker disabledDate={current => current && current > moment().endOf('day')} format={'DD.MM.YYYY'} style={{ width: '100%' }} placeholder="Geburtsdatum" />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Vorname">
                                <Form.Item
                                    hasFeedback
                                    name="firstname"
                                    rules={[{
                                        required: true,
                                        min: 2,
                                        message: 'Bitte Ihren Vornamen eingeben!',
                                    },
                                    {
                                        pattern: /^[a-zA-ZäöüßÄÖÜ ]+$/i,
                                        message: 'Der Vorname darf nur Buchstaben enthalten!',
                                    },
                                    {
                                        max: 50,
                                        message: 'Der Vorname ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<PersonOutlineOutlinedIcon fontSize='small' />} placeholder="Vorname" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Nachname">
                                <Form.Item
                                    hasFeedback
                                    name="lastname"
                                    rules={[{
                                        required: true,
                                        min: 2,
                                        message: 'Bitte Ihren Nachnamen eingeben!',
                                    },
                                    {
                                        pattern: /^[a-zA-ZäöüßÄÖÜ ]+$/i,
                                        message: 'Der Nachname darf nur Buchstaben enthalten!',
                                    },
                                    {
                                        max: 50,
                                        message: 'Der Nachname ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<PersonOutlineOutlinedIcon fontSize='small' />} placeholder="Nachname" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Titel">
                                <Form.Item
                                    name="title"
                                    rules={[{
                                        pattern: /^[a-zA-ZäöüßÄÖÜ.\- ]+$/i,
                                        message: 'Der Titel darf nur Buchstaben enthalten!',
                                    },
                                    {
                                        max: 25,
                                        message: 'Der Titel ist zu Lang!',
                                    },
                                    ]}>
                                    <Input placeholder="Titel" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Vorsatz">
                                <Form.Item
                                    name="prefix"
                                    rules={[{
                                        pattern: /^[a-zA-ZäöüßÄÖÜ.\- ]+$/i,
                                        message: 'Der Vorsatz darf nur Buchstaben enthalten!',
                                    },
                                    {
                                        max: 25,
                                        message: 'Der Vorsatz ist zu Lang!',
                                    },
                                    ]}>
                                    <Input placeholder="Vorsatz" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Zusatz">
                                <Form.Item
                                    name="addition"
                                    rules={[{
                                        pattern: /^[a-zA-ZäöüßÄÖÜ. ]+$/i,
                                        message: 'Der Zusatz darf nur Buchstaben enthalten!',
                                    },
                                    {
                                        max: 25,
                                        message: 'Der Zusatz ist zu Lang!',
                                    },
                                    ]}>
                                    <Input placeholder="Zusatz" />
                                </Form.Item>
                            </Tooltip>
                        </Col>


                        {/*Geburt*/}
                        <Col span={24}>
                            <Divider orientation='left' orientationMargin="0"><PersonOutlineOutlinedIcon /> Geburtsangaben</Divider>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Geburtsname">
                                <Form.Item
                                    hasFeedback
                                    name="birthLastname"
                                    rules={[{
                                        required: true,
                                        min: 2,
                                        message: 'Bitte Ihren Geburtsnamen eingeben!',
                                    },
                                    {
                                        pattern: /^[a-zA-ZäöüßÄÖÜ ]+$/i,
                                        message: 'Der Geburtsname darf nur Buchstaben enthalten!',
                                    },
                                    {
                                        max: 50,
                                        message: 'Der Geburtsname ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<PersonOutlineOutlinedIcon fontSize='small' />} placeholder="Geburtsname" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Vorsatz (Geb.)">
                                <Form.Item
                                    name="birthPrefix"
                                    rules={[{
                                        pattern: /^[a-zA-ZäöüßÄÖÜ.\- ]+$/i,
                                        message: 'Der Vorsatz (Geb.) darf nur Buchstaben enthalten!',
                                    },
                                    {
                                        max: 25,
                                        message: 'Der Vorsatz (Geb.) ist zu Lang!',
                                    },
                                    ]}>
                                    <Input placeholder="Vorsatz (Geb.)" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Zusatz (Geb.)">
                                <Form.Item
                                    name="birthAddition"
                                    rules={[{
                                        pattern: /^[a-zA-ZäöüßÄÖÜ. ]+$/i,
                                        message: 'Der Zusatz (Geb.) darf nur Buchstaben enthalten!',
                                    },
                                    {
                                        max: 25,
                                        message: 'Der Zusatz (Geb.) ist zu Lang!',
                                    },
                                    ]}>
                                    <Input placeholder="Zusatz (Geb.)" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Geburtsstadt">
                                <Form.Item
                                    hasFeedback
                                    name="birthCity"
                                    rules={[{
                                        required: true,
                                        min: 2,
                                        message: 'Bitte Ihren Geburtsstadt eingeben!',
                                    },
                                    {
                                        pattern: /^[a-zA-ZäöüßÄÖÜ ]+$/i,
                                        message: 'Der Geburtsstadt darf nur Buchstaben enthalten!',
                                    },
                                    {
                                        max: 50,
                                        message: 'Der Geburtsstadt ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<LocationCityOutlinedIcon fontSize='small' />} placeholder="Geburtsstadt" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Geburtsland">
                                <Form.Item
                                    hasFeedback
                                    name="birthCountry"
                                    rules={[{
                                        required: true,
                                        min: 2,
                                        message: 'Bitte Ihren Geburtsland eingeben!',
                                    },
                                    {
                                        pattern: /^[a-zA-ZäöüßÄÖÜ ]+$/i,
                                        message: 'Der Geburtsland darf nur Buchstaben enthalten!',
                                    },
                                    {
                                        max: 50,
                                        message: 'Der Geburtsland ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<PublicOutlinedIcon fontSize='small' />} placeholder="Geburtsland" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={12} sm={18} md={18} lg={18} xl={18} ></Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" loading={loading} block>
                                    {title ? title : uuid ? "Speichern" : 'Erstellen'}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </div >
    );
};