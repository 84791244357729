import React, { useState, useEffect } from "react";
import { Space, Card, Row, Col, Divider, Button, List, Collapse, FloatButton } from 'antd';
import { ErrorDisplay, ListDisplay, BookingItem, LocationSelect, CourseTypeSelect, ModalDisplay, CourseFilter } from "../..";
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useParams } from "react-router-dom";


export const BookingList = ({ ghost, locationIds = [], courseTypeIds = [], editable, card, mainApi, custApi, authApi, pagination }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [location, setLocation] = useState(locationIds);
    const [size, setSize] = useState(10);
    const [courseType, setCourseType] = useState(courseTypeIds);
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState("");
    const [pageSize, setPageSize] = useState(10);

    const getData = async (loc = location, type = courseType, size = pageSize) => {
        try {
            const response = await mainApi.get(`/course/all?page=${1}&limit=${size}&status=${"BOOKING"}&locationIds=${loc}&courseTypeIds=${type}&release=1&prerelease=${0}&onlyFree=${0}`);
            setData(response.data.course);
        } catch (error) {
            setError("Probleme beim Laden der Daten: " + error?.message)
        } finally {
            setLoading(false);
        }
    };

    const handleSelect = (loc = locationIds, type = courseTypeIds, size = size) => {
        setSize(size)
        setLocation(loc)
        setCourseType(type)
        getData(loc, type, size)
    };


    useEffect(() => {
        setLoading(true)
        setError("")
        getData(location, courseType);
    }, []);

    return (
        <>
            <Row gutter={[16, 16]} style={{ maxHeight: "100vh" }}>
                <Col xs={24} sm={24} md={24} lg={6} xl={6}  >
                    <CourseFilter
                        mainApi={mainApi}
                        isSuccess={(location, courseType, size) => handleSelect(location, courseType, size)}
                        location={location}
                        courseType={courseType}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={18} xl={18} >
                    <ErrorDisplay error={error} loading={loading}>
                        <ListDisplay
                            bordered
                            emptyText="Keinen passenden Kurs gefunden."
                            maxHeight={""}
                            data={data}
                            listItem={(item) => <BookingItem authApi={authApi} editable={editable} custApi={custApi} mainApi={mainApi} item={item} refresh={() => setRefresh(true)} />}
                        />
                    </ErrorDisplay>
                </Col>
            </Row>
        </>
    );
};