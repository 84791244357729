import React from "react";

export default function Privacy({ }) {

    return (<>
        <h3>Datenschutzhinweis</h3>
        <h6>Allgemeine Hinweise und Pflichtinformationen</h6>
        <p>
            Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
        </p>

        <h6>
            Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
        </h6>
        <p>
            Adrian Trumpa <br />
            Kaiserstraße 3 <br />
            76131 Karlsruhe <br />
            Telefon: +49 (0) 178 3249700 <br />
            E-Mail: kontakt(at)swimgood-schwimmschule.de <br /> <br />
            Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen o. Ä.) entscheidet.
            Soweit wir für Verarbeitungsvorgänge personenbezogener Daten eine Einwilligung der betroffenen Person einholen, dient Art. 6 Abs. 1 lit. A der EU-Datenschutz-Grundverordnung (DSGVO) als Rechtsgrundlage. Bei der Verarbeitung von personenbezogenen Daten, die zur Erfüllung eines Vertrages, dessen Vertragspartei die betroffene Person ist, erforderlich ist, dient Art. 6 Abs. 1 lit. b DSGVO als Rechtsgrundlage. Dies gilt auch für Verarbeitungsvorgänge, die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind. Soweit eine Verarbeitung personenbezogener Daten zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist, der unser Unternehmen unterliegt, dient Art. 6 Abs. 1 lit. c DSGVO als Rechtsgrundlage.
        </p>
        <h6>
            Speicherung
        </h6>
        <p>
            Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.
        </p>
        <h6>
            Widerruf Ihrer Einwilligung zur Datenverarbeitung
        </h6>
        <p>
            Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
        </p>
        <h6>
            Welche Daten werden erfasst?
        </h6>
        <p>
            Wir erfassen zwei Arten von Daten und Informationen von Benutzern aufgrund unseres berechtigten Interesses (s. Art. 6 Abs. 1 lit. f. DSGVO).
            Zur ersten Kategorie gehören nicht identifizierende und nicht identifizierbare Benutzerdaten, die durch die Nutzung der Webseite bereitgestellt oder erfasst werden („Nicht personenbezogene Daten”). Wir kennen die Identität des Benutzers nicht, von dem nicht personenbezogene Daten erfasst wurden. Zu den nicht personenbezogenen Daten, die erfasst werden können, gehören aggregierte Nutzungsdaten und technische Daten, die von Ihrem Gerät übermittelt werden, einschließlich bestimmter Informationen bezüglich Software und Hardware (z. B. auf dem Gerät verwendeter Browser und verwendetes Betriebssystem, Spracheinstellung, Zugriffszeit usw.). Anhand dieser Daten verbessern wir die Funktionalität unserer Webseite. Wir können auch Daten über Ihre Aktivität auf der Webseite erfassen (z. B. aufgerufene Seiten, Surfverhalten, Klicks, Aktionen usw.).
            Zur zweiten Kategorie gehören personenbezogene Daten , also Daten, die eine Einzelperson identifizieren oder durch angemessene Maßnahmen identifizieren können. Zu solchen Daten gehören:
            Gerätedaten: Wir erfassen personenbezogene Daten von Ihrem Gerät. Solche Daten umfassen Geolocation-Daten, IP-Adresse, eindeutige Kennungen (z. B. MAC-Adresse und UUID) sowie andere Daten, die sich aus Ihrer Aktivität auf der Webseite ergeben.
        </p>
        <h6>
            Wie erhalten wir Daten über Sie?
        </h6>
        <p>
            Wir beziehen Ihre personenbezogenen Daten aus verschiedenen Quellen:
            Wir erhalten solche Daten, wenn Sie unsere Webseite nutzen oder in Verbindung mit einem unserer Services darauf zugreifen.
            Wir erhalten solche Daten von anderen Anbietern, Services und aus öffentlichen Registern (zum Beispiel von Datentraffic-Analyseanbietern).
        </p>
        <h6>
            Hosting durch IONOS SE
        </h6>
        <p>
            Diese Webseite wurde mit dem Online-Baukastensystem von IONOS SE erstellt und wird auch auf den Webservern des Unternehmens IONOS SE (Elgendorfer Str. 57, 56410 Montabaur Deutschland) gehostet und mit Software dieses Unternehmens betrieben. Um diesen Service und alle damit zusammenhängenden Services zu gewährleisten, setzt die IONOS SE auf Cookies und statistische Tools ein, die man in der Datenschutzerklärung von IONOS SE nachlesen kann. Die ausführliche Erklärung von IONOS zum Datenschutz finden Sie unter https://www.ionos.de/terms-gtc/terms-privacy
        </p>
        <h6>
            Cookies
        </h6>
        <p>
            Für die Bereitstellung entsprechender Services verwenden wir und unsere Partner Cookies. Dies gilt auch, wenn Sie unsere Webseite besuchen oder auf unsere Services zugreifen.
            Bei einem „Cookie“ handelt es sich um ein kleines Datenpaket, das Ihrem Gerät beim Besuch einer Webseite von dieser Webseite zugeordnet wird. Cookies sind nützlich und können für unterschiedliche Zwecke eingesetzt werden. Dazu gehören z. B. die erleichterte Navigation zwischen verschiedenen Seiten, die automatische Aktivierung bestimmter Funktionen, das Speichern Ihrer Einstellungen sowie ein optimierter Zugriff auf unsere Services. Die Verwendung von Cookies ermöglicht uns außerdem, Ihnen relevante, auf Ihre Interessen abgestimmte Werbung einzublenden und statistische Informationen zu Ihrer Nutzung unserer Services zu sammeln.
            <br />
            <br /> Diese Webseite verwendet folgende Arten von Cookies:
            <br />
            a. „Sitzungscookies“ , die für eine normale Systemnutzung sorgen. Sitzungscookies werden nur für begrenzte Zeit während einer Sitzung gespeichert und von Ihrem Gerät gelöscht, sobald Sie Ihren Browser schließen.
            <br />
            b. „Permanente Cookies“, die nur von der Webseite gelesen und beim Schließen des Browserfensters nicht gelöscht, sondern für eine bestimmte Dauer auf Ihrem Computer gespeichert werden. Diese Art von Cookie ermöglicht uns, Sie bei Ihrem nächsten Besuch zu identifizieren und beispielsweise Ihre Einstellungen zu speichern.
            <br />
            c. „Drittanbieter-Cookies“ , die von anderen Online-Diensten gesetzt werden, die mit eigenen Inhalten auf der von Ihnen besuchten Seite vertreten sind. Dies können z. B. externe Web-Analytics-Unternehmen sein, die den Zugriff auf unsere Webseite erfassen und analysieren.
            <br />
            Cookies enthalten keine personenbezogenen Daten, die Sie identifizieren, doch die von uns gespeicherten personenbezogenen Daten werden möglicherweise von uns mit den in den Cookies enthaltenen Daten verknüpft. Sie können Cookies über die Geräteeinstellungen Ihres Gerät entfernen. Folgen Sie dabei den entsprechenden Anweisungen. Beachten Sie, dass die Deaktivierung von Cookies zur Einschränkung bestimmter Funktionen bei der Nutzung unserer Webseite führen kann.
            Das von uns verwendete Tool basiert auf der Technologie von Snowplow Analytics . Zu den von uns erfassten Daten zur Nutzung unserer Webseite gehören beispielsweise, wie häufig Benutzer die Webseite besuchen oder welche Bereiche aufgerufen werden. Das von uns verwendete Tool erfasst keine personenbezogenen Daten und wird von unserem Webhosting-Anbieter und Service-Provider ausschließlich zur Verbesserung des eigenen Angebots verwendet.
            <br />
            <br />
            Falls Sie nicht möchten, dass Cookies zur Reichweitenmessung auf Ihrem Endgerät gespeichert werden, können Sie dem Einsatz dieser Dateien hier widersprechen:
            <br />
            • Cookie-Deaktivierungsseite der Netzwerkwerbeinitiative:
            https://optout.networkadvertising.org/?c=1#!/
            <br />
            • Cookie-Deaktivierungsseite der US-amerikanischen Website:
            https://optout.aboutads.info/?lang=EN&c=2#!%2F
            <br />
            • Cookie-Deaktivierungsseite der europäischen Website:
            https://optout.networkadvertising.org/?c=1#!/

        </p>
        <h6>
            Verwendung von Skriptbibliotheken (Google Web Fonts)
        </h6>
        <p>
            Damit unsere Inhalte in jedem Browser korrekt und grafisch ansprechend dargestellt werden, verwenden wir für diese Webseite Skript- und Schriftbibliotheken wie Google Web Fonts (https://www.google.com/webfonts). Google Web Fonts werden in den Cache Ihres Browsers übertragen, sodass sie nur einmal geladen werden müssen. Wenn Ihr Browser Google Web Fonts nicht unterstützt oder den Zugriff verweigert, werden die Inhalte in einer Standardschriftart dargestellt.
            Beim Aufrufen von Skript- oder Schriftbibliotheken wir automatisch eine Verbindung zum Betreiber der Bibliothek hergestellt. Es besteht hierbei theoretisch die Möglichkeit für diesen Betreiber, Daten zu erfassen. Derzeit ist nicht bekannt, ob und zu welchem Zweck die Betreiber der entsprechenden Bibliotheken tatsächlich Daten erfassen.
            Hier finden Sie die Datenschutzbestimmungen des Betreibers der Google-Bibliothek: https://www.google.com/policies/privacy.
        </p>
        <h6>
            Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten
        </h6>
        <p>
            Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten. Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in diese Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf hin, dass in diesen Ländern kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann. Beispielsweise sind US-Unternehmen dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
            Rechte des Nutzers
            Sie haben als Nutzer das Recht, auf Antrag eine kostenlose Auskunft darüber zu erhalten, welche personenbezogenen Daten über Sie gespeichert wurden. Sie haben außerdem das Recht auf Berichtigung falscher Daten und auf die Verarbeitungseinschränkung oder Löschung Ihrer personenbezogenen Daten. Falls zutreffend, können Sie auch Ihr Recht auf Datenportabilität geltend machen. Sollten Sie annehmen, dass Ihre Daten unrechtmäßig verarbeitet wurden, können Sie eine Beschwerde bei der zuständigen Aufsichtsbehörde einreichen.
        </p>
        <h6>
            Löschung von Daten
        </h6>
        <p>
            Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur Aufbewahrung von Daten (z. B. Vorratsdatenspeicherung) kollidiert, haben Sie ein Anrecht auf Löschung Ihrer Daten. Von uns gespeicherte Daten werden, sollten sie für ihre Zweckbestimmung nicht mehr vonnöten sein und es keine gesetzlichen Aufbewahrungsfristen geben, gelöscht. Falls eine Löschung nicht durchgeführt werden kann, da die Daten für zulässige gesetzliche Zwecke erforderlich sind, erfolgt eine Einschränkung der Datenverarbeitung. In diesem Fall werden die Daten gesperrt und nicht für andere Zwecke verarbeitet.
        </p>
        <h6>
            Widerspruchsrecht
        </h6>
        <p>
            Nutzer dieser Webseite können von ihrem Widerspruchsrecht Gebrauch machen und der Verarbeitung ihrer personenbezogenen Daten zu jeder Zeit widersprechen.
            Wenn Sie eine Berichtigung, Sperrung, Löschung oder Auskunft über die zu Ihrer Person gespeicherten personenbezogenen Daten wünschen oder Fragen bzgl. der Erhebung, Verarbeitung oder Verwendung Ihrer personenbezogenen Daten haben oder erteilte Einwilligungen widerrufen möchten, wenden Sie sich bitte an folgende E-Mail-Adresse: adrian.trumpa(at)swimgoodka.com
        </p>
        <h6>
            Beschwerderecht
        </h6>
        <p>
            Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.
        </p>
        <h6>
            Kommunikation via WhatsApp
        </h6>
        <p>
            Für die Kommunikation mit unseren Kunden und sonstigen Dritten nutzen wir unter anderem den Instant-Messaging-Dienst WhatsApp. Anbieter ist die WhatsApp Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland.
            Die Kommunikation erfolgt über eine Ende-zu-Ende-Verschlüsselung (Peer-to-Peer), die verhindert, dass WhatsApp oder sonstige Dritte Zugriff auf die Kommunikationsinhalte erlangen können. WhatsApp erhält jedoch Zugriff auf Metadaten, die im Zuge des Kommunikationsvorgangs entstehen (z.B. Absender, Empfänger und Zeitpunkt). Wir weisen darauf hin, dass WhatsApp nach eigener Aussage, personenbezogene Daten seiner Nutzer mit seiner in den USA ansässigen Konzernmutter Facebook teilt. Weitere Details zur Datenverarbeitung finden Sie in der Datenschutzrichtlinie von WhatsApp unter: https://www.whatsapp.com/legal/#privacy-policy
            Der Einsatz von WhatsApp erfolgt auf Grundlage unseres berechtigten Interesses an einer möglichst schnellen und effektiven Kommunikation mit Kunden, Interessenten und sonstigen Geschäfts- und Vertragspartnern (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Datenverarbeitung ausschließlich auf Grundlage der Einwilligung; diese ist jederzeit mit Wirkung für die Zukunft widerrufbar.
            Die zwischen und auf WhatsApp ausgetauschten Kommunikationsinhalte verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt. Zwingende gesetzliche Bestimmungen insbesondere Aufbewahrungsfristen bleiben unberührt.
            Es wird WhatsApp in der Variante „WhatsApp Business“ genutzt.
            Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier: https://www.whatsapp.com/legal/business-data-transfer-addendum
        </p>
        <h6>
            Kontaktformulare/E-Mail/Telefon
        </h6>
        <p>
            Wenn Sie uns per Kontaktformular, per E-Mail oder per Telefon kontaktieren, werden Ihre Angaben inklusive der von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns gespeichert und verarbeitet. Ihre Daten werden nicht ohne Ihre Einwilligung weiter gegeben.
            Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde.
            Die von Ihnen im Kontaktformular, per E-Mail oder per Telefon eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer Anfrage). Zwingende gesetzliche Bestimmungen insbesondere Aufbewahrungsfristen bleiben unberührt.
        </p>
        <h6>
            Links zu anderen Webseiten
        </h6>
        <p>
            Unsere Website kann Links zu Websites Dritter enthalten. Wenn Sie einem Link zu einer dieser Websites folgen, weisen wir Sie darauf hin, dass diese Websites ihre eigenen Datenschutzrichtlinien haben und dass wir für diese Richtlinien keine Verantwortung oder Haftung übernehmen. Bitte überprüfen Sie diese Datenschutzrichtlinien, bevor Sie personenbezogenen Daten an diese Websites weitergeben.
        </p>
        <h6>
            Minderjährige
        </h6>
        <p>
            Der Schutz der Daten von Kindern ist insbesondere im Online-Bereich sehr wichtig. Die Webseite ist nicht für Kinder konzipiert und richtet sich nicht an diese. Die Nutzung unserer Services durch Minderjährige ist nur mit der vorherigen Einwilligung oder Autorisierung von einem Elternteil oder Erziehungsberechtigten zulässig. Wir erfassen personenbezogene Daten von Minderjährigen nicht wissentlich. Wenn ein Elternteil oder Erziehungsberechtigter Kenntnis davon erlangt, dass sein oder ihr Kind uns personenbezogene Daten ohne deren Einwilligung bereitgestellt hat, kann er/sie sich unter info@swimgoodka.com an uns wenden.
            Aktualisierungen oder Änderungen dieser Datenschutzbestimmungen
            Wir behalten uns das Recht vor, diese Datenschutzbestimmungen von Zeit zu Zeit zu ändern oder zu prüfen. Sie finden das Datum der aktuellen Version unter „Zuletzt geändert am“. Ihre fortgesetzte Nutzung der Plattform nach der Bekanntmachung solcher Änderungen auf unserer Webseite stellt Ihre Zustimmung zu solchen Änderungen an den Datenschutzbestimmungen dar und gilt als Ihr Einverständnis der Bindung an die geänderten Bestimmungen.
        </p>
        <h6>
            SSL-Verschlüsselung
        </h6>
        <p>
            Diese Website nutzt die SSL-Verschlüsselung (Secure Socket Layer) für die Übertragung von Daten Ihres Browsers zu unserem Server und zu Servern, die Dateien bereitstellen, welche wir auf unserer Webseite einbinden.
            Mit SSL werden Daten verschlüsselt übertragen. Die Daten sind nicht veränderbar und der Absender kann identifiziert werden. Sie erkennen das Vorhandensein der SSL-Verschlüsselung anhand des vorangestellten Textes "https" vor der Adresse der Webseite, die sie im Browser aufrufen.
        </p>
        Zuletzt geändert am 30.01.2024
    </ >);
};