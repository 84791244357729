import React, { useState, useEffect } from "react";
import { Space, Card, Row, Col, Divider } from 'antd';
import { CreateButton, ErrorDisplay, SearchFilter, ListDisplay, ModalDisplay, UnitItem, UnitForm, getFilter, ContactItem, ContactForm, CoachItem, CoachForm, CustomerForm, CustomerItem, ParticipationForm, ParticipationItemParticipant, ParticipationItemCourse } from "../..";
import PoolOutlinedIcon from '@mui/icons-material/PoolOutlined';

export const ParticipationList = ({ ghost, courseIds = "", participantIds = "", editable, card, mainApi, custApi, pagination, filterText, filterStartDate, filterEndDate, filterStatus, displayParticipantMode }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [error, setError] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    const getData = async (search = "", status = getFilter(filterStatus), start = getFilter(filterStartDate), end = getFilter(filterEndDate),) => {
        try {
            const response = await custApi.get(`/participation/all?page=${currentPage}&limit=${pageSize}&search=${search}&status=${status}&courseIds=${courseIds}&participantIds=${participantIds}&startDate=${start}&endDate=${end}`);
            setData(response.data);
        } catch (error) {
            setError("Probleme beim Laden der Daten: " + error?.response?.data?.message)
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        setRefresh(true);
    };

    const handlePageSizeChange = (size) => {
        setCurrentPage(1);
        setPageSize(size);
        setRefresh(true);
    };

    useEffect(() => {
        setLoading(true)
        setError("")
        getData()
        setRefresh(false)
    }, [open, refresh]);

    const result = (
        <Row Row gutter={[16, 16]} >
            <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                {ghost ? "" : <Divider orientation='left' orientationMargin="0">
                    <Space>
                        <><PoolOutlinedIcon />Teilnahmen</>
                    </Space>
                </Divider>}
                <SearchFilter
                    text={!filterText}
                    onSubmit={(values) => { getData(values.search, values.status) }}
                />
                <ErrorDisplay error={error} loading={loading}>
                    <ListDisplay
                        emptyText="Keine Teilnahmen gefunden."
                        emptyOnClick={() => setOpen(editable ? true : false)}
                        data={data.participation}
                        bordered
                        listItem={(item) => <ParticipationItemParticipant mainApi={mainApi} editable={editable} custApi={custApi} item={item} refresh={() => setRefresh(true)} />}
                        //maxHeight={500}
                        pagination={!pagination ? "" : {
                            current: currentPage,
                            total: data?.totalPages,
                            onChange: handlePageChange,
                        }}
                        pageSize={{ current: pageSize, handle: handlePageSizeChange }}
                    />
                    <ModalDisplay
                        onCancel={() => { setOpen(false) }}
                        open={open}
                        content={
                            <ParticipationForm
                                custApi={custApi}
                                isSuccess={() => {
                                    setOpen(false)
                                    setRefresh(true)
                                }}
                            />}
                    />
                </ErrorDisplay>
            </Col >
        </Row >
    );



    return (<>
        {card ? <Card size="small" loading={loading} > {result} </Card>
            :
            result}
    </>);
};