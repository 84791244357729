import React, { useEffect, useState } from 'react';
import { TimePicker, Form, Input, Button, Spin, Space, message, Row, Col, Divider, Tooltip, Select } from 'antd';
import TransgenderOutlinedIcon from '@mui/icons-material/TransgenderOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import dayjs from 'dayjs';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import AddAlarmOutlinedIcon from '@mui/icons-material/AddAlarmOutlined';
import moment from 'moment';

const { Option } = Select;
export const AvailableForm = ({ title, isSuccess, uuid, contactId, mainApi }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [user, setUser] = useState([])

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user")) || {};
        if (storedUser) { setUser(storedUser) }
        if (uuid) {
            getData();
        }
    }, []);

    const getData = async () => {
        try {
            setLoading(true);
            const response = await mainApi.get(`/available/one/${uuid}`);
            const unit = {
                day: response?.data?.available?.day,
                startTime: response?.data?.available?.startTime,
                endTime: response?.data?.available?.phone?.endTime,
            }
            form.setFieldsValue(unit);
        } catch (error) {
            message.error("Fehler beim Laden der Daten. " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };
    const onFinish = async (values) => {
        let response;
        try {
            setLoading(true);
            if (uuid) {
                values.times[0].contactId = contactId;
                response = await mainApi.patch(`/available/update/${uuid}`, values.times[0]);
            } else {
                for (const value of values.times) {
                    value.contactId = contactId;
                    response = await mainApi.post(`/available`, value);
                }
            }
            isSuccess();
        } catch (error) {
            message.error("Fehlgeschlagen: " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    const validateEndTime = async () => {
        const { startTime } = form.getFieldsValue("startTime");
        const { endTime } = form.getFieldsValue("endTime");
        if (startTime && endTime && moment(startTime).isAfter(endTime)) {
            throw new Error('Das Enddatum muss nach dem Startdatum liegen.');
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Form
                name="ContactForm"
                requiredMark={false}
                form={form}
                onFinish={onFinish}
                layout="vertical"
                validateTrigger="onChange"
                style={{ width: "100%" }}
            >
                <Space direction="vertical">
                    <h4>{uuid ? 'Verfügbarkeit ändern' : 'Verfügbarkeit eintragen'}</h4>
                </Space>
                <Spin spinning={false} size="large" loading={loading}>
                    <Row gutter={[16, 0]}>
                        <Form.List name="times">
                            {(fields, { add, remove }) => (
                                < >
                                    {fields.map(({ key, name, ...restField }) => (
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} key={key}>
                                            <Row gutter={[16, 0]}>
                                                <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                                                    <Divider style={{ marginBottom: 15, marginTop: -5 }}></Divider>
                                                </Col>
                                                <Col xs={24} sm={24} md={12} lg={12} xl={12} >
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'day']}
                                                        rules={[{ required: true, message: 'Bitte einen Wochentag angeben.' }]}
                                                    >
                                                        <Select prefix={<TransgenderOutlinedIcon fontSize='small' />} placeholder="Tag">
                                                            <Option value="Montag">Montag</Option>
                                                            <Option value="Dienstag">Dienstag</Option>
                                                            <Option value="Mittwoch">Mittwoch</Option>
                                                            <Option value="Donnerstag">Donnerstag</Option>
                                                            <Option value="Freitag">Freitag</Option>
                                                            <Option value="Samstag">Samstag</Option>
                                                            <Option value="Sonntag">Sonntag</Option>
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={11} sm={11} md={5} lg={5} xl={5} >
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'startTime']}
                                                        rules={[{ required: true, message: 'Bitte eine Beginn angeben.' }]}
                                                    >
                                                        <TimePicker style={{ width: "100%" }} changeOnBlur showNow={false} format={"HH:mm"} placeholder='Start' />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={11} sm={11} md={5} lg={5} xl={5} >
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'endTime']}
                                                        rules={[{ required: true, message: 'Bitte eine Ende angeben.' },
                                                        { validator: validateEndTime },
                                                        ]}
                                                    >
                                                        <TimePicker style={{ width: "100%" }} changeOnBlur showNow={false} format={"HH:mm"} placeholder='Ende' />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={2} sm={2} md={2} lg={2} xl={2} >
                                                    <Form.Item>
                                                        <Button type='dashed' icon={<RemoveCircleOutlineOutlinedIcon fontSize='small' />} onClick={() => remove(name)} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>))}

                                    <Col xs={24} sm={12} md={16} lg={17} xl={18} >
                                        <Form.Item>
                                            <Button type="default" onClick={() => add()} block icon={<AddAlarmOutlinedIcon />}>Verfügbarkeit Hinzufügen </Button>
                                        </Form.Item>
                                    </Col>
                                </>)}
                        </Form.List>
                        <Col xs={16} sm={12} md={8} lg={7} xl={6} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" loading={loading} block>
                                    {title ? title : uuid ? "Speichern" : 'Erstellen'}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </div >
    );
};