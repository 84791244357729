import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Tabs } from "antd";
import Layout from "../layout/Layout";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
//Icons
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { EmptyDisplay, ErrorDisplay, ModalDisplay, ParticipantForm, ParticipantShowCustom } from "../package";
import { SystemApi, customCustomerApi } from "../urlConfig";

export const TabsDisplay = ({ content = {}, action }) => {
  const { pages, label } = content
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState("");
  const [participants, setParticipants] = useState([])
  const [key, setKey] = useState("0")
  const [open, setOpen] = useState(false);

  const test = () => {
    if (content.pages.some((page) => page.key.includes(new URLSearchParams(window.location.search).get("id")))) {
      setKey(new URLSearchParams(window.location.search).get("id"))
    }
    if (pages.length === 1) {
      console.log((pages[0]?.key).toString())
      setKey((pages[0]?.key).toString())
    }
  }

  useEffect(() => {

    test()
    setLoading(false)
  }, []);

  const pagesContent = pages?.map((data, index) => ({
    key: data?.key,
    label: data?.label,
    children: data?.children,
  }));

  const defaultPage = [
    {
      key: '0',
      label: (label),
      children: (
        <Row gutter={[16, 16]}>
          {pages.map((data, index) => (
            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
              <EmptyDisplay onClick={() => setKey((data?.key).toString())} card icon={data.icon} text={data.label} />
            </Col>
          ))}
          {!action ? "" :
            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
              <EmptyDisplay onClick={() => action.onClick()} card icon={action.icon} text={action.label} />
            </Col>}
        </Row>
      ),
    },
  ];
  const display = defaultPage.concat(pagesContent)

  return (
    <React.Fragment>
      <ErrorDisplay error={error} loading={loading}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Tabs type="line" size="small" activeKey={key} items={display} onTabClick={() => setKey(this)} />
        </Col>
      </ErrorDisplay>
    </React.Fragment>
  );
};