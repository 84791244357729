import { Row, Col, List, Pagination, Space, Select } from "antd";
import { EmptyDisplay } from "./emptyDisplay";

export const ListDisplay = ({ emptyOnClick, data, listItem, emptyText, maxHeight, pagination, bordered, pageSize }) => {
    if (!data || data.length === 0) {
        return (
            <EmptyDisplay iconHeight={0} card text={emptyText} onClick={emptyOnClick} />
        );
    }

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
                    <List
                        style={{ overflow: "auto", maxHeight: maxHeight }}
                        size="small"
                        bordered={!bordered}
                        itemLayout="vertical"
                        dataSource={data}
                        renderItem={(item, index) => listItem(item, index)}
                    />
                </Col>
                {!pagination ? "" : <>
                    <Space xs={20} sm={20} md={20} lg={20} xl={20} xxl={20} >
                        <Pagination
                            size="small"
                            responsive
                            current={pagination?.current}
                            total={pagination.total * 10}
                            onChange={(e) => pagination?.onChange(e)}
                            showSizeChanger={false}
                        />
                        <Select size="small"
                            defaultValue={pageSize.current} onChange={(e) => pageSize.handle(e)}
                            options={[
                                { value: 1, label: "1 / Seite" },
                                { value: 5, label: "5 / Seite" },
                                { value: 10, label: "10 / Seite" },
                                { value: 25, label: "25 / Seite" },
                                { value: 50, label: "50 / Seite" },
                            ]}>
                        </Select>
                    </Space>
                </>
                }
            </Row >
        </>
    );
};
