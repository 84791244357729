
import React from "react";
import { CloseCircleOutlined } from '@ant-design/icons';
import { Row, Col, Button, Result, Typography } from "antd";

const { Paragraph, Text } = Typography;

export const ErrorPage = ({ data = "" }) => {
        const { title, message, primaryButton, secondaryButton, icon, error } = data;

    return (
        <React.Fragment>
            <Row justify="center" align="middle" style={{ height: '100vh' }}>
                <Col xs={24} sm={20} md={18} lg={16} xl={14}>
                    <Result
                        icon={icon ? icon : ''}
                        status="error"
                        title={title ? title : "Leider ist ein Fehler aufgetreten"}
                        subTitle={message ? message : "Bitte versuchen Sie es nocheinmal!"}
                        extra={[
                            primaryButton ?
                                [<Button type="primary" href={primaryButton.link}>
                                    {primaryButton.label}
                                </Button>]
                                : '',
                            secondaryButton ?
                                [<Button href={secondaryButton.link}>
                                    {secondaryButton.label}
                                </Button>] : '',
                        ]}
                    >
                        <div className="desc">
                            <Paragraph>
                                <Text
                                    strong
                                    style={{
                                        fontSize: 16,
                                    }}
                                >
                                    The content you submitted has the following error:
                                </Text>
                            </Paragraph>
                            <Paragraph>
                                <CloseCircleOutlined className="site-result-demo-error-icon" /> {error}
                            </Paragraph>
                        </div>
                    </Result>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default ErrorPage;
