import React, { useEffect, useState } from 'react';
import { TimePicker, Form, Input, Button, Spin, Space, message, Row, Col, Divider, Tooltip, Select, DatePicker } from 'antd';

import { QuillEditor, SelectWithArray, SelectWithData, PriceInput } from '../..';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import TitleOutlinedIcon from '@mui/icons-material/TitleOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import TransgenderOutlinedIcon from '@mui/icons-material/TransgenderOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import AddAlarmOutlinedIcon from '@mui/icons-material/AddAlarmOutlined';
import moment from 'moment';
import { json } from 'react-router-dom';

const { RangePicker } = DatePicker;
export const UnitTimeForm = ({ title, isSuccess, uuid, unitId, mainApi }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [user, setUser] = useState([])

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem("user")) || {};
        if (storedUser) { setUser(storedUser) }
        if (uuid) {
            getData();
        }
    }, []);

    const getData = async () => {
        try {
            setLoading(true);
            const response = await mainApi.get(`/unitTime/one/${uuid}`);
            const data = {
                times: [{
                    time: [
                        moment(response?.data?.unitTime?.start),
                        moment(response?.data?.unitTime?.end)
                    ]
                }]
            }
            form.setFieldsValue(data);
        } catch (error) {
            message.error("Fehler beim Laden der Daten. " + error?.response?.data?.message && error?.message);
        } finally {
            setLoading(false);
        }
    };

    const onFinish = async (values) => {
        let response;
        values.userId = user?.uuid;
        values.unitId = unitId;
        console.log(values.times)
        try {
            setLoading(true);
            if (uuid) {
                values.start = values.times[0].time[0];
                values.end = values.times[0].time[1];
                response = await mainApi.patch(`/unitTime/update/${uuid}`, values);
            } else {
                for (const value of values.times) {
                    const timeValues = {
                        ...values,
                        start: value.time[0],
                        end: value.time[1],
                    };
                    response = await mainApi.post(`/unitTime`, timeValues);
                }
            }
            isSuccess();
        } catch (error) {
            message.error("Fehlgeschlagen: " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Form
                name="UnitTimeForm"
                requiredMark={false}
                form={form}
                onFinish={onFinish}
                layout="vertical"
                validateTrigger="onChange"
                style={{ width: "100%" }}
            >
                <Space direction="vertical">
                    <h3>{uuid ? 'Zeiten ändern' : 'Zeiten anlegen'}</h3>
                </Space>
                <Spin spinning={false} size="large" loading={loading}>
                    <Row gutter={[16, 0]}>

                        <Form.List name="times">
                            {(fields, { add, remove }) => (
                                < >
                                    <Col span={24}>
                                        <Divider orientation='left' orientationMargin="0">Angelegte Zeiten: {form.getFieldValue("times")?.length || "0"} </Divider>
                                    </Col>
                                    {fields.map(({ i, key, name, ...restField }) => (
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} key={key}>
                                            <Row gutter={[16, 0]}>
                                                <Col xs={20} sm={22} md={22} lg={22} xl={22} >
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'time']}
                                                        rules={[{ required: true, message: 'Bitte einen Zeitraum angeben.' }]}
                                                    >
                                                        <RangePicker showTime style={{ width: "100%" }} changeOnBlur showNow={false} placeholder={['Start', 'Ende']} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={4} sm={2} md={2} lg={2} xl={2} >
                                                    <Form.Item>
                                                        <Button type='dashed' icon={<RemoveCircleOutlineOutlinedIcon fontSize='small' />} onClick={() => remove(name)} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>))}

                                    <Col xs={24} sm={12} md={16} lg={17} xl={18} >
                                        <Form.Item>
                                            <Button type="default" onClick={() => add()} block icon={<AddAlarmOutlinedIcon />}>Verfügbarkeit Hinzufügen </Button>
                                        </Form.Item>
                                    </Col>
                                </>)}
                        </Form.List>
                        <Col xs={24} sm={12} md={8} lg={7} xl={6} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" loading={loading} block>
                                    {title ? title : uuid ? "Speichern" : 'Erstellen'}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </div >
    );
};