import React, { useEffect, useState } from "react";
import { StepDisplay, BookingCustomerForm, BookingParticipantForm, ErrorDisplay, BookingOverviewForm, SuccessPage, BookingItem } from "../..";
//Icons
import { Divider, message } from "antd";
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import PoolOutlinedIcon from '@mui/icons-material/PoolOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

export const FullParticipationForm = ({ isSuccess, courseId, mainApi, custApi, authApi }) => {
    const [course, setCourse] = useState({});
    const [location, setLocation] = useState({});
    const [customer, setCustomer] = useState({});
    const [participant, setParticipant] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [current, setCurrent] = useState(0);

    const next = () => {
        if (current + 1 === items.length) {
            isSuccess()
        } else {
            setCurrent(current + 1);
        }
    };
    const prev = () => {
        setCurrent(current - 1);
    };

    const getData = async () => {
        try {
            const response = await mainApi.get(`/course/one/${courseId}`);
            setCourse(response.data.course);
            const location = await mainApi.get(`/location/all?limit=1&locationIds=${response.data.course?.courseDates[0]?.unit?.locationId}`);
            setLocation(location.data.location[0]);
        } catch (error) {
            setError("Probleme beim Laden der Daten: " + error?.response?.data?.message)
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        setLoading(true)
        try {
            const values = { courseId: course.uuid, participantId: participant.uuid, locationShort: location.locationAccessible?.informations[0].subtitle }
            await custApi.post(`/participation`, values);
            next()
        } catch (error) {
            message.error("Anmeldung Fehlgeschlagen: " + error?.response?.data?.message)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setError("")
        getData();
        setLoading(false)
    }, []);


    const items = [
        {
            key: 0,
            title: 'Kurs',
            icon: <ArticleOutlinedIcon />,
            content: (<>
                <Divider style={{ margin: 5 }}></Divider>
                <BookingItem
                    size="full"
                    item={course}
                    custApi={custApi}
                    mainApi={mainApi}
                    authApi={authApi}
                    isSuccess={() => {
                        next();
                    }}
                />
            </>),
        },
        {
            key: 1,
            title: 'Buchender',
            icon: <PersonOutlineOutlinedIcon />,
            content: (<>
                <BookingCustomerForm
                    custApi={custApi}
                    authApi={authApi}
                    isSuccess={(data) => {
                        setCustomer(data)
                        next();
                    }}
                />
            </>),
        },
        {
            key: 2,
            title: 'Teilnehmer',
            content: (<BookingParticipantForm
                custApi={custApi}
                customerId={customer?.uuid}
                ageRange={course?.courseType?.ageRange}
                participantId={""}
                isSuccess={(data) => {
                    setParticipant(data)
                    next()
                }}
            />),
            icon: <PoolOutlinedIcon />,
        },
        {
            key: 3,
            title: 'Übersicht',
            content: (<BookingOverviewForm
                course={course}
                location={location}
                customer={customer}
                participant={participant}
                isSuccess={() => handleSubmit()}
            />),
            icon: <SummarizeOutlinedIcon />,
        },
        {
            key: 4,
            title: '',
            content: (<SuccessPage data={{
                title: "Vielen Dank für Ihre Kursanmeldung",
                message: "Wir bestätigen Ihnen die Anmeldung in den nächsten Minuten mit einer Email. Alle Kursanmeldungen finden Sie unter Meine-Kurse",
                primaryButton: { link: "/my-course", label: "Meine Kurse ansehen" },
            }} />),
            icon: <CheckCircleOutlineOutlinedIcon />,
            lockBack: true,
        },
    ];
    return (<>
        <ErrorDisplay error={error} loading={loading}>
            <StepDisplay items={items} prev={() => prev()} next={() => next()} current={current} />
        </ErrorDisplay>
    </>);
};