import React, { useState, useEffect } from "react";
import RenewForm from "../../components/forms/start/RenewForm";
import { Row, Col, Avatar } from "antd";

import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';
import SuccessPage from "../../package/components/displays/SuccessPage";

const RegisterPage = () => {
  const [isSuccess, setIsSuccess] = useState(false);

  const success = {
    icon: <GreenRoundIcon />,
    title: 'E-Mail wurde erneut gesendet.',
    message: "Bitte bestätigen Sie Ihre Email-Adresse, hiezu haben Sie ein Email von uns erhalten.",
    primaryButton: {
      link: '/',
      label: 'Email Bestätigt? Jetzt Anmelden',
    },
    secondaryButton: {
      link: '/register/new',
      label: 'Email nocheinmal senden?',
    },
  }

  return (
    <React.Fragment>
      <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
        <Col xs={12} sm={10} md={9} lg={8} xl={7}>
          {isSuccess ? <SuccessPage data={success} /> :
            <RenewForm linkLogin={'/'} isSuccess={() => setIsSuccess(true)}></RenewForm>
          }
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default RegisterPage;


const GreenRoundIcon = () => (
  <Avatar size='large' style={{ backgroundColor: '#1a4568' }}>
    <FingerprintOutlinedIcon />
  </Avatar>
);
