import React, { useState } from "react";
import { StepDisplay, SuccessPage, ParticipantForm, CustomerForm } from "../..";
//Icons
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import PoolOutlinedIcon from '@mui/icons-material/PoolOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

export const InitPageForm = ({ isSuccess, custApi }) => {
    const [customer, setCustomer] = useState("");
    const [participant, setParticipant] = useState("");
    const [current, setCurrent] = useState(0);

    const next = () => {
        if (current + 1 === items.length) {
            isSuccess()
        } else {
            setCurrent(current + 1);
        }
    };
    const prev = () => {
        setCurrent(current - 1);
    };

    const items = [
        {
            key: 1,
            title: 'Mein Profil',
            icon: <PersonOutlineOutlinedIcon />,
            content: (<>
                <CustomerForm
                    uuid={customer}
                    custApi={custApi}
                    isSuccess={(uuid) => {
                        setCustomer(uuid)
                        next();
                    }}
                />
            </>),
        },
        {
            key: 2,
            title: 'Teilnehmer anlegen',
            content: (
                <ParticipantForm
                    uuid={participant}
                    customerId={customer}
                    custApi={custApi}
                    isSuccess={(uuid) => {
                        setParticipant(uuid)
                        next()
                    }}
                />),
            icon: <PoolOutlinedIcon />,
            skip: true,
        },
        {
            key: 3,
            title: '',
            content: (<SuccessPage data={{
                title: "Vielen Dank für Ihre Registrierung",
                message: "Ihr Account ist erfolgreich erstellt, Sie können nun eine Kursanmeldung mit nur drei Klicks erstellen.",
                primaryButton: { link: "/course", label: "Kurs finden" },
            }} />),
            icon: <CheckCircleOutlineOutlinedIcon />,
            lockBack: true,
        },
    ];
    return (<>
        <StepDisplay items={items} prev={() => prev()} next={() => next()} current={current} />
    </>);
};