import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Space, message, Row, Col, Divider, Tooltip, Select } from 'antd';
import TransgenderOutlinedIcon from '@mui/icons-material/TransgenderOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import { LocationSelect } from '../../components/form/parts/LocationSelect';
import { SelectWithData } from '../../components/form/parts/SelectWithData';

const { Option } = Select;
export const CoachForm = ({ title, isSuccess, uuid, employeeId, name, mainApi }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();
    const [coachUuid, setCoachUuid] = useState("")

    useEffect(() => {
        if (uuid) {
            getData();
        }
    }, []);

    const getData = async () => {
        try {
            setLoading(true);
            const response = await mainApi.get(`/coach/one/${uuid}`);
            setCoachUuid(response.data?.coach?.uuid)
            const data = {
                locationId: response?.data?.coach?.locationId,
                status: response?.data?.coach?.coachStatus?.statusValue,
            }
            form.setFieldsValue(data);
        } catch (error) {
            message.error("Fehler beim Laden der Daten. " + error?.message);
        } finally {
            setLoading(false);
        }
    };

    const onFinish = async (values) => {
        let response;
        values.employeeId = employeeId;
        values.name = name;
        try {
            setLoading(true);
            if (uuid) {
                response = await mainApi.patch(`/coach/update/${coachUuid}`, values);
            } else {
                response = await mainApi.post(`/coach`, values);
            }
            isSuccess(response.data.coach);
        } catch (error) {
            message.error("Fehlgeschlagen: " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Form
                name="CoachForm"
                requiredMark={false}
                form={form}
                onFinish={onFinish}
                layout="vertical"
                validateTrigger="onChange"
                style={{ width: "100%" }}
            >
                <Space direction="vertical">
                    <h3>{uuid ? 'Trainer ändern' : 'Trainer anlegen'}</h3>
                </Space>
                <Spin spinning={false} size="large" loading={loading}>
                    <Row gutter={[16, 0]}>
                        {/**/}
                        <Col span={24}>
                            <Divider orientation='left' orientationMargin="0"><PersonOutlineOutlinedIcon /> Trainer</Divider>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <LocationSelect mainApi={mainApi} />
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <SelectWithData
                                label="Status"
                                tooltip={"Status des Trainers?"}
                                name="status"
                                data={[
                                    { value: "ACTIVE", label: "Aktiv" },
                                    { value: "INTERNAL", label: "Intern (Verwaltung)" },
                                    { value: "INACTIVE", label: "Inaktiv" },
                                    { value: "FALLBACK", label: "Ersatz" },
                                    { value: "PENDING", label: "Nur ansicht" },
                                    { value: "BLOCKED", label: "Blockiert" },
                                ]}
                            />
                        </Col>

                        <Col xs={24} sm={12} md={16} lg={17} xl={18} ></Col>
                        <Col xs={16} sm={12} md={8} lg={7} xl={6} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" loading={loading} block>
                                    {title ? title : uuid ? "Speichern" : 'Erstellen'}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </div >
    );
};