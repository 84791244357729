import { Card, Empty } from "antd";
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined';

export const EmptyDisplay = ({ onClick, card, icon, text, iconHeight }) => {
    if (card) {
        return (
            <Card size="small" hoverable onClick={onClick} style={{width: "100%"}}>
                <Empty
                    imageStyle={{ height: iconHeight }}
                    image={icon ? icon : <DataObjectOutlinedIcon style={{ zoom: 2, color: '#9dcedf' }} />}
                    description={<h6 style={{ color: '#c0c0c0' }}> {text ? text : "Leer"} </h6>}
                />
            </Card>
        )
    }
    return (
        <Empty
            onClick={onClick}
            imageStyle={{ height: iconHeight }}
            image={icon ? icon : <DataObjectOutlinedIcon style={{ zoom: 2, color: '#9dcedf' }} />}
            description={<h6 style={{ color: '#c0c0c0' }}>  {text ? text : "Leer"} </h6>}
        />
    )
};