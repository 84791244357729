import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Space, message, Row, Col, Divider, Tooltip, Select, Card } from 'antd';

import { ErrorDisplay } from '../../components/displays/errorDisplay';
import { CustomerLogInForm } from '../user/CustomerLogInForm';
import { useNavigate } from 'react-router-dom';

const { Option } = Select;
export const BookingCustomerForm = ({ title, isSuccess, uuid, custApi, authApi }) => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState("")
    const [customer, setCustomer] = useState()
    const [logIn, setLogIn] = useState()
    const [form] = Form.useForm();

    useEffect(() => {
        setLoading(true)
        const user = JSON.parse(localStorage.getItem("user"))
        setLogIn(user)
        getData(user);
    }, []);


    const getData = async (user) => {
        try {
            if (user?.customer) {
                const customer = await custApi.get(`/customer/one/${user?.customer?.uuid}`);
                setCustomer(customer?.data?.customer)
            }
        } catch (error) {
            setError('Fehler beim Laden der Benutzerdetails.' + error?.response?.data?.message);
        } finally {
            setLoading(false)
        }
    };

    return (
        <ErrorDisplay loading={loading} error={error}>
            {customer ?
                <Row justify="center">
                    <Col xs={24} sm={24} md={16} lg={16} xl={16} >
                        <Space style={{ width: "100%" }} direction='vertical'>
                            <Card><Card.Meta
                                description="Wir haben ein Profil gefunden, sind sie das?"
                                title={customer?.person?.name?.firstname + " " + customer?.person?.name?.lastname}
                            ></Card.Meta></Card>
                            <Button block onClick={() => isSuccess(customer)} type='primary' > Fortfahren </Button>
                            <Button block onClick={() => setCustomer("")} type='text' > Das bin ich nicht, neu Anmelden  </Button>
                        </Space>
                    </Col>
                </Row>
                :
                <Row justify="center">
                    <Col xs={24} sm={24} md={16} lg={16} xl={16} >
                        <Space style={{ width: "100%" }} direction='vertical'>
                            <Card><Card.Meta
                                description="Bitte melden Sie sich an!"
                                title={"Sie haben schon einen Account?"}
                            ></Card.Meta>
                                <CustomerLogInForm ghost authApi={authApi} isSuccess={(data) => {
                                    isSuccess(data)
                                    window.location.reload();
                                }} />
                            </Card>
                            {JSON.stringify(authApi)}
                        </Space>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                        <Divider> Oder </Divider>
                    </Col>
                    <Col xs={24} sm={24} md={16} lg={16} xl={16} >
                        <Space style={{ width: "100%" }} direction='vertical'>
                            <Card><Card.Meta
                                description="Bitte erstellen Sie sich eine Account, anschließend können Sie die Kursanmeldung fortfahren!"
                                title={"Account Erstellen"}
                            ></Card.Meta></Card>
                            <Button block onClick={() => navigate("/register")} type='primary' > Erstellen  </Button>
                        </Space>
                    </Col>
                </Row>
            }

        </ErrorDisplay>
    );
};