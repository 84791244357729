import { Button, Input, Form, Col, Row, DatePicker } from "antd";
import SearchOutlined from "@mui/icons-material/SearchOutlined";
import { SelectWithData } from "../form/parts/SelectWithData";
const { RangePicker } = DatePicker;

export const SearchFilter = ({ text, range, status, onSubmit, posStatus }) => {
    const [form] = Form.useForm();

    return (
        <Form
            name="FilterFrom"
            requiredMark={false}
            form={form}
            layout="horizontal"
            onFinish={(values) => onSubmit(values)}
            style={{ width: "100%" }}
        >
            <Row gutter={[16, 0]}>
                {!text ? "" : <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                    <Form.Item name="search">
                        <Input prefix={<SearchOutlined size="small" />} placeholder="Suchen" />
                    </Form.Item>
                </Col>}
                {!status ? "" : <Col xs={8} sm={8} md={8} lg={5} xl={5}>
                    <SelectWithData feedback={false} label="Status" name="status"
                        data={posStatus ? posStatus : [
                            { value: "", label: "Alle" },
                            { value: "ACTIVE", label: "Aktiv" },
                            { value: "INTERNAL", label: "Intern (Verwaltung)" },
                            { value: "SOON", label: "Intern (Trainer)" },
                        ]} />
                </Col>}
                {!range ? "" : <Col xs={8} sm={8} md={8} lg={5} xl={5}>
                    <Form.Item name="timeRange">
                        <RangePicker placeholder={["Start", "Ende"]} />
                    </Form.Item>
                </Col>}
                {!text && !range && !status ? "" :
                    <Col xs={8} sm={8} md={8} lg={4} xl={4}>
                        <Form.Item >
                            <Button type="primary" htmlType="submit" block>
                                Suchen
                            </Button>
                        </Form.Item>
                    </Col>}
            </Row>
        </Form>
    )
};

export function getFilter(value) {
    return (value === true ? "" : value ? value : "")
}