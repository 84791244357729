import { Card, Spin, Row } from "antd";
import { EmptyDisplay } from "./emptyDisplay";

export const ErrorDisplay = ({ children, loading, error }) => {

    if (error) {
        return (<Card style={{ width: "100%" }} loading={loading}>
            <EmptyDisplay text={error} iconHeight={0} />
        </Card>)
    }
    if (loading) {
        return (<Row justify={"center"}>
            <Spin size="large" spinning={loading}></Spin>
        </Row>)
    }
    return (<>{children}</>)
};