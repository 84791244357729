
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Spin, message } from "antd";
import SuccessPage from "../../package/components/displays/SuccessPage";
import ErrorPage from "../../package/components/displays/ErrorPage";
import { customAuthApi, frondend } from "../../urlConfig";

const AuthPage = () => {
    const { token } = useParams();
    const [loading, setLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState();
    const [isError, setIsError] = useState(null);

    const success = {
        title: 'Verifizierung erfolgreich',
        message: "Ihre E-Mail-Adresse wurde erfolgreich verifiziert. Ihr Account steht Ihnen nun zur Verfügung.",
        primaryButton: {
            link: '/',
            label: 'Jetzt Anmelden',
        },
    }
    const error = {
        title: 'Verifizierung fehlgeschlagen',
        message: "Ihre Email-Adresse konnte nicht Verifiziert werden bitte fordern Sie eine neue E-Mail an.",
        primaryButton: {
            link: frondend.customBase + '/register/new',
            label: 'Neue Email Anfordern',
        },
        error: isError,
    }

    const checkToken = async () => {
        try {
            await customAuthApi.get(`/auth/${token}`);
            setIsSuccess(true);
        } catch (error) {
            setIsError(error?.response?.data?.message || {})
            setIsSuccess(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        checkToken();
    }, []);

    return (
        <React.Fragment>
            <Row justify="center" align="middle" style={{ height: '100vh' }}>
                <Col xs={24} sm={20} md={18} lg={16} xl={14}>
                    <Spin spinning={loading} size="large">
                        {loading ? '' :
                            <div>
                                {isSuccess ? <SuccessPage data={success} /> : <ErrorPage data={error} />}
                            </div>
                        }
                    </Spin>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default AuthPage;