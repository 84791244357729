import React, { useState } from 'react';
import {  Form, Input, Button, Row, Col, Card, Space, Spin, message } from 'antd';
import {customAuthApi} from '../../../urlConfig';

import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';

const RegisterForm = ({ linkLogin, isSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const msg = (type, msg) => {
    messageApi.open({
      type: type,
      content: `${msg}`,
      duration: 10,
    });
  };

  const handleRegister = async (values) => {
    try {
      setLoading(true);

      await customAuthApi.post('/auth/new', values);
      isSuccess();
    } catch (error) {
      msg('error', `Registrierung Fehlgeschlagen: ${error.response?.data?.message}`);
    } finally {
      setLoading(false);
    }
  };



  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      {contextHolder}
      <Form
        name="normal_register"
        className="register-form"
        requiredMark={false}
        onFinish={handleRegister}
        layout="vertical"
        validateTrigger="onChange"
        style={{ width: "100%" }}
      >
        <Card title={
          <Space direction="vertical">
            <h3>Bestätigungsemail erneuet senden</h3>
            <h6>Swimgood-Schwimmschule - CourseSync</h6>
          </Space>
        } hoverable>
          <Spin spinning={loading} size="large">
            <Form.Item
              hasFeedback
              label="E-Mail-Adresse:"
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Bitte eine gültige E-Mail-Adresse eingeben',
                },
              ]}
            >
              <Input prefix={<AlternateEmailOutlinedIcon fontSize='small' />} placeholder="E-Mail-Adresse" />
            </Form.Item>
            <Form.Item>
              <Row align="middle" justify="space-between">
                {linkLogin ?
                  <Col className='text-end'>
                    <a href={linkLogin}>Zurück zur Anmeldung</a>
                  </Col>
                  : ''}
                <Col>
                  <Button type="primary" htmlType="submit" className="text-start btn-secondary">
                    Senden
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Spin>
        </Card>
      </Form>
    </div>
  );
};

export default RegisterForm;
