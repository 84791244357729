import { Row, Col, Button, Steps, Flex } from "antd";
import { EmptyDisplay } from "./emptyDisplay";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

export const StepDisplay = ({ items, current, prev, next }) => {
    if (!items) {
        return (
            <EmptyDisplay iconHeight={0} card />
        )
    }
    return (
        <Row gutter={[0, 8]}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                <Steps current={current} items={items} responsive={false} />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                {items[current]?.content}
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                <Flex justify="space-between">
                    {current == 0 || items[current]?.lockBack ? "" : <Button size="small" icon={<ArrowBackOutlinedIcon fontSize="small" />} type="text" onClick={() => prev()}>Zurück</Button>}
                    {!items[current]?.skip ? "" : <Button size="small" type="text" onClick={() => next()}>Überspringen</Button>}
                </Flex>
            </Col>

        </Row>
    )
};