import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Flex } from "antd";
import Layout from "../../layout/Layout";
import { CustomerFullData, ErrorDisplay } from "../../package";
import { customCustomerApi } from "../../urlConfig";

export default function ProfilePage({ layout }) {
  const navigate = useNavigate();
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);

  const headerData = { title: 'Profil', }

  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("user"))
    setData(localData)
    setLoading(false)
  }, []);

  return (
    <React.Fragment>
      <Layout layout={layout} headerData={headerData}>
        <ErrorDisplay loading={loading}>
          <Flex justify="center" gap={5}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <CustomerFullData editable uuid={data?.customer?.uuid} card custApi={customCustomerApi} />
            </Col>
          </Flex>
        </ErrorDisplay>
      </Layout>
    </React.Fragment>
  );
};