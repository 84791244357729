import { Select, Form, Tooltip, Col } from 'antd';

export const SelectWithData = ({ ghost, feedback = true, tooltip, label, name, data }) => {
    if (ghost) {
        return (
            <Select style={{ width: '100%' }} placeholder={`${label}`} showSearch optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                options={data}>
            </Select>
        )
    }
    return (
        <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title={tooltip}>
            <Form.Item
                hasFeedback={feedback}
                name={name}
                rules={feedback ? [
                    {
                        required: true,
                        message: `Bitte ein ${label?.toLowerCase()} auswählen`,
                    },
                ] : []}
            >
                <Select style={{ width: '100%' }} placeholder={`${label}`} showSearch optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={data}>
                </Select>
            </Form.Item>
        </Tooltip>
    );
};
