import React from "react";
import { useNavigate } from "react-router-dom";
import { List, Flex, message } from 'antd';

import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import CookieOutlinedIcon from '@mui/icons-material/CookieOutlined';
import { ModalDisplay } from "../package";
import { useState } from "react";
import Impressum from "./Impressum";
import Privacy from "./Privacy";
import SettingsOutlined from "@mui/icons-material/SettingsOutlined";
import DisplayProvider from "./DisplayProvider";

export default function Settings({ isSuccess, mode }) {
    const navigate = useNavigate()
    const [impressum, setImpressum] = useState(new URLSearchParams(window.location.search).get("impressum") == 1 ? true : false);
    const [privacy, setPrivacy] = useState(new URLSearchParams(window.location.search).get("privacy") == 1 ? true : false);

    const credentials = {
        icon: <SettingsOutlined />,
        header: "Einstellungen",
        footer: <Flex justify="center" style={{ color: "gray" }}>©{new Date().getFullYear()} Alle Rechte vorbehalten | Swimgood-Schwimmschule Trumpa </Flex>
    }

    const data = [
        {
            onClick: (() => window.open('https://swimgood-schwimmschule.de', '_blank')),
            label: ("Website"),
            icon: <LanguageOutlinedIcon />,
            description: ("Besuchen Sie unsere Website"),
        },
        {
            onClick: (() => { }),
            label: ("Swimgood-Schwimmschule Kontakt"),
            icon: <AlternateEmailOutlinedIcon />,
            description: (<>
                E-Mail: kontakt@swimgood-schwimmschule.de <br />
                WhatsApp: +49 (0) 178 3249700
            </>),
        },
        {
            onClick: (() => { }),
            label: ("CourseSync Kontakt (Kein Kundensupport)"),
            icon: <BugReportOutlinedIcon />,
            description: ("info@coursesync.de"),
        },
        {
            onClick: (() => window.open('https://swimgood-schwimmschule.de/agb-kurse', '_blank')),
            label: ("AGB"),
            icon: <ListOutlinedIcon />,
        },
        {
            onClick: (() => setPrivacy(true)),
            label: ("Datenschutz"),
            icon: <SecurityOutlinedIcon />,
        },
        {
            onClick: (() => setImpressum(true)),
            label: ("Impressum"),
            icon: <InfoOutlinedIcon />,
        },
        {
            onClick: (() => isSuccess("cookie")),
            label: ("Cookies"),
            icon: <CookieOutlinedIcon />,
        },
    ];

    const content = (<React.Fragment>
        <List
            itemLayout="horizontal"
            style={{ width: "100%" }}
            dataSource={data}
            renderItem={(item, index) => (
                <List.Item onClick={() => item.onClick()}>
                    <List.Item.Meta
                        avatar={item.icon}
                        title={item.label}
                        description={item.description}
                    />
                </List.Item>
            )}
        />
        <ModalDisplay
            onCancel={() => {
                setImpressum(false)
                navigate("")
            }}
            open={impressum}
            content={<Impressum />} />
        <ModalDisplay
            onCancel={() => {
                setPrivacy(false)
                navigate("")
            }}
            open={privacy}
            content={<Privacy />} />
    </React.Fragment>);

    return <DisplayProvider page={content} credentials={credentials} mode={mode} />
};