import React from "react";
import Navbar from "./Navbar";
import { Row, Col } from "antd";
import Header from "./Header";


const Layout = ({ children, layout, headerData, select }) => {
  const viewportHeight = window.innerHeight;

  return (
    <React.Fragment>
      <Navbar >
        <Row style={{ height: '100vh', maxHeight: "100vh" }}>
          <Col span={24}>
            <Header layout={layout} data={headerData} select={select} />
          </Col>
          <Col span={24} style={{ padding: 10, overflow: 'auto', height: `calc(${viewportHeight}px - 75px)` }}>
            <main>{children}</main>
          </Col>
        </Row>

      </Navbar>
    </React.Fragment>
  );
};

export default Layout;