// Sidebar.js
import React, { useState, useEffect } from "react";
import { Row } from "antd";
import Layout from "../../layout/Layout";
import PublicDashboardContent from "./PublicDashboardContent";
import DashboardContent from "./DashboardContent";
import { ErrorDisplay } from "../../package";

export default function Dashboard({ layout }) {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const headerData = { title: 'Willkommen bei Ihrer My-Swimy-App', }

  useEffect(() => {
    const localUser = JSON.parse(localStorage.getItem("user"))
    setUser(localUser);
    setLoading(false);
  }, []);

  return (
    <React.Fragment>
      <Layout layout={layout} headerData={headerData}>
        <Row gutter={[16, 16]}>
          <ErrorDisplay loading={loading}>
          {!user ? <PublicDashboardContent /> : <DashboardContent />}
          </ErrorDisplay>
        </Row>
      </Layout>
    </React.Fragment >
  );
};