import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { List, Collapse, Row, Col, Card, Button, Space, Tag, Switch, Select } from 'antd';

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import { LocationSelect, CourseTypeSelect } from "../..";

export function CourseFilter({ isSuccess, mainApi, location = [], courseType = [], size = 10 }) {
    const [loc, setLoc] = useState(location);
    const [type, setType] = useState(courseType);
    const [count, setCount] = useState(size);
    const [open, setOpen] = useState(0);

    const handleSuccess = () => {
        isSuccess(loc, type, count)
        setOpen(1)
    };
    const handleDelete = () => {
        setLoc([])
        setType([])
        isSuccess("", "", "10")
        setOpen(1)
    };

    const toggle = () => {
        setOpen(open !== 0 ? 0 : 1)
    };

    const data = [
        {
            onClick: (() => { }),
            label: ("Wählen Sie Ihren Standort:"),
            icon: <LocationOnOutlinedIcon />,
            description: (<LocationSelect startValue={loc} onChange={(e) => setLoc(e)} ghost mainApi={mainApi} filterStatus="ACTIVE" />),
        },
        {
            onClick: (() => { }),
            label: ("Wählen Sie eine Kursart:"),
            icon: <ListOutlinedIcon />,
            description: (<CourseTypeSelect startValue={type} onChange={(e) => setType(e)} ghost mainApi={mainApi} filterStatus="ACTIVE" />),
        },
        {
            onClick: (() => { }),
            label: ("Maximal angezeigte Anzahl von Kursen"),
            icon: <ListOutlinedIcon />,
            description: (<Select
                value={count} onChange={(e) => setCount(e)} style={{ width: "100%" }}
                options={[
                    { value: 5, label: "5 Kurse" },
                    { value: 10, label: "10 Kurse" },
                    { value: 25, label: "25 Kurse" },
                    { value: 50, label: "50 Kurse" },
                ]}>
            </Select>
            ),
        },
    ];

    return (<>

        <Collapse
            style={{ backgroundColor: "#9dcedf", color: "white" }}
            size="small"
            activeKey={open}
            onChange={() => { toggle() }}
            items={[{
                extra: (<InfoOutlinedIcon />),
                label: (<h6>Willkommen bei Ihrer Kursanmeldung</h6>),
                children: (
                    <List
                        size="small"
                        header={"Hier haben Sie die Möglichkeit eine Auswahl für die Kurse zu treffen."}
                        footer={<Row>
                            <Col align="start" span={12} >
                                <Button type="text" onClick={() => handleDelete()}>Auswahl Löschen</Button>
                            </Col>
                            <Col align="end" span={12} >
                                <Button type="primary" onClick={() => handleSuccess()}>Anwenden</Button>
                            </Col>
                        </Row>}
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={(item, index) => (
                            <List.Item onClick={() => item.onClick()}>
                                <List.Item.Meta
                                    avatar={item.icon}
                                    title={item.label}
                                    description={item.description}
                                />
                            </List.Item>
                        )}
                    />

                )
            }]}>

        </Collapse >
    </>);
};