import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row, Tabs, message } from "antd";
import Layout from "../../layout/Layout";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
//Icons
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { EmptyDisplay, ErrorDisplay, ModalDisplay, ParticipantForm, ParticipantShowCustom } from "../../package";
import { SystemApi, customCustomerApi } from "../../urlConfig";
import { TabsDisplay } from "../Tabs";

export default function ProfilePage({ layout }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState("");
  const [participants, setParticipants] = useState([])
  const [open, setOpen] = useState(false);

  const headerData = {
    title: 'Meine Teilnehmer',
    icon: <GroupOutlinedIcon />,
  }

  const getParticipants = async (customerId) => {
    try {
      const response = await customCustomerApi.get(`/participant/all?customerIds=${customerId}`);
      setParticipants(response.data.participant);
    } catch (error) {
      setError("Probleme beim Laden der Daten: " + error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("user")) ?? navigate("/")
    getParticipants(localData?.customer?.uuid);
    setRefresh(false)
  }, [open, refresh]);


  const participantPage = {
    label: <PersonOutlineOutlinedIcon />,
    pages: participants.map((participant, index) => ({
      icon: <PersonOutlineOutlinedIcon />,
      key: (participant.uuid).toString(),
      label: participant.person.name.firstname + ' ' + participant.person.name.lastname,
      children: (<ParticipantShowCustom refresh={() => setRefresh(true)} editable mainApi={SystemApi} custApi={customCustomerApi} uuid={participant.uuid} />),
    })),
  }

  const action = {
    icon: <PersonAddAlt1OutlinedIcon />,
    label: "Hinzufügen",
    onClick: () => setOpen(true),
  }

  return (
    <React.Fragment>
      <Layout layout={layout} headerData={headerData}>
        <ErrorDisplay error={error} loading={loading}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <TabsDisplay content={participantPage} action={action}></TabsDisplay>
            <ModalDisplay
              onCancel={() => setOpen(false)}
              open={open}
              content={<ParticipantForm
                custApi={customCustomerApi}
                isSuccess={() => { setOpen(false) }} />}
            />
          </Col>
        </ErrorDisplay>
      </Layout>
    </React.Fragment >
  );
};