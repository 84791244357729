import React from 'react';
import { Form, Input, Button, Checkbox, Card, Spin, Row, Col, Space, DatePicker, Select } from 'antd';

const CustomerForm = ({ contextHolder, handleSubmit, loading, linkPwForgotten, linkRegister }) => {
  const { Option } = Select;

  const onFinish = (values) => {
    // Handle form submission
    handleSubmit(values);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      {contextHolder}
      <Form
        name="customer_form"
        className="customer-form"
        requiredMark={false}
        onFinish={onFinish}
        layout="vertical"
        validateTrigger="onChange"
        style={{ width: "100%" }}
      >
        <Card title={
          <Space direction="vertical">
            <h3>Anmeldung Kundenportal</h3>
            <h6>Swimgood-Schwimmschule - CoursSync</h6>
          </Space>
        } hoverable>
          <Spin spinning={loading} size="large">
            {/* Email */}
            <Form.Item
              hasFeedback
              label="Email:"
              name="email"
              rules={[
                {
                  required: true,
                  type: 'email',
                  message: 'Bitte eine gültige E-Mail-Adresse eingeben',
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>

            {/* Person Referenz */}
            <Form.Item
              label="Person"
              name="personId"
              rules={[
                {
                  type: 'object',
                  fields: {
                    sex: { type: 'string', required: true, message: 'Bitte Geschlecht auswählen' },
                    birthdate: { type: 'date', required: true, message: 'Bitte Geburtsdatum auswählen' },
                  },
                },
              ]}
            >
              <Input.Group compact>
                <Form.Item name={['personId', 'sex']} noStyle>
                  <Select placeholder="Geschlecht">
                    <Option value="male">Männlich</Option>
                    <Option value="female">Weiblich</Option>
                    <Option value="other">Andere</Option>
                  </Select>
                </Form.Item>
                <Form.Item name={['personId', 'birthdate']} noStyle>
                  <DatePicker placeholder="Geburtsdatum" style={{ width: '100%' }} />
                </Form.Item>
              </Input.Group>
            </Form.Item>

            {/* Name Referenz */}
            <Form.Item
              label="Name"
              name="nameId"
              rules={[
                {
                  type: 'object',
                  fields: {
                    firstname: { type: 'string', required: true, message: 'Bitte Vornamen eingeben' },
                    lastname: { type: 'string', required: true, message: 'Bitte Nachnamen eingeben' },
                  },
                },
              ]}
            >
              <Input.Group compact>
                <Form.Item name={['nameId', 'firstname']} noStyle>
                  <Input placeholder="Vorname" />
                </Form.Item>
                <Form.Item name={['nameId', 'lastname']} noStyle>
                  <Input placeholder="Nachname" />
                </Form.Item>
              </Input.Group>
            </Form.Item>

            {/* Weitere Referenzen hier hinzufügen */}

            {/* Remember me and forgot password */}
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox >Angemeldet bleiben</Checkbox>
              </Form.Item>
              {linkPwForgotten ?
                <a className="login-form-forgot" href="">
                  Passwort vergessen?
                </a>
                : ''}
            </Form.Item>

            {/* Submit and Register */}
            <Form.Item>
              <Row align="middle" justify="space-between">
                <Col>
                  <Button type="primary" htmlType="submit" className="text-start btn-secondary">
                    Anmelden
                  </Button>
                </Col>
                {linkRegister ?
                  <Col className='text-end'>
                    <a href={linkRegister}> Kein Account? <br/> Jetzt registrieren</a>
                  </Col>
                  : ''}
              </Row>
            </Form.Item>
          </Spin>
        </Card>
      </Form>
    </div>
  );
};

export default CustomerForm;
