import React, { useState, useEffect } from "react";
import { RegisterForm, SuccessPage } from "../../../package";
import { Avatar } from "antd";
import FingerprintOutlinedIcon from '@mui/icons-material/FingerprintOutlined';

export const RegisterPage = ({ authApi, isSsuccess }) => {
  const [isSuccess, setIsSuccess] = useState(false);

  const success = {
    icon: <GreenRoundIcon />,
    title: 'Registrierung Erfolgreich',
    message: "Bitte bestätigen Sie Ihre Email-Adresse, hiezu haben Sie ein Email von uns erhalten.",
    primaryButton: {
      link: '/',
      label: 'Email Bestätigt? Jetzt Anmelden',
    },
    secondaryButton: {
      link: '/register/new',
      label: 'Email nocheinmal senden?',
    },
  }

  return (
    <React.Fragment>
      {isSuccess ? <SuccessPage data={success} /> :
        <RegisterForm linkLogin={'/?login=1'} authApi={authApi} isSuccess={() => setIsSuccess(true)}></RegisterForm>
      }
    </React.Fragment>
  );
};

export default RegisterPage;


const GreenRoundIcon = () => (
  <Avatar size='large' style={{ backgroundColor: '#1a4568' }}>
    <FingerprintOutlinedIcon />
  </Avatar>
);
