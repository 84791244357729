// Sidebar.js
import React, { useState, useEffect } from "react";
import { useNavigate, Route, Routes } from "react-router-dom";
import { Row, Col, Button, Tag, Card } from "antd";
import { ErrorDisplay, ParticipantList, ParticipationList } from "../../package";
import Layout from "../../layout/Layout.jsx";
import { SystemApi, customCustomerApi } from "../../urlConfig";


//MyCourseIcons
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import PoolOutlinedIcon from '@mui/icons-material/PoolOutlined';
import { TabsDisplay } from "../Tabs.jsx";


export default function MyCoursePage({ layout }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [error, setError] = useState("");
  const [participants, setParticipants] = useState([])
  const [open, setOpen] = useState(false);

  const headerData = { title: 'Meine Kurse', }

  const getParticipants = async (customerId) => {
    try {
      const response = await customCustomerApi.get(`/participant/all?customerIds=${customerId}`);
      setParticipants(response.data.participant);
    } catch (error) {
      setError("Probleme beim Laden der Daten: " + error?.response?.data?.message)
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    const localData = JSON.parse(localStorage.getItem("user")) ?? navigate("/")
    getParticipants(localData?.customer?.uuid);
    setRefresh(false)
  }, [open, refresh]);


  const participantPage = {
    label: <PersonOutlineOutlinedIcon />,
    pages: participants.map((participant, index) => ({
      icon: <PersonOutlineOutlinedIcon />,
      key: (participant.uuid).toString(),
      label: participant.person.name.firstname + ' ' + participant.person.name.lastname,
      children: (
        <ParticipationList filterText participantIds={participant?.uuid} custApi={customCustomerApi} mainApi={SystemApi} />
      ),
    })),
  }

  const action = {
    icon: <PersonAddAlt1OutlinedIcon />,
    label: "Hinzufügen",
    onClick: () => setOpen(true),
  }

  return (
    <React.Fragment>
      <Layout layout={layout} headerData={headerData}>
        <ErrorDisplay>
          <TabsDisplay content={participantPage}></TabsDisplay>
          {/* 
          <Card
            title={"Diese Seite wird gerade noch entwickelt!"}
            actions={[<Button onClick={() => navigate("/participants")} type="primary">Die angemeldeten Kurse können Sie jetzt schon mit allen Terminen unter "Meine Teilnehmer" einsehen.</Button>]}
          >
            <p>Bald stehen Ihnen hier schon die Möglichkeiten von:</p>
            <p>
              <Tag color="blue">Kurseinsicht</Tag>
              <Tag color="blue">Teilnehmerausweise</Tag>
              <Tag color="blue">Stornieren</Tag>
              <Tag color="blue">Kurs wechseln</Tag>
            </p>
            <p>Und viele weitere Funktionen zur Verfügung.</p>
          </Card> */}
        </ErrorDisplay>
      </Layout>
    </React.Fragment>
  );

};