import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Space, message, Row, Col, Divider, Tooltip, Select, Switch } from 'antd';
import TransgenderOutlinedIcon from '@mui/icons-material/TransgenderOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import AssignmentIndOutlinedIcon from '@mui/icons-material/AssignmentIndOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import { LocationSelect } from '../../components/form/parts/LocationSelect';
import { SelectWithData } from '../../components/form/parts/SelectWithData';

const { Option } = Select;
export const ManagerForm = ({ title, isSuccess, uuid, employeeId, name, empApi }) => {
    const [loading, setLoading] = useState(false)
    const [location, setLocation] = useState("")
    const [service, setService] = useState("")
    const [personal, setPersonal] = useState("")
    const [god, setGod] = useState("")
    const [form] = Form.useForm();
    const [managerUuid, setManagerUuid] = useState("")

    useEffect(() => {
        if (uuid) {
            getData();
        }
    }, []);

    const getData = async () => {
        try {
            setLoading(true);
            const response = await empApi.get(`/manager/one/${uuid}`);
            setManagerUuid(response.data.manager.uuid)
            const temp = {
                status: response?.data?.manager?.managerStatus?.statusValue,
            }
            setGod(response?.data?.manager?.god);
            setLocation(response?.data?.manager?.location);
            setService(response?.data?.manager?.service);
            setPersonal(response?.data?.manager?.personal);
            form.setFieldsValue(temp);
        } catch (error) {
            message.error("Fehler beim Laden der Daten. " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    const onFinish = async (values) => {
        let response;
        values.employeeId = employeeId;
        values.location = location;
        values.service = service;
        values.god = god;
        values.personal = personal;
        try {
            setLoading(true);
            if (uuid) {
                response = await empApi.patch(`/manager/update/${managerUuid}`, values);
            } else {
                response = await empApi.post(`/manager`, values);
            }
            isSuccess(response.data.manager);
        } catch (error) {
            message.error("Fehlgeschlagen: " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    const handleLocationChange = () => {
        setLocation(!location)
    }
    const handleGodChange = () => {
        setGod(!god)
    }
    const handleServiceChange = () => {
        setService(!service)
    }
    const handlePersonalChange = () => {
        setPersonal(!personal)
    }


    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Form
                name="ManagerForm"
                requiredMark={false}
                form={form}
                onFinish={onFinish}
                layout="vertical"
                validateTrigger="onChange"
                style={{ width: "100%" }}
            >
                <Space direction="vertical">
                    <h3>{uuid ? 'Manager ändern' : 'Manager anlegen'}</h3>
                </Space>
                <Spin spinning={false} size="large" loading={loading}>
                    <Row gutter={[16, 0]}>
                        {/**/}
                        <Col span={24}>
                            <Divider orientation='left' orientationMargin="0"><PersonOutlineOutlinedIcon /> Manager</Divider>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <Form.Item name="service" valuePropName="checked">
                                <Space>
                                    <Switch checked={service} onChange={() => handleServiceChange()} />
                                    Kunden und Kurs Zugang
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <Form.Item
                                name="location"
                                valuePropName="checked"
                                initialValue={true}>
                                <Space>
                                    <Switch checked={location} onClick={() => handleLocationChange()} />
                                    Standort Zugang
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <Form.Item
                                name="personal"
                                valuePropName="checked">
                                <Space>
                                    <Switch checked={personal} onClick={() => handlePersonalChange()} />
                                    Personal Zugang
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <Form.Item
                                name="god"
                                valuePropName="checked">
                                <Space>
                                    <Switch checked={god} onClick={() => handleGodChange()} />
                                    Vollen Zugang
                                </Space>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={16} lg={17} xl={18} >
                            <SelectWithData
                                label="Status"
                                tooltip={"Status des Trainers?"}
                                name="status"
                                data={[
                                    { value: "ACTIVE", label: "Aktiv" },
                                    { value: "INACTIVE", label: "Inaktiv" },
                                    { value: "FALLBACK", label: "Ersatz" },
                                    { value: "PENDING", label: "Nur ansicht" },
                                    { value: "BLOCKED", label: "Blockiert" },
                                ]}
                            />
                        </Col>
                        <Col xs={16} sm={12} md={8} lg={7} xl={6} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" loading={loading} block>
                                    {title ? title : uuid ? "Speichern" : 'Erstellen'}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </div >
    );
};