import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Spin, Space, message, Row, Col, Divider, Tooltip, Checkbox, Select, DatePicker } from 'antd';
import moment from 'moment';

import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TransgenderOutlinedIcon from '@mui/icons-material/TransgenderOutlined';
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import NumbersOutlinedIcon from '@mui/icons-material/NumbersOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';

const { Option } = Select;
export const EmployeeTaxForm = ({ title, isSuccess, uuid, empApi }) => {
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm();

    useEffect(() => {
        if (uuid) { getData() }
    }, []);

    const getData = async () => {
        try {
            setLoading(true);
            const response = await empApi.get(`/employee/one/${uuid}`);
            const data = {
                nationality: response?.data?.employee?.taxData?.nationality,
                taxClass: response?.data?.employee?.taxData?.taxClass,
                taxNumber: response?.data?.employee?.taxData?.taxNumber,
                graduation: response?.data?.employee?.taxData?.graduation,
                education: response?.data?.employee?.taxData?.education,

                type: response?.data?.employee?.insuranceData?.type,
                insuranceNumber: response?.data?.employee?.insuranceData?.insuranceNumber,
                institution: response?.data?.employee?.insuranceData?.institution,
            }
            form.setFieldsValue(data);
        } catch (error) {
            message.error("Fehler beim Laden der Daten. " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    const taxValidator = (_, value) => {
        const regex = /^[0-9]{2,3}\/[0-9]{3}\/[0-9]{5}$/;
        if (!regex.test(value)) {
            return Promise.reject('Ungültige Steuernummer Format (XX/XXX/XXXXX)');
        }
        return Promise.resolve();
    };

    const insuranceValidator = (_, value) => {
        const regex = /^[0-9]{8}[A-Z][0-9]{3}$/;
        if (!regex.test(value) && value) {
            return Promise.reject('Ungültige Rentenversicherungsnummer');
        }
        return Promise.resolve();
    };



    const onFinish = async (values) => {
        const data = {
            taxData: {
                nationality: values.nationality,
                taxClass: values.taxClass,
                taxNumber: values.taxNumber,
                graduation: values.graduation,
                education: values.education,
            },
            insuranceData: {
                type: values.type,
                insuranceNumber: values.insuranceNumber,
                institution: values.institution,
            },
        }
        try {
            setLoading(true);
            await empApi.patch(`/employee/update/${uuid}`, data);
            isSuccess();
        } catch (error) {
            message.error("Fehlgeschlagen: " + error?.response?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Form
                name="employeeTaxForm"
                requiredMark={false}
                form={form}
                onFinish={onFinish}
                layout="vertical"
                validateTrigger="onChange"
                style={{ width: "100%" }}
            >
                <Space direction="vertical">
                    <h3>{"Weitere Daten"}</h3>
                </Space>
                <Spin spinning={false} size="large" loading={loading}>
                    <Row gutter={[16, 0]}>

                        {/**/}
                        <Col span={24}>
                            <Divider orientation='left' orientationMargin="0"><OutlinedFlagIcon /> Steuerliche Daten</Divider>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8} xl={8} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Steuerklasse">
                                <Form.Item
                                    name="taxClass"
                                    rules={[{ required: true, message: 'Bitte eine Steuerklasse auswählen!' }]}
                                >
                                    <Select prefix={<TransgenderOutlinedIcon fontSize='small' />} placeholder="Steuerklasse">
                                        <Option value="1">{"Klasse 1 - Ledige und Geschiedene"}</Option>
                                        <Option value="2">{"Klasse 2 - Alleinerziehende und getrennt lebende"}</Option>
                                        <Option value="3">{"Klasse 3 - Ehepaare in Kombination mit Steuerklasse 5"}</Option>
                                        <Option value="4">{"Klasse 4 - Ehepaare mit gleichem Einkommen"}</Option>
                                        <Option value="5">{"Klasse 5 - Ehepartner in Kombination mit Steuerklasse 3"}</Option>
                                        <Option value="6">{"Klasse 6 - Berufstätige mit Nebenjob(s)"}</Option>
                                    </Select>
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8} xl={8} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Steuernummer">
                                <Form.Item
                                    hasFeedback
                                    name="taxNumber"
                                    rules={[
                                        { required: true, message: 'Bitte geben Sie eine Steuernummer ein' },
                                        { validator: taxValidator },
                                    ]}>
                                    <Input prefix={<NumbersOutlinedIcon fontSize='small' />} placeholder="Steuernummer" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={8} xl={8} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Nationalität">
                                <Form.Item
                                    hasFeedback
                                    name="nationality"
                                    rules={[{
                                        required: true,
                                        min: 2,
                                        message: 'Bitte Ihren Nationalität eingeben!',
                                    },
                                    {
                                        pattern: /^[a-zA-ZäöüßÄÖÜ ]+$/i,
                                        message: 'Der Nationalität darf nur Buchstaben enthalten!',
                                    },
                                    {
                                        max: 50,
                                        message: 'Der Nationalität ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<PublicOutlinedIcon fontSize='small' />} placeholder="Nationalität" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Schulabschluss">
                                <Form.Item
                                    name="education"
                                    rules={[{ required: true, message: 'Bitte eine Schulabschluss auswählen!' }]}
                                >
                                    <Select prefix={<TransgenderOutlinedIcon fontSize='small' />} placeholder="Schulabschluss">
                                        <Option value="1 - Ohne Schlabschluss">{"1 - Ohne Schlabschluss"}</Option>
                                        <Option value="2 - Haupt-/Volksschulabschluss">{"2 - Haupt-/Volksschulabschluss"}</Option>
                                        <Option value="3 - Mittlere Reife oder gleichwertiger Abschluss">{"3 - Mittlere Reife oder gleichwertiger Abschluss"}</Option>
                                        <Option value="4 - Abitur / Fachabitur">{"4 - Abitur / Fachabitur"}</Option>
                                        <Option value="0 - Andere">{"0 - Andere"}</Option>
                                    </Select>
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Berufsausbildung">
                                <Form.Item
                                    name="graduation"
                                    rules={[{ required: true, message: 'Bitte eine Berufsausbildung auswählen!' }]}
                                >
                                    <Select prefix={<TransgenderOutlinedIcon fontSize='small' />} placeholder="Berufsausbildung">
                                        <Option value="1 - Ohne beruflichen Ausbildungsabschluss">{"1 - Ohne beruflichen Ausbildungsabschluss"}</Option>
                                        <Option value="2 - Abschluss einer anerkannten Berufsausbildung">{"2 - Abschluss einer anerkannten Berufsausbildung"}</Option>
                                        <Option value="3 - Meister-/Techniker- oder gleichwertiger Fachschulabschluss">{"3 - Meister-/Techniker- oder gleichwertiger Fachschulabschluss"}</Option>
                                        <Option value="4 - Bachelor">{"4 - Bachelor"}</Option>
                                        <Option value="5 - Diplom/Magister/Master/Staatsexamen">{"5 - Diplom/Magister/Master/Staatsexamen"}</Option>
                                        <Option value="6 - Promotion">{"6 - Promotion"}</Option>
                                        <Option value="0 - Andere">{"0 - Andere"}</Option>
                                    </Select>
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        {/*KV*/}
                        <Col span={24}>
                            <Divider orientation='left' orientationMargin="0"><HealthAndSafetyOutlinedIcon />Krankenversicherung</Divider>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Art der Krankenversicherung">
                                <Form.Item
                                    name="type"
                                    rules={[{ required: true, message: 'Bitte eine Art der Krankenversicherung auswählen!' }]}
                                >
                                    <Select placeholder="Art der Krankenversicherung">
                                        <Option value="1 - Gesetzlich krankenversichert">{"1 - Gesetzlich krankenversichert"}</Option>
                                        <Option value="2 - Privat krankenversichert oder anderweitig">{"2 - Privat krankenversichert oder anderweitig"}</Option>
                                    </Select>
                                </Form.Item>
                            </Tooltip>
                        </Col>


                        <Col xs={24} sm={12} md={12} lg={12} xl={12} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Krankenkasse">
                                <Form.Item
                                    hasFeedback
                                    name="institution"
                                    rules={[{
                                        required: true,
                                        min: 2,
                                        message: 'Bitte Ihren Krankenkasse eingeben!',
                                    },
                                    {
                                        pattern: /^[a-zA-ZäöüßÄÖÜ ]+$/i,
                                        message: 'Der Krankenkasse darf nur Buchstaben enthalten!',
                                    },
                                    {
                                        max: 50,
                                        message: 'Der Krankenkasse ist zu Lang!',
                                    },
                                    ]}>
                                    <Input prefix={<HomeWorkOutlinedIcon fontSize='small' />} placeholder="Krankenkasse" />
                                </Form.Item>
                            </Tooltip>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} >
                            <Tooltip trigger={'focus'} placement='topLeft' arrow={false} title="Rentenversicherungsnummer (Wenn noch keine vergeben bitte leer lassen)">
                                <Form.Item
                                    hasFeedback
                                    tooltip="UZFHFLG"
                                    name="insuranceNumber"
                                    rules={[
                                        { validator: insuranceValidator },
                                    ]}>
                                    <Input prefix={<NumbersOutlinedIcon fontSize='small' />} placeholder="Rentenversicherungsnummer" />
                                </Form.Item>
                            </Tooltip>
                        </Col>

                        <Col xs={12} sm={18} md={18} lg={18} xl={18} ></Col>
                        <Col xs={12} sm={6} md={6} lg={6} xl={6} >
                            <Form.Item >
                                <Button type="primary" htmlType="submit" loading={loading} block>
                                    {title ? title : uuid ? "Speichern" : 'Erstellen'}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Spin>
            </Form>
        </div >
    );
};